import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const CircleLoader = ({ size = 40, color = 'white' }) => {
    return (
        <ColorRing
            visible={true}
            height={size}
            width={size}
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={[color, color, color, color, color]}
        />
    )
}

export default CircleLoader
