import React, { useState } from 'react';
// import './DragAndDrop.css'; // We'll add the CSS here later
import { MdDelete } from "react-icons/md";
import UpdateButton from './UpdateButton';

const DragAndDrop = ({updateNode}) => {
  const [image, setImage] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [hover, setHover] = useState(false);

  // Function to handle file drop
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    const files = event.dataTransfer.files;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  // Function to handle drag over
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  // Function to handle drag leave
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  // Function to delete the image
  const handleDelete = () => {
    setImage(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        // Create a temporary URL for the file
        const imageUrl = URL.createObjectURL(file);
        setImage(imageUrl); // Set the image URL to state
      }
  };
  return (
    <div
        className='py-7'
        onMouseEnter={() => image?setHover(true):null}
        onMouseLeave={() => image?setHover(false):null}
    >
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        style={{
          border: dragActive ? '2px dashed #000' : '2px solid #ccc',
          width: '100%',
          height: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px auto',
          position: 'relative',
          cursor: 'pointer',
        }}
        className='relative z-10 rounded-lg overflow-hidden'
      >
        {hover && <div className='bg-black fade-in-100 opacity-50 w-full h-full absolute top-0 left-0 z-40'></div>}
        {hover && <div onClick={()=>{setImage(null); setHover(false)}} className=' absolute top-[45%] left-[45%] z-50 bg-red-600 px-2 py-2 rounded-full'><MdDelete color='white'/></div>}
        

        {image ? (
          <div
            className="image-container fade-out-100"
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              src={image}
              alt="preview"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            {hover && (
              <>
                <div className="overlay"></div>
                <button className="delete-button" onClick={handleDelete}>
                  &#x2716; {/* Unicode cross symbol for delete */}
                </button>
              </>
            )}
          </div>
        ) : (
            <>
                <label htmlFor='selectImage' className='text-blue-600 cursor-pointer'>Drag & Drop Or Upload Image Here</label>
                <input type="file" id='selectImage' onChange={handleFileChange} className='hidden' />
            </>
        )}
      </div>
      <UpdateButton type={'image'} value={image} updateNode={updateNode}/>

    </div>
  );
};

export default DragAndDrop;
