import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Handle, Position } from "reactflow";

//custome node
function TextNode({ data, selected, id }) {
  const { tempNode } = useSelector((store) => store.nodeSlice);
  const [type, setType] = useState("");


  useEffect(() => {
    const node = tempNode.find(item => item.id === id);
    setType(node?.elementType)
    console.log("elementType", node);
  }, [id, tempNode]);
  return (
    <div
      className={`w-40  shadow-md rounded-md bg-white   ${selected ? "border-solid border-2 border-indigo-500/100" : ""
        } `}
    >
      <div className="flex flex-col">
        <div className="max-h-max flex justify-between items-center px-2 py-1 text-left text-black text-xs font-semibold rounded-t-md bg-teal-300">
          <label htmlFor="">{type || "Select An Element"}</label>
        </div>
        <div className="px-3 py-3 ">
          <div className="text-xs flex flex-col font-normal text-black">
            {data?.label ?? "Text Node"}
          </div>
        </div>
      </div>

      <Handle
        id="a"
        type="target"
        position={Position.Left}
        className="w-1 rounded-full bg-slate-500"
      />
      <Handle
        id="b"
        type="source"
        position={Position.Right}
        className="w-1 rounded-full bg-gray-500"
      />
    </div>
  );
}

export default TextNode;
