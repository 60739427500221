import React, { useContext, useEffect, useState } from 'react'
import WTPSideBar from './WTPSideBar';
import Swal from 'sweetalert2';
import axios from 'axios';
import Backend_Url from '../../config/config';
import { useCookies } from 'react-cookie';
import MyContext from '../../context/Mycontext';

const Template = () => {
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const { setmobileslider, mobileslider } = useContext(MyContext)
    const [templateData, setTemplateData] = useState([]);

    const [formData, setFormData] = useState({
        templateName: '',
        language: '',
        HeaderVariable: '',
        BodyVariable: '',
    });
    const [cookies] = useCookies(['token']);

    console.log("formData", formData);

    useEffect(() => {
        fetchData(selectedTemplate)
        console.log("selectedTemplate", selectedTemplate);
    }, [selectedTemplate]);

    const handleSubmit = async (e, type = 3) => {
        e.preventDefault();
        debugger

        if (!formData.templateName || !formData.language) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: 'Please fill in required fields Template name and language',
            });
            return;
        }

        // Show loading indicator
        Swal.fire({
            title: 'Submitting...',
            text: 'Please wait while we process your request.',
            didOpen: () => {
                Swal.showLoading(); // Show the loading spinner
            },
            allowOutsideClick: false, // Prevent closing the alert while loading
        });



        try {
            // Post request to save form data
            const response = await axios.post(
                `${Backend_Url}/whatsapp/v2/campaignorquery/save`,
                { ...formData, type },
                {
                    headers: {
                        Authorization: `Bearer ${cookies.token}`,
                    }
                }
            );
            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Your data has been saved successfully.',
                });

                console.log('Form Submitted:', formData);
                console.log('Selected Template:', selectedTemplate);

                // Optionally reset form data or redirect user
                setFormData({
                    templateName: '',
                    language: '',
                    HeaderVariable: '',
                    BodyVariable: '',
                });
                fetchTemplateData()
                setSelectedTemplate('');
            }

        } catch (error) {
            // Process error response
            if (error.response && error.response.data && error.response.data.message) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An unexpected error occurred. Please try again later.',
                });
            }
            console.error('API Error:', error);
        } finally {
            // Close the loading indicator
            // Swal.close();
        }
    };

    const fetchData = async (id) => {
        debugger
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/v1/campaignorquery/getbyid/${id}`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`,
                }
            });
            if (response.status === 200) {
                console.log("response", response.data);
                setFormData({
                    templateName: response.data.record.templateName,
                    language: response.data.record.language,
                    HeaderVariable: response.data.record.query,
                    BodyVariable: response.data.record.queryName,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async (e, id) => {
        e.preventDefault()
        debugger
        Swal.fire({
            title: 'Deleting...',
            text: 'Please wait while we delete the record.',
            icon: 'info',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            // Make the request to delete the record
            const response = await axios.delete(`${Backend_Url}/whatsapp/v1/campaignorquery/${id}`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`,
                }
            });

            if (response.status === 200) {
                // Hide the loading spinner and show success message
                Swal.fire({
                    title: 'Deleted!',
                    text: 'The record has been successfully deleted.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });

                // Optionally, update the form data
                setFormData({
                    templateName: '',
                    language: '',
                    HeaderVariable: '',
                    BodyVariable: '',
                });
                setSelectedTemplate('');
                fetchTemplateData()
            }
        } catch (error) {
            // Hide the loading spinner and show error message
            Swal.fire({
                title: 'Error!',
                text: 'There was an error deleting the record. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const fetchTemplateData = async (type = 3) => {
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/v1/campaignorquery/getbytype/${type}`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`,
                }
            });
            if (response.status === 200) {
                console.log("response", response.data.records);
                setTemplateData(response.data.records)
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchTemplateData();
    }, []);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    // const handleTemplateChange = (e) => {
    //     setSelectedTemplate(e.target.value);
    // };
    const handleUpdate = async (e, id, type = 10) => { ///////Type 10 to udpate
        e.preventDefault();
        debugger

        if (!formData.templateName || !formData.language) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: 'Please fill in required fields Template name and language',
            });
            return;
        }

        // Show loading indicator
        Swal.fire({
            title: 'Submitting...',
            text: 'Please wait while we process your request.',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: false,
        });



        try {
            const response = await axios.post(
                `${Backend_Url}/whatsapp/v2/campaignorquery/save`,
                { ...formData, id, type },
                {
                    headers: {
                        Authorization: `Bearer ${cookies.token}`,
                    }
                }
            );
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Your data has been updated successfully.',
                });

                console.log('Form Submitted:', formData);
                console.log('Selected Template:', selectedTemplate);

                fetchData(selectedTemplate)
                fetchTemplateData()
            }

        } catch (error) {
            // Process error response
            if (error.response && error.response.data && error.response.data.message) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.response.data.message,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An unexpected error occurred. Please try again later.',
                });
            }
            console.error('API Error:', error);
        } finally {
            // Close the loading indicator
            // Swal.close();
        }
    }

    useEffect(() => {
        if (selectedTemplate === '') {
            setFormData({
                templateName: '',
                language: '',
                HeaderVariable: '',
                BodyVariable: '',
            });
        }
    }, [selectedTemplate]);


    return (
        <div className="Flex-Head-Right-Compaigns">

            <div>
                <WTPSideBar mobileslider={mobileslider} setmobileslider={setmobileslider}  />
            </div>
            <form className="my-form" onSubmit={handleSubmit}>
                {/* Dropdown for selecting template */}
                <div className="my-form__group">
                    <label className="my-form__label">Select Template:</label>
                    <select
                        className="my-form__dropdown"
                        name="dropdown"
                        value={selectedTemplate}
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                    >
                        <option value="">Select a template</option>
                        {templateData && templateData.map((template, index) => (
                            <option key={index} value={template.id}>
                                {template.templateName}
                            </option>
                        ))}
                    </select>
                </div>


                <div className="my-form__group">
                    <label className="my-form__label">Template Name:</label>
                    <input
                        className="my-form__input-text"
                        type="text"
                        name="templateName"
                        value={formData.templateName}
                        onChange={handleChange}
                        placeholder="Template name as in meta"
                    />
                </div>

                {/* Language */}
                <div className="my-form__group">
                    <label className="my-form__label">Language:</label>
                    <input
                        className="my-form__input-text"
                        type="text"
                        name="language"
                        value={formData.language}
                        onChange={handleChange}
                        placeholder="Language(ex:en_US,en_UK)"
                    />
                </div>

                {/* Header Variable */}
                <div className="my-form__group">
                    <label className="my-form__label">Header Variable:</label>
                    <textarea
                        className="my-form__input-text"
                        type="text"
                        name="HeaderVariable"
                        value={formData.HeaderVariable}
                        onChange={handleChange}
                        placeholder="Enter Header Variable"
                    />
                </div>

                {/* Body Variable */}
                <div className="my-form__group">
                    <label className="my-form__label">Body Variable:</label>
                    <textarea
                        className="my-form__input-text"
                        type="text"
                        name="BodyVariable"
                        value={formData.BodyVariable}
                        onChange={handleChange}
                        placeholder="Enter Body Variable"
                    />
                </div>

                {/* Submit Button */}
                <div className="my-form__button-container">
                    {
                        selectedTemplate ?
                            (
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <button className="my-form__submit-btn" onClick={(e) => handleUpdate(e, selectedTemplate)}>
                                        Update
                                    </button>
                                    <button style={{ backgroundColor: "#ff0000c7" }} className="my-form__submit-btn" onClick={(e) => handleDelete(e, selectedTemplate)}>
                                        Delete
                                    </button>
                                </div>
                            )
                            : (<button className="my-form__submit-btn" type="submit">
                                Save
                            </button>)
                    }
                </div>
            </form>

        </div>
    )
}

export default Template
