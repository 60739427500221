import React, { useContext, useEffect, useState } from 'react';
import "./NavBar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Backend_Url from '../../config/config';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Icon } from '@iconify/react/dist/iconify.js';
import MyContext from '../../context/Mycontext';
import { useLocation } from 'react-router-dom';

function NavBar({ completed, showupload, setmobileslider, mobileslider }) {

    const location = useLocation();

    const [path, setPath] = useState('')

    useEffect(() => {
      setPath(location.pathname)
    }, [location])
    


    const { setCompanyName, companyName } = useContext(MyContext)

    function decodeJWT(token) {
        // Split the JWT into header, payload, and signature
        const base64Url = token.split('.')[1]; // Get the payload part
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Correct Base64 URL encoding
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        // Parse the JSON payload
        return JSON.parse(jsonPayload);
    }
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['token']); // Replace with your cookie name
    const decoded = decodeJWT(cookies.token)

    // console.log("cookies", cookies);



    useEffect(() => {
        // debugger
        const fetchData = async () => {
            try {
                const response = await axios.get(`${Backend_Url}/companydetails/getbycomp/${decoded.companyCode}`);
                // Handle response data here
                setCompanyName(response.data.compName)
                console.log("response", response);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);





    // const handleLogout = async () => {
    //     // debugger
    //     try {
    //         // Retrieve token from cookies
    //         const token = cookies['token']; // Replace with your cookie name

    //         // Make an API call to your backend logout endpoint
    //         const response = await fetch(`${Backend_Url}/users/logout`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',

    //             },
    //             body: JSON.stringify({ token }),
    //         });

    //         if (response) {
    //             removeCookie('token');
    //             alert("logout successfully")
    //             setTimeout(() => {
    //                 navigate("/UserLogin")
    //             }, 500);
    //         } else {
    //             console.error('Failed to logout');
    //             // Handle error cases, like displaying an error message
    //         }
    //     } catch (error) {
    //         console.error('Error during logout:', error);
    //         // Handle network errors or other exceptions
    //     }
    // };

    const handleLogout = async () => {
        try {
            // Retrieve token from cookies
            const token = cookies['token']; // Replace with your cookie name

            // Make an API call to your backend logout endpoint
            const response = await fetch(`${Backend_Url}/users/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });

            if (response.ok) {
                // Successfully logged out
                removeCookie('token');

                Swal.fire({
                    title: 'Logged Out',
                    text: 'You have been logged out successfully.',
                    icon: 'success',
                    timerProgressBar: true,
                    timer: 1500,
                    showConfirmButton: false
                }).then(() => {
                    navigate("/UserLogin");
                });
            } else {
                // Handle logout failure
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to log out. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error('Error during logout:', error);

            Swal.fire({
                title: 'Error!',
                text: 'An unexpected error occurred during logout. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const [closemsg, setclosemsg] = useState(true);


    // ---------------REPORT DOWNLOAD-----------------
    const downloadReport = async (e) => {
        try {
            const downloadreq = await axios.get(`${Backend_Url}/downloadreport`, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.token
                },
                responseType: 'json'
            })

            if (downloadreq && downloadreq.data) {
                // Create a Blob from the JSON response
                const jsonBlob = new Blob([JSON.stringify(downloadreq.data)], { type: 'application/json' });

                // Create a link element
                const link = document.createElement('a');

                // Create a URL for the Blob and set it as the href for the link
                link.href = URL.createObjectURL(jsonBlob);

                // Set the download attribute with the file name you want
                link.download = 'ContactReport.json';

                // Programmatically click the link to trigger the download
                link.click();

                // Optionally, revoke the object URL after the download
                URL.revokeObjectURL(link.href);
                setclosemsg(!closemsg)
            }
            console.log(downloadreq)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className={`navbar ${path==='/whatsappweb' && 'sm:!hidden'}`}>
            {
                showupload ? completed != 100 ? <div className={`container-progressbar`}>
                    <p>Uploading process...</p>
                    <div className="progress-bar-container">
                        <div
                            className="progress-bar-filler"
                            style={{ width: `${completed}%` }}
                        >
                            <span className="progress-bar-label">{`${completed}%`}</span>
                        </div>
                    </div>
                </div> :
                    closemsg ? <p className='all-contact'>Report
                        <button onClick={(e) => downloadReport(e)} className={` !p-0 m-0 px-![5px] cursor-pointer`}>
                            <Icon icon="material-symbols:download" color='red' width={20} height={20} />
                        </button>
                        <button onClick={() => setclosemsg(!closemsg)} className={` !p-0 m-0 px-![5px] cursor-pointer`}>
                            <Icon icon="mingcute:close-fill" width="20" height="20" />
                        </button>
                    </p> : null
                    : null
            }

            <NavLink to="/dashboard" style={{ textDecoration: "none", color: "inherit" }}>
                <div className="logo">{companyName}</div>
            </NavLink>
            <ul className="nav-links">
                {/* <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li> */}
            </ul>
            <button onClick={handleLogout} className="logout-button sm:hidden">Logout</button>

            <button className={`hidden sm:block text-xl m-0 p-0 bg-transparent border-none`} onClick={() => { setmobileslider(!mobileslider) }}>
                <Icon icon="fe:bar" style={{ color: "white", width: '30px', height: 'auto' }} />
            </button>
        </div>
    );

}

export default NavBar;