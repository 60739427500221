import { BaseEdge, EdgeLabelRenderer, getBezierPath, getSimpleBezierPath, getSmoothStepPath, Position } from 'reactflow';
import { FaTrash } from 'react-icons/fa';

export default function CustomEdge({ id, sourceX, sourceY, targetX, targetY, isSelected, onDelete }) {
    // Create a curved path using Bezier curve
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition: Position.Right,
        targetX,
        targetY,
        targetPosition: Position.Left,
        curvature: 0.5,
    });

    // const [edgePath, labelX, labelY] = getSimpleBezierPath({
    //     sourceX,
    //     sourceY,
    //     sourcePosition: Position.Right,
    //     targetX,
    //     targetY,
    //     targetPosition: Position.Left,
    // });

    return (
        <>
            {/* Render the base edge path with the curved path */}
            <BaseEdge id={id} path={edgePath} />

            {/* Edge label renderer with the delete button, conditionally rendered */}
            <EdgeLabelRenderer>
                {isSelected && (
                    <button
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            pointerEvents: 'all',
                        }}
                        className="nodrag nopan bg-white p-1 rounded-full shadow-md hover:bg-red-500 hover:text-white transition duration-200"
                        onClick={onDelete}  // Call the onDelete function passed from App.jsx
                    >
                        {/* Trash icon using react-icons */}
                        <FaTrash size={12} />
                    </button>
                )}
            </EdgeLabelRenderer>
        </>
    );
}
