import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
// import "./newsandnotice.css";
import "../../css/style.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";
import Backend_Url from "../../config/config";
import ModalAddCategory from "./ModalAddCategory";

const ProductCategoryMaster = () => {
    //**************************************************** */


    const [isModalOpenCategory, setIsModalOpenCategory] = useState(false);

    const openModalCategory = () => {
        setIsModalOpenCategory(true);
    };

    const closeModal = () => {
        setIsModalOpenCategory(false);
    };




    const [Category, setCategory] = useState(null);
    const [Image, setImage] = useState({
        FaviconImage: null,
        SelectLogo: null,

    });




    // const [faviconImage, setFaviconImage] = useState(null);
    // const [selectLogo, setSelectLogo] = useState(null);

    const [inputValues, setInputValues] = useState({
        ProjectName: '',
        Email: '',
        Phone: '',
        Address1: '',
        Address2: '',
        Address3: '',
        Address4: '',
        GoogleMapLink: '',
        FacebookLink: '',
        TwitterLink: '',
        InstagramLink: '',
        LinkedinLink: '',
        GooglePlusLink: '',
        YoutubeLink: '',
        CopyrightInfo: '',
        PrivacyPolicyInfo: '',
        MetaDescription: '',
        MetaKeyWords: '',
        MetaAuthor: '',
        SendEmailTo: '',
        SendSms: '',
        ProjectFontFamilyLinkURL: '',
        ProjectBackgroundColor: '',
        MenuBackgroundColor: '',
        MenuTextColor: '',
        MenuFontFamily: '',
        MenuFontSize: '',
        MenuFontWeight: ''

    });

    console.log(inputValues);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // const handleImage = (e) => {
    //     const { name, files } = e.target;
    //     setImage((prevData) => ({
    //         ...prevData,
    //         [name]: files[0]
    //     }));

    // };

    const handleImage = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        // Read the file and set the image URL
        const reader = new FileReader();
        reader.onloadend = () => {
            const imageUrl = reader.result;
            setImage((prevData) => ({
                ...prevData,
                [name]: file,
                [`${name}URL`]: imageUrl
            }));
            // Store selected logo URL in localStorage
            localStorage.setItem('selectedLogo', imageUrl);
        };
        reader.readAsDataURL(file);
    };

    console.log(Image);

    const handleSubmit = async (e) => {
        e.preventDefault();
        alert("submit")

        const formData = new FormData()
        Object.keys(inputValues).forEach(key => {
            formData.append(key, inputValues[key]);
        });

        formData.append('SelectLogo', Image.SelectLogo)
        formData.append('FaviconImage', Image.FaviconImage)

        console.log(Backend_Url);

        try {
            const response = await fetch(`${Backend_Url}/GeneralSetting/create`, {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // },
                body: formData
            });

            if (response) {
                const result = await response.json();
                console.log('Form submitted successfully:', result);
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const fetchdata = async (id) => {
            try {
                const response = await fetch(`${Backend_Url}/GeneralSetting/${id}`)
                console.log();
                var Data = await response.json()
                const newInputValues = {};
                for (const key in inputValues) {
                    if (Data.hasOwnProperty(key)) {
                        newInputValues[key] = Data[key];
                    }
                }
                setInputValues(newInputValues);
                const storedLogo = localStorage.getItem('selectedLogo');
                if (storedLogo) {
                    setImage({
                        ...Image,
                        SelectLogoURL: storedLogo
                    });
                } else {
                    setImage({
                        ...Image,
                        SelectLogoURL: Data.SelectLogo // Set initial logo URL from backend response
                    });
                }
            }
            catch (error) {
                error("enable to fetch")
            }
        }
        fetchdata(1)
    }, [])

    return (
        <div className="App">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="body-content">
                <div className="heading">
                    <h1 className="General-Setting-Head">Product Category Master</h1>
                </div>
                <div className="General-form-content">
                    <div className="Category-buttons">
                        <button type="submit" className="Create-button" onClick={openModalCategory}>
                            + Add Category
                        </button>
                    </div>
                    <div className="heading">
                        <h1 className="General-Setting-Head">Root Category List</h1>
                    </div>
                    <div className="">
                        <table>
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Root/Sub/Child Menu</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {Category.map((slider, index) => ( */}
                                <tr >
                                    <td>1</td>
                                    <td className="Image-Product-Cate ">
                                        {/* {slider.Imagepaths.map((image, imageIndex) => ( */}
                                        <div className="image-parent-slider">
                                            <img
                                                className="image-container-slider"
                                                src=""
                                                alt=""
                                                width="200px"
                                                height="50px"
                                            />
                                        </div>
                                        {/* ))} */}
                                    </td>
                                    <td>Project Name</td>
                                    <td>
                                        <div className="buttons">
                                            <button
                                                className="Add-button"
                                            >
                                                <Icon icon="icon-park-solid:add" />
                                            </button>
                                            <button
                                                className="update-button"
                                            >
                                                <Icon icon="flowbite:edit-solid" />
                                            </button>
                                            <button
                                                className="delete-button"
                                            >
                                                <Icon icon="material-symbols:delete-outline" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                {/* ))} */}
                            </tbody>
                        </table>
                    </div>
                    {isModalOpenCategory && (
                        <div className="modal-Category">
                            <div className="modal-content">
                                <div className="modal-title">
                                    <h3 className="modal-heading-top font-bold text-[1.3rem]">Add Category</h3>
                                <span className="close" onClick={closeModal}>&times;</span>
                                </div>
                                <div>
                                < ModalAddCategory />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductCategoryMaster;
