import React from 'react'
import { DialogClose } from "../../../Component/ui/dialog"

const UpdateButton = ({type, value, updateNode, title}) => {
  return (
    <div className='mt-4 text-end pr-10'>
      <button onClick={()=>{title===false? alert(`please add ${type} title`):value?.length>0 ?updateNode(type,value, title):type==='image'?alert('please select image!'): alert('please add value!')}} className='absolute bottom-3 right-5 px-3 py-1 bg-green-700 text-white rounded-md text-[.9rem]'>Update</button>
  </div>
  )
}

export default UpdateButton