import React, { Children, useContext, useEffect } from "react";
import { EditNode } from "./EditNode";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { nodeSliceAction } from "../../../Store/store";
import MyContext from "../../../context/Mycontext";

export default function Sidebar({
  nodeName,
  setNodeName,
  selectedNode,
  setSelectedElements,
  setNodes,
  preview,
  nodes
}) {


  const { tempNode } = useSelector(store => store.nodeSlice);
  const { setIsFlowModalOpen } = useContext(MyContext)
  const dispatch = useDispatch();

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };


  const handleDelete = () => {
    setNodes(prev => {
      if (Array.isArray(selectedNode?.parents)) {
        // If the node has parents, find the parent and filter out the child
        return prev.map(elm => {
          if (elm.id === selectedNode.parents[0]) {
            return {
              ...elm,
              children: elm.children.filter(e => e.id !== selectedNode.id) // Filter the children of the parent
            };
          } else {
            return elm
          }
        });
      } else {
        // If no parents, just filter out the node from the main array
        return prev.filter(elm => elm.id !== selectedNode.id);
      }
    });

    setNodes(prev => prev.filter(e => e.id !== selectedNode.id));

    setSelectedElements([]); // Reset selected elements
  };

  // Function to download the JSON file
  const downloadJSON = () => {
    setIsFlowModalOpen(false)
    dispatch(nodeSliceAction.exportToJSON(nodes));
  };

  return (
    <aside className={`border-r-2 border-blue-200 p-4 text-sm bg-blue-100 ${preview ? 'w-full' : 'w-64'} h-screen text-black`}>
      {selectedNode ? (
        //settings panel
        <div className="flex ">
          <button
            onClick={(e) => e.preventDefault()}
            className="mt-4 bg-green-600 text-white rounded p-2 hover:bg-green-500"
          >
            <label htmlFor=""><EditNode setNodes={setNodes} /></label>
          </button>
          <button
            className="mt-4 ml-4 bg-blue-500 text-white rounded p-2 hover:bg-blue-600"
            onClick={() => setSelectedElements([])}
          >
            Go Back
          </button>
          <button
            className="mt-4 ml-4 bg-red-600 px-4 text-white rounded p-2 hover:bg-red-500"
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
      ) : !preview ? (
        //node panel
        <div className="flex h-full flex-col justify-between gap-3">
          <div className="flex flex-col gap-3">
            <h3 className="text-xl mb-4 text-blue-900">Nodes Panel</h3>
            <div
              className="bg-white p-3 border-2 border-blue-500 rounded cursor-move flex justify-center items-center text-blue-500 hover:bg-blue-500 hover:text-white transition-colors duration-200"
              onDragStart={(event) => onDragStart(event, "textnode")}
              draggable
            >
              Children Node's
            </div>
            {/* <div
              className="bg-white p-3 border-2 border-blue-500 rounded cursor-move flex justify-center items-center text-blue-500 hover:bg-blue-500 hover:text-white transition-colors duration-200"
              onDragStart={(event) => onDragStart(event, "questionNode")}
              draggable
            >
              Questxion Node
            </div> */}
            <div
              className="bg-white p-3 border-2 border-blue-500 rounded cursor-move flex justify-center items-center text-blue-500 hover:bg-blue-500 hover:text-white transition-colors duration-200"
              onDragStart={(event) => onDragStart(event, "screenNameNode")}
              draggable
            >
              Screen  Node
            </div>
          </div>
          <div>
            <button onClick={downloadJSON} className="mb-3 text-center cursor-pointer w-full border py-2 bg-blue-700 rounded-sm text-white font-semibold active:scale-95 transition-all">Save Flow</button>
          </div>
        </div>
      ) : null
      }
    </aside>
  );
}
