import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import loader from '../../Assets/Images/Loader.json'
function Loader({ width = 150, height = 150 }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Player
                autoplay
                loop
                src={loader}
                style={{ width: `${width}px`, height: `${height}px` }}

            />
        </div>
    );
}

export default Loader;
