import { Button } from "../../../Component/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "../../../Component/ui/dialog";
import { Input } from "../../../Component/ui/input";
import { Label } from "../../../Component/ui/label";
import { IoSettings } from "react-icons/io5";
import { EditNodeMenu } from "./EditNodeMenu";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DragAndDrop from "./DragAndDrop";
import { DropDown, MultipleChoise, SingleChoise, Option } from "./Selection";
import { TextHeading, TextSubHeading, TextBody, TextCaption } from "./Text";
import { nodeSliceAction } from "../../../Store/store";
import TextInput, { DatePicker, TextArea } from "./TextInput";
import { Switch } from "../../../Component/ui/switch";
import UpdateButton from "./UpdateButton";

export function EditNode({ setNodes }) {
  const dispatch = useDispatch();
  const { selectedNode } = useSelector((store) => store.nodeSlice);
  const [nodeValue, setNodeValue] = useState(selectedNode?.data?.label);
  const [openValue, setOpenValue] = useState(selectedNode.elementType);
  const [screenName, setScreenName] = useState(selectedNode?.data?.label);


  useEffect(() => {
    setNodeValue(selectedNode?.data?.label);
  }, []);

  const openOption = (value) => {
    setOpenValue(value);
  };

  const demo = { name: "khilesh" };

  const updateNode = (type, value, title) => {
    setOpenValue("");
    switch (type) {
      case "textheading":

        // Remove the selected node from the state
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));

        // Create the updated node with the new data
        const updatedData1 = {
          ...selectedNode,
          data: { label: value },
          elementType: type,
          element: value
        };

        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData1;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }

        // Dispatch the updated node to the Redux store or state management
        dispatch(nodeSliceAction.selectNode(updatedData1));

        // Re-add the updated node to the state
        setNodes((prev) => [...prev, updatedData1]);

        break;

      case "textsubheading":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData2 = {
          ...selectedNode,
          data: { label: value },
          elementType: type,
          element: value
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData2;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData2));
        setNodes((prev) => [...prev, updatedData2]);
        break;

      case "image":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData3 = {
          ...selectedNode,
          data: { label: String(type).toLocaleUpperCase() },
          element: value,
          elementType: type,
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData3;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData3));
        setNodes((prev) => [...prev, updatedData3]);
        break;

      case "radio":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData4 = {
          ...selectedNode,
          data: { label: value.map(elm => <p className="block">🔘 {elm.radioName}</p>) },
          element: value,
          title,
          elementType: type,
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData4;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData4));
        setNodes((prev) => [...prev, updatedData4]);

        break;
      case "checkbox":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData5 = {
          ...selectedNode,
          data: { label: value.map(elm => <p className="block">&#9989;{elm.checkboxName}</p>) },
          element: value,
          title,
          elementType: type,
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData5;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData5));
        setNodes((prev) => [...prev, updatedData5]);

        break;
      case "dropdown":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData6 = {
          ...selectedNode,
          data: {
            label: <select>
              {value.map(elm => <option value={elm.dropDownName}>{elm.dropDownName}</option>)}
            </select>
          },
          element: value,
          title,
          elementType: type,
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData6;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData6));
        setNodes((prev) => [...prev, updatedData6]);

        break;
      case "option":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData7 = {
          ...selectedNode,
          data: { label: <Switch /> },
          element: value,
          elementType: type,
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData7;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData7));
        setNodes((prev) => [...prev, updatedData7]);

        break;

      case "input":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData8 = {
          ...selectedNode,
          data: { label: value },
          element: value,
          elementType: type,
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData8;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData8));
        setNodes((prev) => [...prev, updatedData8]);

        break;

      case "date":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData9 = {
          ...selectedNode,
          data: { label: value },
          element: value,
          elementType: type,
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData9;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData9));
        setNodes((prev) => [...prev, updatedData9]);

        break;

      case "textarea":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData10 = {
          ...selectedNode,
          data: { label: value },
          element: value,
          elementType: type,
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData10;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData10));
        setNodes((prev) => [...prev, updatedData10]);

        break;

      case "textbody":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData11 = {
          ...selectedNode,
          data: { label: value },
          elementType: type,
          element: value
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData11;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData11));
        setNodes((prev) => [...prev, updatedData11]);
        break;

      case "textcaption":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData12 = {
          ...selectedNode,
          data: { label: value },
          elementType: type,
          element: value
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData12;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData12));
        setNodes((prev) => [...prev, updatedData12]);
        break;

      case "ScreenName":
        setNodes((prev) => prev.filter((elm) => elm.id !== selectedNode.id));
        const updatedData13 = {
          ...selectedNode,
          data: { label: value },
          element: value,
          elementType: type,
        };
        // Check if the selected node has parents
        if (selectedNode?.parents?.length > 0) {
          setNodes((prev) => {
            return prev.map((elm) => {
              if (elm.id === selectedNode.parents[0]) {
                if (Array.isArray(elm.children)) {
                  return {
                    ...elm, children: elm.children.map((e) => {
                      if (e.id === selectedNode.id) {
                        return updatedData13;
                      } else {
                        return e;
                      }
                    }),
                  };
                } else {
                  return elm;
                }
              } else {
                return elm;
              }
            });
          });
        }
        dispatch(nodeSliceAction.selectNode(updatedData13));
        setNodes((prev) => [...prev, updatedData13]);

        break;

      default:
        alert("invalid action!");
        break;
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <label
          htmlFor=""
          className="cursor-pointer active:-rotate-45 transition-all"
        >
          <IoSettings fontSize={"1.3rem"} />
        </label>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <div>
          <DialogHeader>
            <DialogTitle>Set Attribute</DialogTitle>
          </DialogHeader>
          {selectedNode.type !== "screenNameNode" ? (<div className="grid gap-4 py-4">
            <div className="grid grid-cols-2 items-center gap-4">
              <EditNodeMenu openOption={openOption} />
            </div>

            <div className="grid grid-cols-1 relative min-h-[200px] items-start gap-4 border rounded-sm shadow-md px-2 py-2">
              {openValue === "media" ? (
                <DragAndDrop updateNode={updateNode} />
              ) : openValue === "radio" ? (
                <SingleChoise updateNode={updateNode} />
              ) : openValue === "checkbox" ? (
                <MultipleChoise updateNode={updateNode} />
              ) : openValue === "drop" ? (
                <DropDown updateNode={updateNode} />
              ) : openValue === "textheading" ? (
                <TextHeading
                  value={selectedNode?.data?.label}
                  updateNode={updateNode}
                />
              ) : openValue === "textsubheading" ? (
                <TextSubHeading
                  value={selectedNode?.data?.label}
                  updateNode={updateNode}
                />
              ) : openValue === "textbody" ? (
                <TextBody
                  value={selectedNode?.data?.label}
                  updateNode={updateNode}
                />
              ) : openValue === "textcaption" ? (
                <TextCaption
                  value={selectedNode?.data?.label}
                  updateNode={updateNode}
                />
              ) : openValue === "input" ? (
                <TextInput
                  value={selectedNode.data?.label}
                  updateNode={updateNode}
                />
              ) : openValue === "option" ? (
                <Option updateNode={updateNode} value={selectedNode.data?.label} />
              ) : openValue === "date" ? (
                <DatePicker updateNode={updateNode} value={selectedNode.data?.label} />
              ) : openValue === "textarea" ? (
                <TextArea updateNode={updateNode} value={selectedNode.data?.label} />
              ) : null}
            </div>
          </div>) : (
            <div>
              <label htmlFor="">Screen Name</label>
              <div className='mt-4 w-full'>
                <input type="text" onChange={e => setScreenName(e.target.value)} value={screenName} className='w-full rounded-sm outline-none border-2 px-2 py-1 focus:border-blue-700' />
              </div>
              <DialogClose asChild >
                <div className='mt-6 text-end pr-10'>
                  <button onClick={() => { updateNode("ScreenName", screenName) }} className='absolute bottom-3 right-5 px-3 py-1 bg-green-700 text-white rounded-md text-[.9rem]'>Update</button>
                </div>
              </DialogClose>
            </div>
          )}

        </div>
      </DialogContent>
    </Dialog>
  );
}
