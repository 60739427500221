import React, { useEffect, useState, useRef, useContext } from "react";
import "../../css/style.css";
import "../../css/whatsapp.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
import MyContext from "../../context/Mycontext";
// import Modal from "./Modal";



const WhatsappBtnMenu = ({isDesktopFooter, setActiveTab, activeTab}) => {
    //**************************************************** */

    const handleIconClick = (iconName) => {
      setActiveTab(iconName);
    };

    const { modalopen, setmodalopen } = useContext(MyContext);
    return (

        <div className={`chat__footer_mobile ${isDesktopFooter ? '!flex' : null} !w-full`}>
            <div className="footer-icon_mobile">
                {/* <Icon className="Icon-footer-wtp" icon="material-symbols:chat" /> */}
                <Icon
                    className={`Icon-footer-wtp ${activeTab === 'chats' ? 'active' : ''}`}
                    icon="material-symbols:chat"
                    onClick={() => handleIconClick('chats')}
                />
                <span>Chats</span>
            </div>
            <div className="footer-icon_mobile" onClick={()=>setmodalopen(true)}>
                {/* <Icon className="Icon-footer-wtp" icon="f7:status" /> */}
                <Icon
                    className={`Icon-footer-wtp ${activeTab === 'instaPost' ? 'active' : ''}`}
                    icon="ep:plus"
                    onClick={() => handleIconClick('chats')}
                />
                <span>Insta Post</span>
            </div>
            <div className="footer-icon_mobile">
                {/* <Icon className="Icon-footer-wtp" icon="mdi:person-group" /> */}
                <Icon
                    className={`Icon-footer-wtp ${activeTab === 'campaign' ? 'active' : ''}`}
                    icon="mdi:person-group"
                    onClick={() => handleIconClick('campaign')}
                />
                <span>Campaign</span>
            </div>
            <div className="footer-icon_mobile">
                {/* <Icon className="Icon-footer-wtp" icon="material-symbols:call" /> */}
                <Icon
                    className={`Icon-footer-wtp ${activeTab === 'contacts' ? 'active' : ''}`}
                    icon="system-uicons:grid-squares-add"
                    onClick={() => handleIconClick('contacts')}
                />
                <span>Contact</span>
            </div>
        </div>


    );
};

export default WhatsappBtnMenu;
