import React, { useMemo, useRef, useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";

import { IoEye, IoEyeOff } from "react-icons/io5";
import Loader from "../Component/Loader/Loader";
import { useCookies } from "react-cookie";
import CircleLoader from "./Loader/CircleLoader";
import axios from "axios";
import Backend_Url from "../config/config";
// import { IoEyeOff } from "react-icons/io5";

import { LuLogOut } from "react-icons/lu";
import MyContext from "../context/Mycontext";



const IntegrationLoginModal = ({ editableItem, setOpenYesBMModal, fetchGrpData, fetchContactData, isModalFromContacts }) => {

    const [cookies, setCookies] = useCookies(['token', 'YES-BM-CLIENT', 'ASP.NET_SessionId']);
    const [yesbmLoader, setYesbmloader] = useState(false);
    const token = cookies.token;
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isGetURL, setIsGetURL] = useState(false);
    const [connectLoader, setConnectLoader] = useState(false)
    const [loginLoader, setLoginLoader] = useState(false)
    const [apiUrl, setApiUrl] = useState('')
    const [companyList, setCompanyList] = useState([])
    const [company_code, setCompany_code] = useState('')

    const { yesbmLoginStatus, setYesbmLoginStatus } = useContext(MyContext);

    const [data, setData] = useState({
        username: '',
        password: '',
    })


    useEffect(() => {
        debugger
        const getApiUrl = async () => {
            try {
                const response = await axios.get(`${Backend_Url}/Yesbm/getApiUrl`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                console.log('response useEffect', response)

                if (response.status === 200) {
                    setApiUrl(response.data.response.apiUrl)
                    setIsGetURL(true)
                    setYesbmLoginStatus(true)
                    // handleConnectUrl()
                }
            } catch (error) {
                console.log(error)
            }
        }

        getApiUrl();

    }, [])

    useEffect(() => {
        console.log('api Url is', apiUrl);
        if (!apiUrl) return
        handleConnectUrl();
    }, [])



    const handleDataChange = (e) => {
        const { name, value } = e.target;
        setData(prev => ({ ...prev, [name]: value }))
    }

    const handleLogin = async (e) => {
        debugger
        e.preventDefault();

        if (!apiUrl || !company_code || !data.username || !data.password) {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill all neccessory field!.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return
        }
        setLoginLoader(true)

        const parts = apiUrl?.split('?');

        const url = parts[0]

        const loginData = {
            ComCode: company_code,
            UserId: data.username,
            Passerd: data.password,
            api_url: url
        }

        try {
            const response = await axios.post(`${Backend_Url}/Yesbm/login`, loginData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                withCredentials: true
            });

            console.log(response.data.data, response.status)

            if (response.status === 200) {
                if (response.data.data.s3) {
                    setYesbmLoginStatus(true);
                    Swal.fire({
                        title: 'Success!',
                        text: 'Login successfully!.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });



                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Please enter valid credentials!.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
                console.log('login status', response);
            }
        } catch (error) {

        } finally {
            setLoginLoader(false);
        }

    }


    useEffect(() => {
        console.log(apiUrl)
    }, [apiUrl])

    // new api:- http://137.59.201.149/YES-BM-Techcherry/InceptionService.asmx/CustomerReminderDateListForWhatsapp 
    const handleConnectUrl = async (e) => {
        debugger
        if (e) {
            e.preventDefault();
        }

        if (!apiUrl) {
            Swal.fire({
                title: 'Error!',
                text: 'Please enter API URL!',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        const parts = apiUrl?.split('?');

        const url = parts[0]
        // setApiUrl(url)
        const cookies = parts[1]


        console.log('url', url)
        console.log('cookies', cookies);

        let cookiesToken;
        if (cookies) {
            cookiesToken = cookies.split('=')[1];
            setCookies("YES-BM-CLIENT", cookiesToken);
        }


        setConnectLoader(true);
        try {
            const response = await axios.post(`${Backend_Url}/Yesbm/get-companylist`, {
                api_url: url
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                withCredentials: true

            });

            console.log(response.data.data, response.status)

            if (response.status === 200) {
                if (response.data.data?.length > 0) {

                    // const response = 
                    setIsGetURL(true);
                    setCompanyList(response.data.data)
                }
            } else {

            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Please enter valid API URL!',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            console.log('error : ', error)
        } finally {
            setConnectLoader(false);
        }
        setConnectLoader(false);
    }

    const handleSelect = (e) => {
        console.log('target', e.target.value)
        setCompany_code(e.target.value);
    }



    const handleLogout = async (e) => {
        e.preventDefault();
        setLoginLoader(true);
        try {
            const response = await axios.put(`${Backend_Url}/Yesbm/logout`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            if (response.status === 200) {
                setYesbmLoginStatus(false)
                setIsGetURL(false);
                setApiUrl('')
                Swal.fire({
                    title: 'Success!',
                    text: 'Logout successfully!.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            }

        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Something went wrong. Please try after some times!',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            console.log(error);
        } finally {
            setLoginLoader(false);
        }
    }
    const [saveContactType, setSaveContactType] = useState('');



    const getContact = async () => {
        setYesbmloader(true)
        debugger
        try {

            const swal = Swal.fire({
                title: 'Processing...',
                text: 'Please wait while we process your request.',
                showConfirmButton: false,  // Hide the confirm button
                willOpen: () => {
                  Swal.showLoading();  // Show the loading spinner
                }
              });

            let url;

            let data = {}
            url = `http://137.59.201.149/YES-BM-Techcherry/InceptionService.asmx/CustomerReminderDateListForWhatsapp`;
            data = {
                apiUrl: url,
            }
            const response = await axios.post(`${Backend_Url}/Yesbm/save-contact`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                withCredentials: true
            })

            if(response.status==200){
                Swal.fire({
                    title: 'Done!',
                    text: 'The process has been completed successfully.',
                    icon: 'success',
                    cancelButtonText:'Okay'
                  });
            }

            console.log('response', response)

        } catch (error) {
            console.log('catch', error)
            if (error.response.data.NotfoundStatus) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Contact not found!.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } finally {
            setYesbmloader(false)
        }
    }
    // const getContact = async () => {
    //     setYesbmloader(true)
    //     debugger
    //     try {

    //         const start = 0;
    //         const end = 1000;
    //         const format = { grp: "212", txtsearch: "", start: start, end: end, FromDate: "", ToDate: "", filter: "__%" }
    //         const jsonData = encodeURIComponent(JSON.stringify(format))
    //         console.log(jsonData);
    //         // const data = {}
    //         const getContactUrl = apiUrl.split('/LoginPage')[0]
    //         console.log('getContact', getContactUrl);


    //         //DOB   CRM/ReportNextVisit_LensExpiry.aspx/GetData

    //         // CRM/ReportNextVisit_LensExpiry.aspx/GetData
    //         //new url :-http://137.59.201.149/YES-BM-Techcherry/InceptionService.asmx/CustomerReminderDateListForWhatsapp 
    //         // https://www.myopticalapp.in/InceptionService.asmx/viewAccountMaster
    //         let url;

    //         let data = {}

    //         const getCurrentDate = () => {
    //             const currentDate = new Date();

    //             const day = String(currentDate.getDate()).padStart(2, '0'); // Adds leading zero if day < 10
    //             const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    //             const year = currentDate.getFullYear();

    //             return `${day}-${month}-${year}`;
    //         };

    //         if (saveContactType === 'GETALL') {
    //             url = `${getContactUrl}/InceptionService.asmx/viewAccountMaster`;
    //             data = {
    //                 apiUrl : url,
    //                 arg : jsonData
    //             }
    //         } else if (saveContactType === '4' || saveContactType === '5') {


    //             url = `http://137.59.201.149/YES-BM-Techcherry/InceptionService.asmx/CustomerReminderDateListForWhatsapp`;
    //             data = {
    //                 apiUrl: url,               

    //             }


    //             const response = await axios.post(`${Backend_Url}/Yesbm/save-dob`, data, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`
    //                 },
    //                 withCredentials: true
    //             })

    //             console.log(response);
    //             return
    //         }


    //         const response = await axios.post(`${Backend_Url}/Yesbm/save-contact`, data, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             },
    //             withCredentials: true
    //         })

    //         if (response.status === 200) {
    //             Swal.fire({
    //                 title: 'Success!',
    //                 text: 'Contacts are successfully save!.',
    //                 icon: 'success',
    //                 confirmButtonText: 'OK'
    //             });
    //         }
    //         console.log(response)
    //     } catch (error) {
    //         console.log('catch', error)
    //         if (error.response.data.NotfoundStatus) {
    //             Swal.fire({
    //                 title: 'Error!',
    //                 text: 'Contact not found!.',
    //                 icon: 'error',
    //                 confirmButtonText: 'OK'
    //             });
    //         }
    //     } finally {
    //         setYesbmloader(false)
    //     }
    // }



    return (

        <div>
            {
                !isModalFromContacts && (
                    <form onSubmit={handleLogin} className="Model-Query-Content overflow-auto flex flex-col justify-start items-center !px-5 py-5 min-h-[200px] gap-4">
                        <div className="grid grid-cols-3 gap-2 justify-start items-center">
                            <label htmlFor="api_url" className="text-end mr-2">API URL</label>

                            <input
                                type="text"
                                value={apiUrl}  // Bind value to the state
                                readOnly={isGetURL}
                                onChange={(e) => setApiUrl(e.target.value)}  // Handle changes for this input
                                //  className="!m-0 p-[10px] !border-[1px] !border-[#ddd] rounded-[4px]"
                                className='Yes-BM-login-field !m-0'
                            />

                            <div className="flex items-center">
                                <button disabled={isGetURL} onClick={handleConnectUrl} className={`px-4 disabled:cursor-not-allowed cursor-pointer ml-5 py-2 w-[100px] text-white bg-[#2E498C] flex justify-center items-center rounded-[6px]`}>{connectLoader ? <CircleLoader size={25} /> : 'Connect'}</button>
                            </div>
                        </div>
                        {
                            isGetURL && !yesbmLoginStatus && <>
                                <div className="grid grid-cols-2 justify-between items-center w-[55%]">
                                    <label htmlFor="username">Company List</label>
                                    <select name="company_code" id="" className='' onChange={handleSelect}>
                                        <option value="" >select company</option>
                                        {
                                            companyList?.length > 0 && companyList.map(company => (
                                                <option key={company.Code} value={company.Code}>{company.Name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="grid grid-cols-2 justify-start items-center">
                                    <label htmlFor="username">Username</label>
                                    <input
                                        type="username"
                                        name="username"  // Add a name to reference the field
                                        value={data.username}  // Bind value to the state
                                        onChange={handleDataChange}  // Handle changes for this input
                                        // className="p-[10px] border-[1px] border-[#ddd] rounded-[4px]"
                                        className='Yes-BM-login-field'
                                    />
                                </div>
                                <div className="grid grid-cols-2 justify-start relative items-center">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type={`${isShowPassword ? "text" : "password"}`}
                                        name="password"  // Add a name to reference the field
                                        value={data.password}  // Bind value to the state
                                        onChange={handleDataChange}  // Handle changes for this input
                                        // className="p-[10px] border-[1px] border-[#ddd] rounded-[4px]"
                                        className='Yes-BM-login-field'
                                    />
                                    {
                                        isShowPassword ?
                                            <IoEye className="absolute -right-2 top-3 cursor-pointer" onClick={() => setIsShowPassword(false)} />
                                            :
                                            <IoEyeOff className="absolute -right-2 top-3 cursor-pointer" onClick={() => setIsShowPassword(true)} />
                                    }
                                </div>

                            </>
                        }
                        {(isGetURL && !yesbmLoginStatus) && <div className="!py-2">
                            <button type="submit" className="px-4 cursor-pointer ml-5 py-2 w-[100px] text-white bg-[#2E498C] flex justify-center items-center rounded-[6px]">{loginLoader ? <CircleLoader size={25} /> : 'Login'}</button>
                        </div>}
                        {yesbmLoginStatus && <div className="!py-2 flex justify-start">
                            <button onClick={handleLogout} className="px-4 cursor-pointer ml-5 py-2 w-[100px] text-white bg-[#2E498C] flex justify-center items-center rounded-[6px]">{loginLoader ? <CircleLoader size={25} /> : <div className="flex items-center gap-1">Logout <LuLogOut /></div>}</button>
                        </div>}
                    </form>
                )
            }

            {
                isModalFromContacts && (
                    <form onSubmit={getContact} className="Model-Query-Content overflow-auto flex flex-col justify-start items-center !px-5 py-5 min-h-[200px] gap-4">

                        {/* <div className="grid grid-cols-2 justify-between items-center w-[55%]">
                            <label htmlFor="username">Include Fields</label>
                         
                        </div> */}

                        {<div className="!py-2">
                            <button type="submit" className="px-4 cursor-pointer ml-5 py-2 w-full text-white bg-[#2E498C] flex justify-center items-center rounded-[6px]">{loginLoader ? <CircleLoader size={25} /> : 'Import Contact'}</button>
                        </div>}
                        {yesbmLoginStatus && <div className="!py-2 flex justify-start">

                        </div>}
                    </form>
                )
            }
        </div>
    );
};

export default IntegrationLoginModal;
