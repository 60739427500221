import {
  Menubar,
  MenubarCheckboxItem,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarRadioGroup,
  MenubarRadioItem,
  MenubarSeparator,
  MenubarShortcut,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "../../../Component/ui/menubar"
import { RiArrowRightSLine } from "react-icons/ri";
import { FaImage } from "react-icons/fa6";
import { MdFormatListBulleted } from "react-icons/md";
import { IoIosCheckbox } from "react-icons/io";
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoIosSwitch } from "react-icons/io";

export function EditNodeMenu({ openOption }) {
  return (
    <Menubar className="flex flex-col h-full relative">
      <MenubarMenu>
        <MenubarTrigger className="flex justify-between w-full cursor-pointer">
          <div>
            <label htmlFor="" className="font-bold mr-2">Aa</label>
            <label htmlFor="" className="">Text</label>
          </div>
          <div>
            <label htmlFor="" className=""><RiArrowRightSLine cursor={'pointer'} fontSize={'1.3rem'} /></label>
          </div>
        </MenubarTrigger>
        <MenubarContent className="ml-[170px] -70px -mt-[40px]">
          <MenubarItem onClick={() => openOption('textheading')}>
            Text Heading
          </MenubarItem>
          <MenubarItem onClick={() => openOption('textsubheading')}>
            Text Subheading
          </MenubarItem>
          <MenubarItem onClick={() => openOption('textbody')}>
            Text Body
          </MenubarItem>
          <MenubarItem onClick={() => openOption('textcaption')}>
            Text Caption
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger className="flex justify-between w-full cursor-pointer">
          <div className="flex  items-center">
            <label htmlFor="" className="font-bold mr-2"><FaImage /></label>
            <label htmlFor="" className="">Media</label>
          </div>
          <div>
            <label htmlFor="" className=""><RiArrowRightSLine cursor={'pointer'} fontSize={'1.3rem'} /></label>
          </div>
        </MenubarTrigger>
        <MenubarContent className="ml-[170px] -70px -mt-[40px]">
          <MenubarItem onClick={() => openOption('media')} className="flex items-center gap-2">
            <label htmlFor=""><FaImage /></label>
            <label htmlFor="">image</label>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger className="flex justify-between w-full cursor-pointer">
          <div>
            <label htmlFor="" className="font-bold mr-2 bg-slate-800 text-white px-[3px] rounded-sm py-[1px] text-[.5rem]">Aa</label>
            <label htmlFor="" className="">Input</label>
          </div>
          <div>
            <label htmlFor="" className=""><RiArrowRightSLine cursor={'pointer'} fontSize={'1.3rem'} /></label>
          </div>
        </MenubarTrigger>
        <MenubarContent className="ml-[170px] -70px -mt-[40px]">
          <MenubarItem onClick={() => openOption('input')} className="flex items-center gap-2">
            Input Field
          </MenubarItem>
          <MenubarItem onClick={() => openOption('date')} className="flex items-center gap-2">
            Date Picker
          </MenubarItem>
          <MenubarItem onClick={() => openOption('textarea')} className="flex items-center gap-2">
            Textarea
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger className="flex justify-between w-full cursor-pointer">
          <div className="flex items-center">
            <label htmlFor="" className="font-bold mr-2"><MdFormatListBulleted fontSize={'1.1rem'} /></label>
            <label htmlFor="" className="">Selection</label>
          </div>
          <div>
            <label htmlFor="" className=""><RiArrowRightSLine cursor={'pointer'} fontSize={'1.3rem'} /></label>
          </div>
        </MenubarTrigger>
        <MenubarContent className="ml-[170px] -70px -mt-[40px]">
          <MenubarItem onClick={() => openOption('radio')} className="flex items-center gap-2">
            <label htmlFor=""><IoMdRadioButtonOn /></label>
            <label htmlFor="">Single choice</label>
          </MenubarItem>
          <MenubarItem onClick={() => openOption('checkbox')} className="flex items-center gap-2">
            <label htmlFor=""><IoIosCheckbox /></label>
            <label htmlFor="">Multiple choise</label>
          </MenubarItem>
          <MenubarItem onClick={() => openOption('drop')} className="flex items-center gap-2">
            <label htmlFor=""><MdFormatListBulleted /></label>
            <label htmlFor="">Drop-Down</label>
          </MenubarItem>
          <MenubarItem onClick={() => openOption('option')} className="flex items-center gap-2">
            <label htmlFor=""><IoIosSwitch /></label>
            <label htmlFor="">Opt-in</label>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
