import React, { useEffect } from 'react';
import "./WAComponent.css"
import { Icon } from '@iconify/react/dist/iconify.js';
import { NavLink, useNavigate } from 'react-router-dom';
import WTPSideBar from '../WhatsappCampaigns/WTPSideBar';
import { useState } from 'react';
import Backend_Url from '../../config/config';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const WhatsappAutomationAndChatBot = () => {

    const navigate = useNavigate();

    const [cookie] = useCookies(["token"])
    const token = cookie.token;

    const [automationDetail, setAutomationDetail] = useState()

    const fetchData = async () => {
        if (!token) {
            console.error('No token found');
            return;
        }
        try {
            const response = await axios.get(`${Backend_Url}/whatsappAutomations/whatsappAutomations`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(response.data);
            if (response?.data) {
                setAutomationDetail(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    const deleteAutomation = async (id) => {
        if (!token) {
            console.error('No token found');
            return;
        }
        try {
            const response = await axios.delete(`${Backend_Url}/whatsappAutomations/whatsappAutomations/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response) {
                alert("Deleted data Successfully");
                fetchData();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const editAutomation = async (id) => {
        navigate(`/AutomationDetail`, { state: { id } })
    }

    return (
        <div className="Flex-Head-Right-Compaigns">
            <div>
                <WTPSideBar />
            </div>
            <div className="Right-Campaigns-Body" style={{ backgroundColor: "#f8f9fa" }}>
                <div className='alignLeftContent-automation'>
                    <label style={{ fontSize: "18px", fontWeight: "600" }}>Whatsapp Automation & Chatbot</label>
                    <label style={{ marginTop: "5px", fontSize: "14px" }}><b>Note : </b>Please set up your Facebook Cloud API Credential before creating automation.</label>
                </div>
                <div className='container_wa-automation'>
                    <div className='spaceBetween-automation'>
                        <h3 className='font-bold text-[1.3rem] mt-2'>My Automation</h3>
                        <NavLink
                            to="/AutomationDetail"
                            activeClassName="active-automation"
                        >
                            <button className='createBtn-automation'>Create</button>
                        </NavLink>
                    </div>
                    <div className='table_div-automation'>
                        <table className='table_wa-automation'>
                            <thead className='table_head-automation'>
                                <tr>
                                    <th className='table_td_th-automation'>Automation Type</th>
                                    <th className='table_td_th-automation'>Automation Name</th>
                                    <th className='table_td_th-automation'>Status</th>
                                    <th className='table_td_th-automation'>Trigger</th>
                                    <th className='table_td_th-automation'>Action</th>
                                </tr>
                            </thead>
                            <tbody className='table_body-automation'>
                                {automationDetail && automationDetail.map((data, index) => (
                                    <tr key={index}>
                                        <td className='table_td_th-automation'>Keyword Based Message</td>
                                        <td className='table_td_th-automation'>{data.automationName}</td>
                                        <td className='table_td_th-automation'>{data.status ? "Active" : "De-Active"}</td>
                                        <td className='table_td_th-automation'>{data.keywords}</td>
                                        <td className='table_td_th-automation'>
                                            <div className='spaceEvenly-automation'>
                                                <Icon className='iconStyle-automation'
                                                    icon="mdi:pencil"
                                                    width="20"
                                                    height="20"
                                                    style={{ color: "black" }}
                                                    onClick={() => editAutomation(data.id)}
                                                />
                                                <Icon className='iconStyle-automation'
                                                    icon="mdi:trash-can"
                                                    width="20"
                                                    height="20"
                                                    style={{ color: "black" }}
                                                    onClick={() => deleteAutomation(data.id)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatsappAutomationAndChatBot