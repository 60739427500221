import { ScreenTemplate } from "./Template";

export const JSONCondition = (nodes, rawTemplate) => {
  console.log("JSONnodes", nodes);
  const ScreenNodes = nodes.filter(
    (element) => element.type === "screenNameNode"
  );
  console.log("hello");

  const ChildNodes = nodes.filter(
    (element) => element.type !== "screenNameNode"
  );

  const initailTemp = JSON.parse(ScreenTemplate);
  // ScreenNodes?.map((screens, index) => {
  //   ChildNodes.map((child) => {
  //     if (child?.parents?.length > 0) {
  //       const gg = screens.id === child?.parents[0];

  //       console.log("gg", gg);
  //     }
  //   });
  // });
  const processdata = (elm) => {
    // nodes.map((elm) => {
    if (!elm.elementType) return;
    console.log(elm);

    if (elm.elementType === "input") {
      return {
        type: "TextInput",
        required: true,
        label: elm.data.label,
        name: elm.data.label,
      };
    }

    if (elm.elementType === "checkbox") {
      return {
        type: "CheckboxGroup",
        label: elm.title,
        name: elm.title,
        "data-source": elm.element.map((e) => {
          return {
            id: e.checkboxName,
            title: e.checkboxName,
          };
        }),
      };
    }

    if (elm.elementType === "radio") {
      return {
        type: "RadioButtonsGroup",
        label: elm.title,
        name: elm.title,
        "data-source": elm.element.map((e) => {
          return {
            id: e.radioName,
            title: e.radioName,
          };
        }),
      };
    }

    if (elm.elementType === "dropdown") {
      return {
        type: "Dropdown",
        label: elm.title,
        name: elm.title,
        "data-source": elm.element.map((e) => {
          return {
            id: e.dropDownName,
            title: e.dropDownName,
          };
        }),
      };
    }

    if (elm.elementType === "option") {
      return {
        type: "OptIn",
        label: elm.element,
        name: elm.element,
      };
    }

    if (elm.elementType === "date") {
      return {
        type: "DatePicker",
        label: elm.element,
        name: elm.element,
      };
    }

    if (elm.elementType === "textarea") {
      return {
        type: "TextArea",
        required: true,
        label: elm.element,
        name: elm.element,
      };
    }

    if (elm.elementType === "textheading") {
      return {
        type: "TextHeading",
        text: elm.element,
      };
    }
    if (elm.elementType === "textsubheading") {
      return {
        type: "TextSubheading",
        text: elm.element,
      };
    }
    if (elm.elementType === "textbody") {
      return {
        type: "TextBody",
        text: elm.element,
      };
    }
    if (elm.elementType === "textcaption") {
      return {
        type: "TextCaption",
        text: elm.element,
      };
    }
    // });
  };
  function indexToLetter(index) {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const letterIndex = parseInt(index.toString().slice(-6)) % 26;
    return letters[letterIndex];
  }

  if (ScreenNodes && ScreenNodes.length > 1) {
    const routingModel = {};
    const nodeMap = new Map(ScreenNodes.map((node) => [node.id, node]));
    debugger;
    ScreenNodes.forEach((screen, index) => {
      if (screen.parents && screen.parents.length === 1) {
        const targetNode = screen.id;
        const SourceNode = nodeMap.get(screen.parents[0]);
        // const SourceNode = ScreenNodes.find((el) => el.id == screen.parents[0]);

        routingModel[`Screen_${indexToLetter(SourceNode.id)}`] = [
          `Screen_${indexToLetter(targetNode)}`,
        ];
      } else {
        console.log("This screen is a parent of another screen");
      }
    });

    rawTemplate.routing_model = routingModel;
  }

  ScreenNodes.forEach((screen, index) => {
    debugger;
    const children = ChildNodes.filter((child) =>
      child.parents.includes(screen.id)
    );
    console.log("children", children);
    const isLastScreen = index === ScreenNodes.length - 1;

    const footer = isLastScreen
      ? {
          type: "Footer",
          label: "Complete",
          "on-click-action": {
            name: "complete",
            payload: {},
          },
        }
      : {
          type: "Footer",
          label: "Next",
          "on-click-action": {
            name: "navigate",
            next: {
              type: "screen",
              name: `Screen_${indexToLetter(ScreenNodes[index + 1]?.id)}`,
            },
            payload: {},
          },
        };

    const screenObject = {
      id: `Screen_${indexToLetter(screen.id)}`,
      title: screen.data.label,
      data: {},
      layout: {
        type: "SingleColumnLayout",
        children: [...children.map((child) => processdata(child)), footer],
      },
    };

    if (isLastScreen) {
      screenObject.terminal = true;
      screenObject.success = true;
    }

    // {
    //   id: `Screen_${indexToLetter(screen.id)}`,
    //   title: screen.data.label,
    //   terminal: true,
    //   success: true,
    //   data: {},
    //   layout: {
    //     type: "SingleColumnLayout",
    //     children: [...children.map((child) => processdata(child)), footer],
    //   },
    // }

    rawTemplate.screens.push(screenObject);
  });

  return rawTemplate;
};
