import React, { useEffect, useState, useRef, useContext } from "react";
import Sidebar from "../Sidebar";
// import "./newsandnotice.css";
import "../../css/style.css";
import "../../css/whatsapp.css";
import "../../css/mobile.css"
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";
import Backend_Url from "../../config/config";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import Profile from "../../Assets/Images/men_pic.webp";
import ModalAttachMenu from "./ModalAttachMenu";
import ModalHeaderSideBar from "./ModalHeaderSideBar";
import ModalRepliedChat from "./ModalRepliedChat";
import WhatsappBtnMenu from "./whatsappBtnMenu";
import ModalChatHeader from "./ModalChatHeader";
import MyContext from "../../context/Mycontext";
import Loader from "../Loader/Loader";
import WTPSideBar from "../WhatsappCampaigns/WTPSideBar";
import MetaTemplates from "../WhatsappCampaigns/MetaTemplates";
import WTPContacts from "../WhatsappContacts/WTPContacts";
import CircleLoader from "../Loader/CircleLoader";


const WhatsappWeb = ({ mobileslider, setmobileslider, openclosenav }) => {
    //**************************************************** */
    const [slicecount, setslicecount] = useState(0)
    const [selectedContact, setSelectedContact] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sidebarHidden, setSidebarHidden] = useState(false);
    const [instagramData, setInstagramData] = useState({});
    // const chatbody = useRef(null);
    // const [apiData, setApiData] = useState()
    const [filteredContacts, setFilteredContacts] = useState([]);
    // const [messageData, setMessageData] = useState()
    // const [lstmessageData, setLstMessageData] = useState()
    const [cookies] = useCookies(['token']);
    const token = cookies.token;
    const [filteredData, setFilteredData] = useState([]);
    // const [apiset, setApiSet] = useState()
    const [message, setMessage] = useState('');
    const [responseData, setResponseData] = useState('');
    const [page, setPage] = useState(1);
    // const [hasMore, setHasMore] = useState(true);
    // const [ws, setWs] = useState(null);
    const [apiData, setApiData] = useState({ contacts: [], totalPages: 1, currentPage: 1 });
    const [loading, setLoading] = useState(false);
    // const [hasMorec, setHasMorec] = useState(true);
    const navigate = useNavigate();
    const [imageUrls, setImageUrls] = useState({});
    const [loadingImageIds, setLoadingImageIds] = useState(new Set());

    const [selectedTab, setSelectedTab] = useState('All')

    const [activeTab, setActiveTab] = useState('chats');
    const [fetchMoreContactLoading, setFetchMoreContactLoading] = useState(true);


    const {
        fetchContactMessages,
        hasMore,
        lstmessageData,
        messageData,
        setMessageData,
        setHasMore,
        fetchlastmessage,
        companyName,
        apiset,
        setintervelforprogree,
        completed
    } = useContext(MyContext)


    // const [slicecount, setSlicecount] = useState(0);
    const containerRef = useRef(null);
    useEffect(() => {
        console.log("lstmessageData", lstmessageData);
    }, [lstmessageData])

    // useEffect(() => {
    //     console.log("messageData", messageData);
    // }, [messageData]);

    const [scrollonce, setscrollonce] = useState(false);
    const [ScrollStopCheck, setScrollStopCheck] = useState(false)
    useEffect(() => {
        const container = containerRef.current;

        const HandleScroll = (event) => {
            console.log(event)
            console.log(event.target.clientHeight)
            console.log(event.target.scrollTop)

            if (event.target.scrollTop > 3000) {
                setScrollStopCheck(true)
                setslicecount((prevCount) => prevCount + 50);
            }
        };

        if (container && !ScrollStopCheck) {
            container.addEventListener('scroll', HandleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', HandleScroll);
            }
        };
    }, []);

    const loadMoreMessages = (contact) => {
        debugger
        console.log("loadmore", contact)
        if (!hasMore) return;
        fetchContactMessages(contact.wa_id, page + 1)?.then((res) => {
            setPage(prevPage => prevPage + 1);
            console.log('data', res)
        });

        // fetchMsgData(contact.wa_id, page + 1).then(() => {
        //     setPage(prevPage => prevPage + 1);
        // });
    };


    useEffect(() => {
        filteredContacts.forEach((contact) => {
            if (contact.platform === 'instagram' && contact.wa_id && !instagramData[contact.wa_id]) {
                getInstagramUser(contact.wa_id);
            }
        });
    }, [filteredContacts, instagramData]);

    const getInstagramUser = async (id) => {

        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/getInstagramUser/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setInstagramData((prevData) => ({
                ...prevData,
                [id]: response.data,
            }));
            console.log("response", response);
        } catch (error) {
            console.error(error.message);
        }
    }




    const openModal = (contact) => {
        debugger


        // if (chatbody.current.scrollHeight > 100) {
        //     chatbody.current.scrollTop = 10
        // }
        // chatbody.current.scrollTop = chatbody.current.scrollHeight;
        setSelectedContact(contact);
        setMessageData({ messages: [] });
        // fetchMsgData(contact.wa_id, 1)
        fetchContactMessages(contact.wa_id, 1)
        setIsModalOpen(true);
        setSidebarHidden(true);
        readMessage(contact.wa_id)
        let id = document.querySelector("#chat_body")
        console.log(id);

    };


    const handleScroll = (event) => {
        debugger
        const element = event.nativeEvent.target;
        console.log(element.scrollTop)
        setHasScrolled(false) //-----------------------CHANGE FOR CHAT NOT BUFFER---------------
        if (element.scrollTop > 50) {

            loadMoreMessages(selectedContact);
        }
    };

    useEffect(() => {
        console.log("selectedContact", selectedContact);
    }, [selectedContact]);
    useEffect(() => {
        console.log("Message Data", messageData);
    }, [messageData]);
    useEffect(() => {
        console.log("Api Data", apiData);
    }, [apiData]);
    useEffect(() => {
        console.log("Filtererd Data", filteredData);
    }, [filteredData]);

    const readMessage = async (wa_id) => {
        // debugger
        try {
            const response = await axios.patch(`${Backend_Url}/whatsapp/v1/messages/mark-all-read?contactWaId=${wa_id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response.status === 200) {
                fetchlastmessage();
                // contactProcess()
                console.log(response.data)
            }
        } catch (error) {
            console.error(error)
            throw error
        }
    }


    // const fetchMsgData = async (wa_id, page) => {
    //     // debugger
    //     const token = cookies.token;
    //     if (!token) {
    //         console.error('No token found');
    //         return;
    //     }
    //     try {
    //         const response = await axios.get(`${Backend_Url}/whatsapp/v1/contacts/${wa_id}/getmessages/all?page=${page}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         // if (response.data.messages.length > 0) {
    //         //     setMessageData(response.data)
    //         //     console.log(response);
    //         // }
    //         if (response.data.messages.length > 0) {


    //             setMessageData(prevData => {
    //                 const existingMessages = prevData?.messages || [];
    //                 const existingMessageIds = new Set(existingMessages.map(msg => msg.id));
    //                 const newMessages = response.data.messages.filter(msg => !existingMessageIds.has(msg.id));

    //                 return {
    //                     ...prevData,
    //                     messages: [...existingMessages, ...newMessages].sort((a, b) => a.timestamp - b.timestamp),
    //                 };
    //             });
    //             console.log(response);
    //         } else {
    //             setHasMore(false);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };


    // useEffect(() => {
    //     const fetchApiSetting = async () => {

    //         try {
    //             const response = await axios.get(`${Backend_Url}/whatsapp/v1/whatsapp-api`, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`
    //                 }
    //             });
    //             if (response) {
    //                 console.log(response);
    //                 const defaultApi = response.data.find(item => item.IsDefault);
    //                 setApiSet(defaultApi)
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };
    //     fetchApiSetting();
    // }, []);


    const timestampToTime = (timestamp) => {

        if (timestamp.toString().length === 13) {

            timestamp = timestamp / 1000;
        }

        const date = new Date(timestamp * 1000);


        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();


        let hours = date.getHours();
        const minutes = date.getMinutes();


        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;


        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;


        return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes} ${ampm}`;
    };


    const closeModal = () => {
        setSelectedContact(null);
        setIsModalOpen(false);
        setSidebarHidden(false);
    };


    //get all contacts
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const token = cookies.token;
    //         if (!token) {
    //             console.error('No token found');
    //             return;
    //         }

    //         try {
    //             const response = await axios.get(`${Backend_Url}/whatsapp/v1/contacts/getall`, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });

    //             if (response.status === 200) {
    //                 console.log(response);
    //                 setApiData(response.data)
    //             } else {
    //                 console.error('Failed to fetch contacts', response.data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching contacts:', error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    useEffect(() => {
        fetchData(1);
    }, []);


    const fetchData = async (page = 1, limit) => {
        if (loading) return;
        // debugger
        setFetchMoreContactLoading(true);
        const token = cookies.token;
        if (!token) {
            console.error('No token found');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${Backend_Url}/contacts/getallwithPage2?page=${page}&limit=50`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('API Response:', response.data);
            if (response.status === 200) {
                const newContacts = response.data.contacts;
                setApiData(prevData => {

                    const contactMap = new Map(prevData.contacts.map(contact => [contact.wa_id, contact]));
                    newContacts.forEach(contact => contactMap.set(contact.wa_id, contact));

                    return {
                        contacts: Array.from(contactMap.values()),
                        totalPages: response.data.totalPages,
                        currentPage: page
                    };
                });
                setHasMore(page < response.data.totalPages);
                setpagecount((prev) => prev + 1)
            } else {
                console.error('Failed to fetch contacts', response.data);
            }

            if(response.data.contacts.length <= 0) {
                setFetchMoreContactLoading(false); 
            }
        } catch (error) {
            console.error('Error fetching contacts:', error);
        } finally {
            setLoading(false);
        }
    };


    const [searchQuery, setSearchQuery] = useState('');

    const contactProcess = () => {
        debugger
        setLoading(true);
        const MAX_MESSAGE_LENGTH = 25;
        const getLastMessages = () => {
            const messagesByContact = {};

            if (lstmessageData) {
                lstmessageData.forEach(message => {
                    const { contactWaId, createdOn, text } = message;
                    if (!messagesByContact[contactWaId] || new Date(messagesByContact[contactWaId].createdOn) < new Date(createdOn)) {
                        messagesByContact[contactWaId] = message;
                    }
                });

                return messagesByContact;
            }
            return {};
        }

        const lastMessages = getLastMessages();
        if (apiData.contacts) {
            const enrichedContacts = apiData.contacts.map(contact => {
                const lastMessage = lastMessages[contact.wa_id];
                let lastMessageText = 'No messages';
                let lastMessageTime = '';
                let unreadCount = 0;

                if (lastMessage) {
                    const parsedText = JSON.parse(lastMessage.text || '{}');
                    const messageBody = parsedText.body || '';

                    if (lastMessage.type === 'image') {
                        lastMessageText = lastMessage.mediaId ? 'Image' : 'Image';
                    } else if (lastMessage.type === 'video') {
                        lastMessageText = parsedText.video ? `Video: ${parsedText.video.url}` : 'Video';
                    } else if (lastMessage.type === 'audio') {
                        lastMessageText = parsedText.audio ? `Audio: ${parsedText.audio.url}` : 'Audio';
                    } else {
                        lastMessageText = messageBody || 'No content';
                    }
                    if (lastMessageText.length > MAX_MESSAGE_LENGTH) {
                        lastMessageText = lastMessageText.substring(0, MAX_MESSAGE_LENGTH) + '...';
                    }
                    const createdDate = new Date(lastMessage.createdOn);
                    lastMessageTime = `${createdDate.toLocaleDateString()}\n${createdDate.toLocaleTimeString()}`;
                    // lastMessageTime = new Date(lastMessage.createdOn).toLocaleTimeString();
                    unreadCount = lastMessage.unreadCount;
                }

                return {
                    ...contact,
                    lastMessage: lastMessageText,
                    lastMessageTime: lastMessageTime,
                    unreadCount: unreadCount
                };
            });

            const enrichedContactsMap = new Map(enrichedContacts.map(contact => [contact.wa_id, contact]));

            const updatedContacts = filteredContacts.map(contact => {
                if (enrichedContactsMap.has(contact.wa_id)) {
                    return enrichedContactsMap.get(contact.wa_id);
                }
                return contact;
            });

            const allContacts = [
                ...updatedContacts,
                ...enrichedContacts.filter(contact => !filteredContacts.some(c => c.wa_id === contact.wa_id))
            ];

            const sortedContacts = allContacts.sort((a, b) => {
                if (a.unreadCount > 0 && b.unreadCount === 0) {
                    return -1;
                }
                if (a.unreadCount === 0 && b.unreadCount > 0) {
                    return 1;
                }
                return new Date(b.lastMessageTime) - new Date(a.lastMessageTime);
            });

            // const searchedContacts = sortedContacts.filter(contact => {
            //     const searchLower = searchQuery.toLowerCase();
            //     const contactName = contact.Profile?.toLowerCase() || '';
            //     const contactWaId = contact.wa_id || '';
            //     return contactName.includes(searchLower) || contactWaId.includes(searchLower);
            // });
            setFilteredContacts((prev) => sortedContacts);

        }
        setLoading(false);
    }

    useEffect(() => {
        // debugger
        contactProcess()
    }, [apiData, lstmessageData]);


    const replytoComment = async (data) => {
        try {
            const response = await axios.post(`${Backend_Url}/whatsapp/commentreply/${data.from}/${data.id}?message=${message}&replyto=${data.text.body}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            if (response.status === 200) {
                setMessage('');
            }

        } catch (error) {
            console.error(error);
        }
    }

    const DirectMessage = async (Recipient_Id) => {
        try {
            debugger
            const response = await axios.post(`${Backend_Url}/whatsapp/Dmreply/${Recipient_Id}?message=${message}`, {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
            if (response.status === 200) {
                setMessage('');
            }
        } catch (error) {
            console.error(error);
        }
    }




    const handleSendClickInsta = async () => {
        if (selectedMessageId.mimeType === 'comment') {
            await replytoComment(selectedMessageId)
        } else {
            await DirectMessage(selectedMessageId.from)
        }
    }



    const fetchfilteredData = async () => {
        debugger
        try {

            function isNumber(query) {
                // Check if the query contains only digits
                const check = /^\d+$/.test(query);
                if (check) {
                    return "wa_id"
                } else {
                    return "Profile"
                }
            }

            const filterdata = await axios.post(`${Backend_Url}/contacts/filterContacts`, {
                filter: [
                    {
                        "fieldName": isNumber(searchQuery),
                        "operatorName": "like",
                        "compareValue": searchQuery
                    }

                ]
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            const result = await filterdata.data.contacts
            console.log(result);
            if (result) {
                setFilteredContacts(result)
            }

        } catch (error) {
            console.log(error);
        }
    }

    function handleSearch(e) {
        console.log(e);
        if (e.key === 'Enter') {
            if (searchQuery)
                fetchfilteredData();
        }
    }

    useEffect(() => {
        if (searchQuery.length == 0) {
            // contactProcess()
            fetchData()
            contactProcess()
        }
    }, [searchQuery])

    // useEffect(() => {
    //     fetchfilteredData()
    // }, [searchQuery])

    // const [Showcontact, setShowcontact] = useState([])
    // const [slicevalue, setslicevalue] = useState(0)
    // useEffect(() => {
    //     if (filteredContacts) {
    //         setShowcontact(filteredContacts.slice(0, slicevalue))
    //     }
    //     if (slicevalue < 50) {
    //         setShowcontact(filteredContacts.slice(0, slicevalue + 50))
    //     }
    // }, [filteredContacts, slicevalue])


    useEffect(() => {
        // const intervalId = setInterval(() => {
        if (selectedContact != null) {
            fetchContactMessages(selectedContact.wa_id, page)
        }
        // }, 1500);
        // return () => clearInterval(intervalId);
    }, [selectedContact, lstmessageData]);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         fetchlastmessage()
    //     }, 1500);
    //     return () => clearInterval(intervalId);
    // }, []);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {

    //         if (selectedContact != null) {
    //             fetchMsgData(selectedContact.wa_id, page);
    //         }
    //     }, 10000);

    //     return () => clearInterval(intervalId);
    // }, []);


    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    /*********************************************************** */

    const [isMenuOpenHeader, setIsMenuOpenHeader] = useState(false);
    const menuRef1 = useRef(null);

    const toggleMenuHeader = () => {
        setIsMenuOpenHeader(!isMenuOpenHeader);
    };

    const handleClickOutsideHeader = (event) => {
        if (menuRef1.current && !menuRef1.current.contains(event.target)) {
            setIsMenuOpenHeader(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideHeader);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideHeader);
        };
    }, []);


    /******************************************************** */

    const [isModalOpenReply, setIsModalOpenReply] = useState(false);
    const [isMenuOpenReply, setIsMenuOpenReply] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const menuRef3 = useRef(null);

    const toggleMenuReply = (messageId) => {
        debugger
        // alert("selected", messageId)
        setSelectedMessageId(messageId);
        setIsMenuOpenReply(!isMenuOpen);
    };

    const handleClickOutsideReply = (event) => {
        if (menuRef3.current && !menuRef3.current.contains(event.target)) {
            setIsMenuOpenReply(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideReply);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideReply);
        };
    }, []);

    const getTickMark = (status) => {
        if (status === 'read') return '✓✓'; // Double tick
        if (status === 'unread') return '✓'; // Single tick
        return '';
    };


    const handleBackClick = () => {
        setIsModalOpen(false);
        setSidebarHidden(false);
    };

    /********************************************* */

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 750);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    /********************************************************** */

    const [isMenuOpenChatHeader, setIsMenuOpenChatHeader] = useState(false);
    const menuRef4 = useRef(null);

    const toggleMenuChatHeader = () => {
        setIsMenuOpenChatHeader(!isMenuOpenChatHeader);
    };

    const handleClickOutsideChatHeader = (event) => {
        if (menuRef4.current && !menuRef4.current.contains(event.target)) {
            setIsMenuOpenChatHeader(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideChatHeader);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideChatHeader);
        };
    }, []);

    /*************************************************** */



    const handleInputChange = (event) => {
        setMessage(event.target.value);
    };

    console.log("Response Data", responseData);

    ////To ge the last messageID
    useEffect(() => {
        const fetchResponseData = async () => {
            try {
                const response = await axios.get(`${Backend_Url}/whatsapp/v1/messageresponse`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response) {
                    console.log(response);
                    setResponseData(response.data)

                    const getLastEntries = (entries) => {
                        const result = entries.reduce((acc, current) => {
                            const existingEntry = acc[current.wa_id];
                            if (!existingEntry || new Date(existingEntry.createdOn) < new Date(current.createdOn)) {
                                acc[current.wa_id] = current;
                            }
                            return acc;
                        }, {});

                        return Object.values(result);
                    };

                    setFilteredData(getLastEntries(response.data));
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchResponseData();
    }, []);

    useEffect(() => {
        // Your code here
        console.log("Filter Contact", filteredContacts)
    }, [filteredContacts]);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setTimeout(() => {
                handleSendClick();
            }, 200);
        }
    };

    // useEffect(() => {
    //     // Add event listener to the document for keydown
    //     document.addEventListener("keydown", handleKeyDown);

    //     // Cleanup the event listener on component unmount
    //     return () => {
    //         document.removeEventListener("keydown", handleKeyDown);
    //     };
    // }, []);


    const handleSendClick = async () => {
        debugger
        if (!message.trim()) {
            alert("Message cannot be empty");
            return;
        }
        setMessage('');
        // // debugger
        const apiUrl = apiset.URL;

        const msgId = filteredData.filter(item => item.wa_id === selectedContact.wa_id)
        console.log(msgId);
        const payload = {
            messaging_product: 'whatsapp',
            recipient_type: 'individual',
            to: selectedContact.wa_id,
            type: 'text',
            text: {
                body: message
            }
        };

        // const payload = {
        //     messaging_product: "whatsapp",
        //     context: {
        //         message_id: msgId.length > 0 ? msgId[0].message_id : ""
        //     },
        //     to: selectedContact.wa_id,
        //     type: "text",
        //     text: {
        //         preview_url: false,
        //         body: message
        //     }
        // }

        const Token = apiset.RequestHeaderValueField1

        const headers = {
            'Authorization': `${Token}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.post(apiUrl, payload, { headers });
            if (response.status === 200) {
                // alert('Message sent successfully!');
                const savepayload = {
                    input: response.data.contacts[0].input,
                    wa_id: response.data.contacts[0].wa_id,
                    message_id: response.data.messages[0].id,
                    text: {
                        body: message
                    }
                }
                const token = cookies.token;
                const saveResponse = await axios.post(`${Backend_Url}/whatsapp/v1/messageresponse/save`, savepayload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                console.log(saveResponse)
                if (saveResponse) {
                    // fetchMsgData(selectedContact.wa_id, page)
                }
            }
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error sending message:', error.response ? error.response.data : error.message);
            alert('Failed to send message.');
        }
    };




    const ImageWithAuth = ({ imageUrl, token }) => {
        const [imgSrc, setImgSrc] = useState('');
        console.log("Image Source", imgSrc);
        useEffect(() => {
            const fetchImage = async () => {
                // // debugger
                try {

                    const response = await axios.post(`${Backend_Url}/helper/proxy-image`, { imageUrl, token }, {
                        responseType: 'arraybuffer'
                    });

                    if (response.status === 200) {
                        const blob = new Blob([response.data], { type: 'image/jpeg' });
                        const imgURL = URL.createObjectURL(blob);
                        setImgSrc(imgURL);
                    } else {
                        console.error('Failed to fetch image:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error fetching image:', error);
                }
            };

            fetchImage();
        }, []);

        return (
            <div>
                {imgSrc ? (
                    <img src={imgSrc} alt="Authenticated Content" width={"250px"} height={"300px"} />
                ) : (
                    <p>Loading image...</p>
                )}
            </div>
        );
    };



    const fetchImageUrl = async (mediaId) => {
        if (!mediaId) return;

        setLoadingImageIds((prev) => new Set(prev).add(mediaId)); // Add mediaId to loading state

        try {
            const url = `${apiset.Url}/${apiset.Version}`;
            const response = await axios.get(`${url}/${mediaId}`, {
                headers: {
                    Authorization: apiset.RequestHeaderValueField1,
                },
            });

            if (response && response.data && response.data.url) {
                setImageUrls((prev) => ({ ...prev, [mediaId]: response.data.url }));
            }
        } catch (error) {
            console.error('Error fetching image URL:', error);
        } finally {
            setLoadingImageIds((prev) => {
                const newSet = new Set(prev);
                newSet.delete(mediaId);
                return newSet;
            });
        }
    };

    useEffect(() => {
        messageData && messageData.messages.forEach((message) => {
            if (message.mediaId && !imageUrls[message.mediaId]) {
                fetchImageUrl(message.mediaId);
            }
        });
    }, [messageData]);

    const endOfMessagesRef = useRef(null);

    const [hasScrolled, setHasScrolled] = useState(true);
    useEffect((e) => {
        // // debugger
        // console.log(messageData)
        if (endOfMessagesRef.current) {
            if (hasScrolled) {
                endOfMessagesRef.current.scrollIntoView({ behavior: 'auto' });

            }
        }
    }, [messageData]);



    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                navigate(-1);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [navigate]);

    const getInitials = (profile) => {

        if (!profile) return '';

        const names = profile?.split(' ');
        const firstInitial = names[0] ? names[0][0] : '';
        const lastInitial = names[1] ? names[1][0] : '';

        return `${firstInitial}${lastInitial}`.toUpperCase();
    };


    const [pagecount, setpagecount] = useState(1)
    const HandleScroll = (e) => {
        try {
            const target = e.target;
            const scrollPosition = target.scrollTop;
            const scrollHeight = target.scrollHeight;
            const clientHeight = target.clientHeight;

            // Check if we're within 100 pixels of the bottom
            if (scrollPosition + clientHeight >= scrollHeight - 500) {
                fetchData(pagecount);
            }
        } catch (error) {
            console.error("Error in HandleScroll:", error);
        }
    }


    const location = useLocation();



        useEffect(() => {
          const handleBackButton = (event) => {
            // This will be triggered when the back button is pressed
           setIsModalOpen(false);

           event.preventDefault();

           alert('back button pressed')




            // if(location.pathname !== '/whatsappweb'){
            //     // window.history.back();
            // }
      
            // Perform additional logic here if needed
          };
      
          // Add event listener when the component mounts
          window.addEventListener("popstate", handleBackButton);
      
          // Clean up the event listener when the component unmountss

        }, []);


    


    return (
        <>
            <div className="Flex-Head-Right-Compaigns sm:h-fit">
                <div>
                    <WTPSideBar mobileslider={mobileslider} setmobileslider={setmobileslider} isWhatsappWebPannel={true} />
                </div>
            </div>
            <div className="app">
                <div className={`app__body ${selectedTab === 'chats' && '!flex'}`}>
                    {activeTab === 'chats' ?
                        <div className="Flex-Whatsapp-Body">

                            <div className={`sidebar-wtp ${isModalOpen ? '!h-[100vh]' : '!h-[92.5vh]'} ${sidebarHidden ? 'hidden' : ''}`}>
                                <div className="sidebar__header">
                                    {isMobile ? (
                                        <>
                                            <div className="sidebar__headerLeft">
                                                <span className="sidebar__headerName">Inbox</span>
                                            </div>
                                            <div className="Flex-right-Icons">
                                                <div className="sidebar__headerRight">
                                                    {/* <button className="sidebar__headerButton">
                                                        <Icon className="Icon-Size-wtp" icon="mdi:qrcode-scan" />
                                                    </button> */}

                                                </div>
                                                <div className="sidebar__headerLeft">
                                                    {/* <button className="sidebar__headerButton">
                                                        <Icon className="Icon-Size-wtp" icon="mdi:camera-outline" />
                                                    </button> */}
                                                </div>
                                                <div className="sidebar__headerRight !ml-2">
                                                    {/* <button className="sidebar__headerButton Icon-header-menu" onClick={toggleMenuHeader}>
                                                        <Icon className="Icon-Size-wtp" icon="bi:three-dots-vertical" />
                                                        {isMenuOpenHeader && (
                                                            <div className="modal-header" ref={menuRef1}>
                                                                <ModalHeaderSideBar />
                                                            </div>
                                                        )}
                                                    </button> */}

                                                    <button onClick={() => setmobileslider(true)}>
                                                        <Icon icon="fe:bar" style={{ color: "black", width: '30px', height: 'auto' }} />

                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {/* <img src={Profile} alt="Profile" className="sidebar__headerProfileImage" /> */}
                                            <div className="sidebar__headerProfileImage" style={{
                                                background: 'gray',
                                                color: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '40px',
                                                fontSize: '18px',
                                            }}>
                                                {companyName && getInitials(companyName)}
                                            </div>
                                            <div className="sidebar__headerRight">

                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="sidebar__search">
                                    <div className="Icon-search-wtp"><Icon icon="material-symbols:search" /></div>
                                    <input className="Input-Box-whatsapp" placeholder="Search or start new chat" type="text"
                                        value={searchQuery}
                                        onKeyDown={(e) => handleSearch(e)}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                <div className="flex justify-start items-center gap-4 px-4 py-1 pb-2  mt-2 bg-white">
                                    <label onClick={() => setSelectedTab('All')} htmlFor="" className={`text-[.8rem] px-2 py-1 cursor-pointer ${selectedTab === 'All' ? 'bg-green-300' : 'bg-[#f6f6f6]'} !rounded-[8px]`}>All</label>
                                    <label onClick={() => setSelectedTab('Unread')} htmlFor="" className={`text-[.8rem] px-2 py-1 cursor-pointer ${selectedTab === 'Unread' ? 'bg-green-300' : 'bg-[#f6f6f6]'} !rounded-[8px]`}>Unread</label>
                                    <label onClick={() => setSelectedTab('Favorites')} htmlFor="" className={`text-[.8rem] px-2 py-1 cursor-pointer ${selectedTab === 'Favorites' ? 'bg-green-300' : 'bg-[#f6f6f6]'} !rounded-[8px]`}>Favorites</label>
                                    <label onClick={() => setSelectedTab('Groups')} htmlFor="" className={`text-[.8rem] px-2 py-1 cursor-pointer ${selectedTab === 'Groups' ? 'bg-green-300' : 'bg-[#f6f6f6]'} !rounded-[8px]`}>Groups</label>
                                </div>
                                {loading ? (<Loader />) : (
                                    <div className="chat">
                                        <div className={`sidebar__chats `} onScroll={HandleScroll} ref={containerRef} style={{ overflow: 'auto', height: '500px' }}>

                                            {filteredContacts && filteredContacts.map(contact => (
                                                <div
                                                    key={contact.wa_id}
                                                    className="sidebar__chat"
                                                    onClick={() => { openModal(contact); setHasScrolled(true) }}
                                                >

                                                    {contact.platform === 'whatsapp' && (
                                                        <div
                                                            className="sidebar__headerProfileImage"
                                                            style={{
                                                                position: 'relative',
                                                                background: 'gray',
                                                                color: 'white',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: '50%',
                                                                width: '40px',
                                                                height: '40px',
                                                                fontSize: '18px',
                                                            }}
                                                        >
                                                            {contact.Profile !== ' ' ? getInitials(contact.Profile) : getInitials('Wa User')}
                                                            <div
                                                                className="whatsappIcon"
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '-3px', // Positioned below the profile circle
                                                                    right: '-8px',  // Positioned to the right of the profile circle

                                                                    borderRadius: '50%', // Round icon
                                                                    padding: '2px', // Spacing around the icon

                                                                }}
                                                            >
                                                                <Icon icon="logos:whatsapp-icon" style={{ fontSize: '16px' }} />
                                                            </div>
                                                        </div>
                                                    )}

                                                    {contact.platform === 'instagram' && (
                                                        <div
                                                            className="sidebar__headerProfileImage"
                                                            style={{
                                                                position: 'relative',
                                                                background: 'gray',
                                                                color: 'white',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: '50%',
                                                                width: '40px',
                                                                height: '40px',
                                                                fontSize: '18px',
                                                            }}
                                                        >
                                                            {instagramData[contact.wa_id] ? (
                                                                <img
                                                                    src={instagramData[contact.wa_id].profile_pic}
                                                                    alt={contact.Profile || 'Instagram User'}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        borderRadius: '50%',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                />
                                                            ) : (
                                                                getInitials("Insta Gram")
                                                            )}
                                                            <div
                                                                className="whatsappIcon"
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '-5px',
                                                                    right: '-8px',
                                                                    borderRadius: '50%',
                                                                    padding: '2px',

                                                                }}
                                                            >

                                                                <Icon icon="skill-icons:instagram" style={{ fontSize: '16px' }} />
                                                            </div>
                                                        </div>
                                                    )}




                                                    <div className="Msg-width-wtp">
                                                        <div style={{ display: "flex" }}>
                                                            {contact.platform === 'whatsapp' && <div className="chat-person-name">{contact.Profile !== ' ' ? contact.Profile : 'Wa User'}</div>}
                                                            {contact.platform === 'instagram' && instagramData[contact.wa_id] && <div className="chat-person-name">{instagramData[contact.wa_id].name}</div>}
                                                            {contact.platform === 'whatsapp' && <div className="chat-contact">{`(${contact.wa_id})`}</div>}
                                                            {contact.platform === 'instagram' && instagramData[contact.wa_id] && <div className="chat-contact">{`(${instagramData[contact.wa_id].username})`}</div>}
                                                        </div>
                                                        <div className="chat-person-last-message">{contact.lastMessage}</div>
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <div className="Chat_Time_Wtp">{contact.lastMessageTime}</div>
                                                            {contact.platform === 'whatsapp' && <div className="chat-contact">{`(${contact.wa_id})`}</div>}
                                                            {contact.platform === 'instagram' && instagramData[contact.wa_id] && <div className="chat-contact">{instagramData[contact.wa_id].username}</div>}
                                                        </div>
                                                        {contact.unreadCount > 0 && <div className="unread-count">{contact.unreadCount}</div>}
                                                    </div>
                                                </div>
                                            ))}

                                            {fetchMoreContactLoading && <div className="flex justify-center items-start w-full p-4">
                                                <CircleLoader size={40} color={'green'} />
                                            </div>}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className={`chat-container ${isModalOpen ? 'chat-container--modal-open' : ''} ${sidebarHidden ? 'show' : ''}`}>

                                {isModalOpen && (
                                    <div className="chat">
                                        <div className="chat__header sm:!px-2">
                                            <button className="chat__headerBackButton" onClick={handleBackClick}>
                                                <Icon className="Icon-Size-wtp" icon="mdi:arrow-left" />
                                            </button>
                                            <img src={Profile} alt="Contact" className="chat__headerImage ml-1" />
                                            <div className="chat__headerInfo">
                                                <h3 className='font-bold text-[1.3rem] mt-2'>{(selectedContact.Profile != ' ' || '') ? selectedContact.Profile : selectedContact.wa_id}</h3>
                                                {/*<p className="">Last seen today at 06:30 PM</p>*/}
                                            </div>
                                            <div className="chat__headerRight">
                                                {/* <button className="chat__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="fluent:call-16-regular" />
                                        </button>
                                        <button className="chat__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="icons8:video-call" />
                                        </button>
                                        <button className="chat__headerButton">
                                            <Icon className="Icon-Size-wtp" icon="material-symbols:search" />
                                        </button> */}
                                                <button className="chat__headerButton" onClick={toggleMenuChatHeader}>
                                                    <Icon className="Icon-Size-wtp" icon="bi:three-dots-vertical" />
                                                    {isMenuOpenChatHeader && (
                                                        <div ref={menuRef4}>
                                                            <ModalChatHeader />
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="chat__body"
                                            onScroll={handleScroll}
                                        >

                                            {messageData && messageData.messages.length > 0 && messageData.messages
                                                .map((message, index) => {
                                                    const time = timestampToTime(message.timestamp);
                                                    const imageUrl = imageUrls[message.mediaId];

                                                    return (
                                                        <div
                                                            key={message.id}
                                                            // className={`chat__message-container ${message.from === 'self' ? 'chat__message-container--self' : ''}`}
                                                            className={`chat__message-container ${message.from === 'self'
                                                                ? 'chat__message-container--self'
                                                                : message.from === 'selfauto'
                                                                    ? 'chat__message-container--selfauto'
                                                                    : ''
                                                                }`}
                                                            onClick={() => toggleMenuReply(message)}
                                                        >
                                                            {message.mimeType !== 'comment' && (
                                                                <p className="chat__message">
                                                                    {message.type === 'text' && message.text.body ? (
                                                                        message.text.body
                                                                    ) : message.type === 'commentReply' ? (
                                                                        <div>
                                                                            <p className="reply-message-box">{message.mimeType}</p>
                                                                            <p className="msg__body">{message.text.body}</p>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            {loadingImageIds.has(message.mediaId) ? (
                                                                                <span>Loading...</span>
                                                                            ) : imageUrl ? (
                                                                                <ImageWithAuth token={apiset.RequestHeaderValueField1} imageUrl={imageUrl} />
                                                                            ) : (
                                                                                <span>No image available</span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {/* <br /> */}
                                                                    {/* <span className="chat__message-type">Comment</span> */}
                                                                    <span className="chat__message-time" style={{ top: message.type === 'commentReply' ? '95px' : '40px' }}>  {time}</span>
                                                                    {/* <span className="chat__message-status">{getTickMark(message.status)}</span> */}
                                                                    <span className="chat__message-arrow">
                                                                        {/* <Icon icon="ep:arrow-down" /> */}
                                                                        {message.platform === 'instagram' && message.from !== 'self' && <Icon icon="mdi:reply-outline" />}
                                                                    </span>
                                                                </p>
                                                            )}
                                                            {message.mimeType === 'comment' && (
                                                                <p className="chat__message">
                                                                    {/* {message.mediaId && (
                                                                <img
                                                                    src={getInstaMediaUrl(message.mediaId)}
                                                                    alt="Media"
                                                                    className="chat__message-media"
                                                                />
                                                            )} */}
                                                                    {message.text.body}
                                                                    <span style={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <span className="chat__message-time">{time}</span>
                                                                        <span className="chat__message-type">{message.mimeType}</span>
                                                                    </span>
                                                                    {/* <span className="chat__message-status">{getTickMark(message.status)}</span> */}
                                                                    <span className="chat__message-arrow">
                                                                        {/* <Icon icon="ep:arrow-down" /> */}
                                                                        {<Icon icon="mdi:reply-outline" />}
                                                                    </span>
                                                                </p>

                                                            )}
                                                        </div>
                                                    );
                                                })
                                            }
                                            <div ref={endOfMessagesRef} /> {/* This div acts as the scroll anchor */}
                                        </div>
                                        <div className="chat__footer flex-footer">
                                            {selectedContact && selectedContact.platform === 'whatsapp' && (
                                                <>
                                                    <div className="Icon-margin-send">
                                                        <Icon icon="la:laugh-solid" />
                                                    </div>
                                                    {/* <div className="Icon-margin-send" onClick={toggleMenu}>
                                    <Icon icon="ic:baseline-plus" />
                                </div> */}
                                                    <div className="Icon-margin-send Icon-header-menu" onClick={toggleMenu}>
                                                        {/* <Icon icon={isMenuOpen ? "ic:baseline-close" : "ic:baseline-plus"} /> */}
                                                        {isMenuOpen && (
                                                            <div className="Attach-menu" ref={menuRef}>
                                                                <ModalAttachMenu />
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="Input-Box-Chat flex justify-center items-center">
                                                        <input className="Input-text-chatting bg-[#f6f6f6] !rounded" value={message} onChange={handleInputChange} onKeyDown={handleKeyDown} placeholder="Type a message" type="text" />
                                                    </div>
                                                    <div className="Icon-margin-send !mr-6 bg-[#21c063] !flex !items-center !justify-center size-[40px] !rounded-full">
                                                        {message ? (
                                                            <button className="chat__sendButton" onClick={handleSendClick}>
                                                                <Icon icon="carbon:send" color="black" />
                                                            </button>
                                                        ) : (
                                                            <button className="chat__sendButton">
                                                                <Icon icon="mdi:microphone" color="black" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )
                                            }
                                            {selectedContact && selectedContact.platform === 'instagram' && selectedMessageId && selectedMessageId.id && selectedMessageId.from !== 'self' && (
                                                <>
                                                    <div className="Icon-margin-send">
                                                        <Icon icon="la:laugh-solid" />
                                                    </div>

                                                    <div className="Icon-margin-send Icon-header-menu" onClick={toggleMenu}>

                                                        {isMenuOpen && (
                                                            <div className="Attach-menu" ref={menuRef}>
                                                                <ModalAttachMenu />
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="Input-Box-Chat">
                                                        <input className="Input-text-chatting" value={message} onChange={handleInputChange} placeholder={selectedMessageId.mimeType === 'comment' ? "Type a Reply to Comment" : "Type a Direct message"} type="text" />
                                                    </div>
                                                    <div className="Icon-margin-send">
                                                        {message ? (
                                                            <button className="chat__sendButton" onClick={handleSendClickInsta}>
                                                                <Icon icon="carbon:send" />
                                                            </button>
                                                        ) : (
                                                            <button className="chat__sendButton">
                                                                <Icon icon="mdi:microphone" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )
                                            }
                                        </div>

                                    </div>
                                )}
                            </div>

                        </div>
                        : activeTab === 'campaign' ?
                            <MetaTemplates setmobileslider={setmobileslider} openclosenav={openclosenav} mobileslider={mobileslider} isFromWhatsappWeb={true} /> :

                            activeTab === 'contacts' ?
                                <WTPContacts completed={completed} setmobileslider={setmobileslider} openclosenav={openclosenav} mobileslider={mobileslider} setintervelforprogree={setintervelforprogree} isFromWhatsappWeb={true} /> : null
                    }
                </div>
                {!isModalOpen && (
                    <WhatsappBtnMenu isDesktopFooter={false} activeTab={activeTab} setActiveTab={setActiveTab} />
                )}
            </div>
        </>
    );
};

export default WhatsappWeb;
