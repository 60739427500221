import React, { useState } from 'react'
import { MdDelete } from "react-icons/md";

import { MdFormatListBulleted } from "react-icons/md";
import { IoIosCheckbox } from "react-icons/io";
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoIosSwitch } from "react-icons/io";
import UpdateButton from './UpdateButton';



const SingleChoise = ({updateNode}) => {
  const [radioArray, setRadioArray] = useState([]);
  const [radioName, setRadioName] = useState('')
  const [radioTitle, setRadioTitle] = useState('');
  const addRadio = () => {
    if(!radioName){
      alert('please Enter Value')
      return
    }
    const newRadio = {
      id : Date.now(),
      radioName
    }
    setRadioArray(prev => [...prev, newRadio]);
    setRadioName('')
  }

  const deleteRadio = (id) => {
    setRadioArray(prev => prev.filter(e => e.id !== id));
  }
  return (
    <div className='w-full mb-4'>
      <div className='flex items-center gap-2'>
        <label htmlFor=""><IoMdRadioButtonOn color='blue'/></label>
        <label htmlFor="" className=''>Single Choise</label>
      </div>
      <div className='flex mt-2 justify-center items-center gap-3'>
          <input type="text" placeholder='Radio Title' value={radioTitle} onChange={e => setRadioTitle(e.target.value)} className='outline-none border-2 focus:border-blue-700 rounded-md px-2 py-[2px]' />
          <button className='invisible px-3 py-1 rounded-md bg-blue-700 font-semibold text-white'>Add</button>
        </div>
        <div className='flex mt-2 justify-center items-center gap-3'>
          <input type="text" placeholder='Add value' value={radioName} onChange={e => setRadioName(e.target.value)} className='outline-none border-2 focus:border-blue-700 rounded-md px-2 py-[2px]' />
          <button onClick={addRadio} className='px-3 py-1 rounded-md bg-blue-700 font-semibold text-white'>Add</button>
        </div>
        <div className='mt-2'>
          <ul className='px-12 flex flex-col gap-2'>
            {
              radioArray.map((elm, index) => (
                <li key={elm.id} className='flex justify-between bg-slate-100 items-center py-1 px-2 rounded-sm'>
                  <div className='flex items-center gap-2'>
                    <label htmlFor="">{index + 1}.</label>
                    <label htmlFor="">{elm.radioName}</label>
                  </div>
                  <label onClick={()=>deleteRadio(elm.id)} htmlFor=""><MdDelete cursor={'pointer'} color='red'/></label>
                </li>
              ))
            }
          </ul>
          <UpdateButton type={'radio'} title={radioTitle || false} value={radioArray} updateNode={updateNode}/>
        </div>
    </div>
  )
}
const MultipleChoise = ({updateNode}) => {
  const [checkboxArray, setCheckboxArray] = useState([]);
  const [checkboxName, setCheckboxName] = useState('')
  const [checkboxTitle, setCheckboxTitle] = useState('')
  const addCheckBox = () => {
    if(!checkboxName){
      alert('please Enter Value')
      return
    }
    const newCheckbox = {
      id : Date.now(),
      checkboxName,
    }
    setCheckboxArray(prev => [...prev, newCheckbox]);
    setCheckboxName('')
  }

  const deleteCheckbox = (id) => {
    setCheckboxArray(prev => prev.filter(e => e.id !== id));
  }
  return (
    <div className='w-full mb-4'>
         <div className='flex items-center gap-2'>
          <label htmlFor=""><IoIosCheckbox color='blue'/></label>
          <label htmlFor="" className=''>Multiple Choise</label>
        </div>
        <div className='flex mt-2 justify-center items-center gap-3'>
          <input type="text" placeholder='Chackbox Title' value={checkboxTitle} onChange={e => setCheckboxTitle(e.target.value)} className='outline-none border-2 focus:border-blue-700 rounded-md px-2 py-[2px]' />
          <button className='invisible px-3 py-1 rounded-md bg-blue-700 font-semibold text-white'>Add</button>
        </div>
        <div className='flex mt-2 justify-center items-center gap-3'>
          <input type="text" placeholder='Add value' value={checkboxName} onChange={e => setCheckboxName(e.target.value)} className='outline-none border-2 focus:border-blue-700 rounded-md px-2 py-[2px]' />
          <button onClick={addCheckBox} className='px-3 py-1 rounded-md bg-blue-700 font-semibold text-white'>Add</button>
        </div>
        <div className='mt-2'>
          <ul className='px-12 flex flex-col gap-2'>
            {
              checkboxArray.map((elm, index) => (
                <li key={elm.id} className='flex justify-between bg-slate-100 items-center py-1 px-2 rounded-sm'>
                  <div className='flex items-center gap-2'>
                    <label htmlFor="">{index + 1}.</label>
                    <label htmlFor="">{elm.checkboxName}</label>
                  </div>
                  <label onClick={()=>deleteCheckbox(elm.id)} htmlFor=""><MdDelete cursor={'pointer'} color='red'/></label>
                </li>
              ))
            }
          </ul>
          <UpdateButton type={'checkbox'} title={checkboxTitle || false} value={checkboxArray} updateNode={updateNode}/>
        </div>
    </div>
  )
}
const DropDown = ({updateNode}) => {
  const [dropDownArray, setDropDownArray] = useState([]);
  const [dropDownName, setDropDownName] = useState('')
  const [dropDownTitle, setDropDownTitle] = useState('')
  const addDropDown = () => {
    if(!dropDownName){
      alert('please Enter Value')
      return
    }
    const newDropDown = {
      id : Date.now(),
      dropDownName
    }
    setDropDownArray(prev => [...prev, newDropDown]);
    setDropDownName('')
  }

  const deleteDropDown = (id) => {
    setDropDownArray(prev => prev.filter(e => e.id !== id));
  }
  return (
    <div className='w-full mb-4'>
         <div className='flex items-center gap-2'>
          <label htmlFor=""><MdFormatListBulleted color='blue'/></label>
          <label htmlFor="" className=''>Drop-Down</label>
        </div>
        <div className='flex mt-2 justify-center items-center gap-3'>
          <input type="text" placeholder='Dropdown title' value={dropDownTitle} onChange={e => setDropDownTitle(e.target.value)} className='outline-none border-2 focus:border-blue-700 rounded-md px-2 py-[2px]' />
          <button className='invisible px-3 py-1 rounded-md bg-blue-700 font-semibold text-white'>Add</button>
        </div>
        <div className='flex mt-2 justify-center items-center gap-3'>
          <input type="text" placeholder='Add value' value={dropDownName} onChange={e => setDropDownName(e.target.value)} className='outline-none border-2 focus:border-blue-700 rounded-md px-2 py-[2px]' />
          <button onClick={addDropDown} className='px-3 py-1 rounded-md bg-blue-700 font-semibold text-white'>Add</button>
        </div>
        <div className='mt-2'>
          <ul className='px-12 flex flex-col gap-2'>
            {
              dropDownArray.map((elm, index) => (
                <li key={elm.id} className='flex justify-between bg-slate-100 items-center py-1 px-2 rounded-sm'>
                  <div className='flex items-center gap-2'>
                    <label htmlFor="">{index + 1}.</label>
                    <label htmlFor="">{elm.dropDownName}</label>
                  </div>
                  <label onClick={()=>deleteDropDown(elm.id)} htmlFor=""><MdDelete cursor={'pointer'} color='red'/></label>
                </li>
              ))
            }
          </ul>
          <UpdateButton type={'dropdown'} title={dropDownTitle || false} value={dropDownArray} updateNode={updateNode}/>
        </div>
    </div>
  )
}
const Option = ({updateNode}) => {
  const [optionName, setOptionName] = useState('')
  return (
    <div className='w-full mb-4'>
         <div className='flex items-center gap-2'>
          <label htmlFor=""><IoIosSwitch color='blue'/></label>
          <label htmlFor="" className=''>Option</label>
        </div>
        <div className='flex mt-2 justify-center items-center gap-3'>
          <input type="text" placeholder='Add name' value={optionName} onChange={e => setOptionName(e.target.value)} className='outline-none border-2 focus:border-blue-700 rounded-md px-2 py-[2px]' />
        </div>
        <div className='mt-2'>
          <UpdateButton type={'option'} value={optionName}  updateNode={updateNode}/>
        </div>
    </div>
  )
}

export { SingleChoise, MultipleChoise, DropDown, Option}

