import React, { useState } from 'react'
import UpdateButton from './UpdateButton'

const TextInput = ({updateNode, value}) => {
    const [inputValue, setInputValue] = useState(value)
  return (
    <div>
        <label htmlFor="">Input Field</label>
        <div className='mt-4 w-full'>
            <input type="text" onChange={e => setInputValue(e.target.value)} value={inputValue} className='w-full rounded-sm outline-none border-2 px-2 py-1 focus:border-blue-700' />
        </div>
        <UpdateButton type={'input'} value={inputValue} updateNode={updateNode}/>
    </div>
  )
}

const TextArea = ({updateNode, value}) => {
    const [textAreaValue, setTextAreaValue] = useState(value)
  return (
    <div>
        <label htmlFor="">Textarea</label>
        <div className='mt-4 w-full'>
            <input type="text" onChange={e => setTextAreaValue(e.target.value)} value={textAreaValue} className='w-full rounded-sm outline-none border-2 px-2 py-1 focus:border-blue-700' />
        </div>
        <UpdateButton type={'textarea'} value={textAreaValue} updateNode={updateNode}/>
    </div>
  )
}

const DatePicker = ({updateNode, value}) => {
    const [title, setTitle] = useState(value)
  return (
    <div>
        <label htmlFor="">Date Picker</label>
        <div className='mt-4 w-full'>
            <input type="text" onChange={e => setTitle(e.target.value)} value={title} className='w-full rounded-sm outline-none border-2 px-2 py-1 focus:border-blue-700' />
        </div>
        <UpdateButton type={'date'} value={title} updateNode={updateNode}/>
    </div>
  )
}

export default TextInput
export  { DatePicker, TextArea }
