import React, { useState } from 'react'
import UpdateButton from './UpdateButton'

const Text = ({updateNode, value, type, title}) => {
    const [text, setText] = useState(value)
  return (
    <div>
        <label htmlFor="">{title}</label>
        <div className='mt-4 w-full'>
            <input type="text" onChange={e => setText(e.target.value)} value={text} className='w-full rounded-sm outline-none border-2 px-2 py-1 focus:border-blue-700' />
        </div>
        <UpdateButton type={type} value={text} updateNode={updateNode}/>
    </div>
  )
}

const TextHeading = ({updateNode, value}) => {
  return (
    <Text type={'textheading'} updateNode={updateNode} value={value} title={'Text Heading'}/>
  )
}
const TextSubHeading = ({updateNode, value}) => {
  return (
    <Text type={'textsubheading'} updateNode={updateNode} value={value} title={'Text Sub Heading'}/>
  )
}
const TextBody = ({updateNode, value}) => {
  return (
    <Text type={'textbody'} updateNode={updateNode} value={value} title={'Text Body'}/>
  )
}
const TextCaption = ({updateNode, value}) => {
  return (
    <Text type={'textcaption'} updateNode={updateNode} value={value} title={'Text Caption'}/>
  )
}




export { TextHeading, TextSubHeading, TextBody, TextCaption};