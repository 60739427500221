import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
// import "./newsandnotice.css";
import "../../css/style.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
// import Modal from "./Modal";
import Backend_Url from "../../config/config";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import MyContext from "../../context/Mycontext";
import Swal from "sweetalert2";


const UserLogin = () => {
    //**************************************************** */
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [cookies, setCookie] = useCookies(['token']);
    const [companyCode, setCompanyCode] = useState('');


    const { companyDetails } = useContext(MyContext)


    console.log(companyDetails);
    console.log("Company Code", companyCode);





    console.log(userId);
    console.log(password);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleUserIdChange = (event) => {
        setUserId(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     // debugger;
    //     const userData = {
    //         companyCode: companyCode,
    //         userid: userId,
    //         password: password
    //     };
    //     console.log(userData)

    //     try {
    //         const response = await fetch(`${Backend_Url}/users/v1/login`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(userData),
    //             // credentials: 'include',
    //         });

    //         if (response.ok) {
    //             alert("Login successfully")
    //             setTimeout(() => {
    //                 navigate("/WTPsidebar")
    //             }, 500);
    //         } else {
    //             // Handle login failure
    //             alert('Login failed');
    //         }

    //         if (!response.ok) {
    //             throw new Error('Invalid credentials');
    //         }

    //         const data = await response.json();

    //         // Assuming your API returns a JWT token
    //         const token = data.token;

    //         // Set cookie to store token
    //         setCookie('token', token, { path: '/' });

    //         // Redirect to dashboard or desired page
    //         // Example: history.push('/dashboard');
    //     } catch (error) {
    //         console.error(error.message);
    //     }
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const userData = {
            companyCode: companyCode,
            userid: userId,
            password: password
        };
        console.log(userData);

        try {
            const response = await fetch(`${Backend_Url}/users/v1/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
                // credentials: 'include', // Uncomment if needed
            });

            if (response.ok) {
                const data = await response.json();

                // Assuming your API returns a JWT token
                const token = data.token;

                // Set cookie to store token
                setCookie('token', token, { path: '/' });

                Swal.fire({
                    title: 'Success!',
                    text: 'Login successful. Redirecting...',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    willClose: () => {
                        navigate("/dashboard");
                    }
                });
            } else {
                // Handle login failure
                Swal.fire({
                    title: 'Error!',
                    text: 'Login failed. Please check your credentials and try again.',
                    icon: 'error',
                    // timer: 3000, // Display for 3 seconds
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error(error.message);

            Swal.fire({
                title: 'Error!',
                text: 'An unexpected error occurred. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleCompanyCodeChange = (e) => {
        setCompanyCode(e.target.value);
    };


    return (
        <div className="App">
            <div className="body-content">
                <div className="Flex-Login-User">

                    <div class="Login-container">
                        <div class="heading">Login</div>
                        <div action="" class="form">
                            {/* <select
                                required
                                className="input-Box-login"
                                value={companyCode}
                                name="companyCode"
                                id="companyCode"
                                onChange={handleCompanyCodeChange}
                            >
                                <option value="" disabled>Select Company</option>
                                {companyDetails && companyDetails.map((company) => (
                                    <option key={company.id} value={company.compCode}>
                                        {company.compName}
                                    </option>
                                ))}
                            </select> */}
                            <input required="" className="input-Box-login" type="text" value={companyCode} name="companyCode" id="companyCode" placeholder="Enter Company Code" onChange={handleCompanyCodeChange} />
                            <input required="" className="input-Box-login" type="text" value={userId} name="userid" id="userid" placeholder="User ID" onChange={handleUserIdChange} />
                            <input required="" className="input-Box-login" type={passwordVisible ? 'text' : 'password'} value={password} name="password" id="password" placeholder="Password" onChange={handlePasswordChange} />
                            <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            {/* <span className="forgot-password">
                                <Link to="/ForgotPassword">Forgot Password ?</Link>
                            </span> */}
                            <button class="login-button" type="submit" value="Sign In" onClick={handleSubmit}>Sign In
                            </button>
                            <span className="forgot-password">
                                <Link to="/SignUp">Sign Up</Link>
                            </span>
                            <span className="forgot-password">
                                <Link to="/createcompany">Create Company</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserLogin;
