import React, { useContext, useEffect, useRef, useState } from 'react';

import "../../css/wtpstyle.css";
import { FaCheckCircle, FaFileAlt } from 'react-icons/fa';
import WTPSideBar from '../WhatsappCampaigns/WTPSideBar';
import ModelUsers from '../WhatsappCampaigns/ModelUsers';
import ModelAddContact from './ModelAddContact';
import ModelAddGroups from './ModelAddGroups';
import axios from 'axios';
import Backend_Url from '../../config/config';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import ModalImportContact from './ModalImportContact';
import Modal from '../modal';
import MemberModal from './MembersshowModal';
import { Icon } from '@iconify/react/dist/iconify.js';
import IntegrationLoginModal from '../IntegrationLoginModal';
import MyContext from '../../context/Mycontext';


const WTPContacts = ({ setintervelforprogree, completed, setmobileslider, openclosenav, mobileslider, isFromWhatsappWeb }) => {

    const renderStatusIcon = (status) => {
        if (status === 'Delivered') {
            return <FaCheckCircle style={{ color: 'green', marginRight: '5px' }} />;
        } else if (status === 'Draft') {
            return <FaFileAlt style={{ color: 'orange', marginRight: '5px' }} />;
        }
        return null;
    };
    const [Opendate, setOpendate] = useState(false);
    const [selecedContactDetails, setSelectedContactDetails] = useState([])
    const [selectGruopforFilter, setselectGruopforFilter] = useState(null)
    const [StartDate, setStartDate] = useState(null)
    const [EndDate, setEndDate] = useState(null)
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [GruopnameCheck, setGruopNameCheck] = useState([])
    const [searchvalue, setsearchvalue] = useState("")
    const [cookie] = useCookies(["token"])
    const token = cookie.token;
    const [groupData, setGroupData] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [AllcontactwithGruop, setAllcontactwithgruop] = useState([])
    const [isOpen, setisOpen] = useState(false)
    const [gruopWa_id, setgruopWa_id] = useState(null)
    const [Allgruop, setallGruop] = useState([])

    const [openYesBMModal, setOpenYesBMModal] = useState(false);
    const [yesbmLoader, setYesbmloader] = useState(false);
    /******************************************************** */
    console.log(groupData);
    console.log("Api Data", apiData);
    const [showMenu, setShowMenu] = useState(null); // State to manage the visibility of the menu
    const menuRefAction = useRef(null); // Ref for the dropdown menu

    const { yesbmLoginStatus, apiUrl, setYesbmLoginStatus, setApiUrl } = useContext(MyContext);

    useEffect(() => {
        console.log('wtp Contact', apiUrl);
    }, [apiUrl])

    useEffect(() => {
        debugger
        const getApiUrl = async () => {
            try {
                const response = await axios.get(`${Backend_Url}/Yesbm/getApiUrl`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                console.log('response useEffect', response)

                if (response.status === 200) {
                    setApiUrl(response.data.response.apiUrl)
                    setYesbmLoginStatus(true)
                    // handleConnectUrl()
                }
            } catch (error) {
                console.log(error)
            }
        }

        getApiUrl();

    }, [])


    const handleexport = async () => {
        if (!token) {
            Swal.fire({
                icon: 'warning',
                title: 'No Token Found',
                text: 'Please log in to export contacts.',
            });
            return;
        }
        // debugger
        try {
            const response = await axios.get(`${Backend_Url}/contacts/export-contacts`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                responseType: 'blob' // Important for handling binary data
            });

            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'contacts.xlsx'); // Set the default file name
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            Swal.fire({
                icon: 'success',
                title: 'Download Successful',
                text: 'Contacts have been exported successfully.',
            });

        } catch (error) {
            console.error(error);

            Swal.fire({
                icon: 'error',
                title: 'Export Failed',
                text: 'There was an error exporting the contacts. Please try again later.',
            });
        }
    };

    const [totalmem, settotalmem] = useState(0)
    const OpenMembersModal = (item) => {
        setgruopWa_id(item.wa_id)
        settotalmem(item.memberCount)
        setisOpen(true)
    }

    const handleIconClick = (id) => {
        // debugger
        console.log("Icon clicked", id);
        setShowMenu((prev) => (prev === id ? null : id)); // Toggle menu visibility
    };

    const handleEdit = (item, type) => {
        if (type === 1) {
            setEditableItem(item)
            setModalAddContact(true);
            console.log(`Edit campaign ${item}`);
            setShowMenu(null);
        }
        if (type === 2) {
            setEditableItem(item)
            setModalAddGroup(true);
            console.log(`Edit campaign ${item}`);
            setShowMenu(null);
        }
    };

    const handleDelete = (id) => {
        // alert(id)
        deleteContact(id)
        console.log(`Delete campaign ${id}`);
        setShowMenu(null);
    };
    const handleGroupDelete = (id) => {
        deleteGroup(id)
    }

    const handleClickOutsideAction = (event) => {
        if (menuRefAction.current && !menuRefAction.current.contains(event.target)) {
            setShowMenu(null);
        }
    };

    // -------------------------listen progress event------------------------------
    // const checkProgress = async () => {
    //     await fetch(`${Backend_Url}/contacts/event`)
    //         .then(data => {
    //             console.log(`Progress: ${data.progress}%`);
    //             if (data.message.includes("successfully")) {
    //                 console.log(data);

    //             }
    //         })
    //         .catch(error => console.error("Error fetching progress:", error));
    // };

    // Check progress every 2 seconds



    // ------------------------------------------delete gruop----------------------------------

    const deleteGroup = async (id) => {
        debugger
        if (!token) {
            console.error('No token found');
            return;
        }
        const result = await Swal.fire({
            title: 'Are you delete this Group sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isDismissed) {
            return;
        }

        try {

            // -------------delete gruop-------------
            Swal.fire({
                title: 'Processing...',
                text: 'Please wait while deleting.',
                // html: (<p className={`text-red-400`}>Note:- contact delete if not present in other gruop.</p>),
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            const response = await axios.delete(`${Backend_Url}/contactgroups/delete/contactgruop/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            if (response.status === 200) {
                fetchData()
                fetchGrpData()
                Swal.fire(
                    'Deleted!',
                    'Group has been deleted.',
                    'success'
                );
            }
        } catch (error) {
            console.log(error)
            Swal.fire(
                'Error!',
                'There was a problem deleting the Group.',
                'error'
            );
        }
    }

    const deleteContact = async (id) => {
        if (!token) {
            console.error('No token found');
            return;
        }
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isDismissed) {
            return;
        }

        try {
            const response = await axios.delete(`${Backend_Url}/contacts/contacts/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            if (response.status === 200) {
                fetchData()
                fetchGrpData()
                Swal.fire(
                    'Deleted!',
                    'Your contact has been deleted.',
                    'success'
                );
            }
        } catch (error) {
            Swal.fire(
                'Error!',
                'There was a problem deleting the contact.',
                'error'
            );
        }
    }

    useEffect(() => {
        if (completed == 99) {
            fetchData(1, 50);
            fetchGrpData();
        }
    }, [completed]);

    /********************************************** */

    const [showMenuUser, setShowMenuUser] = useState(false); // State to manage dropdown visibility
    const menuRef = useRef(null); // Ref for the dropdown menu

    // Toggle dropdown visibility
    const handleButtonClick = () => {
        setShowMenuUser(prev => !prev);
    };

    // Handle clicks outside of the menu to close it
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenuUser(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const fetchData = async (page = 1, limit = 50) => {
        if (!token) {
            console.error('No token found');
            return;
        }
        debugger
        try {
            const response = await axios.get(`${Backend_Url}/contacts/getallwithPage?page=${page}&limit=${limit}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                debugger
                setApiData(response.data);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.currentPage);
            } else {
                console.error('Failed to fetch contacts', response.data);
            }
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };
    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };


    // const data = [
    //     { id: 1, name: 'Campaign A', country: 'India', contacts: 9978912068, email: 'user@gmail.com', status: 'Subscribed' },
    //     { id: 2, name: 'Campaign B', country: 'India', contacts: 9978912068, email: 'user@gmail.com', status: 'Subscribed' },
    //     { id: 3, name: 'Campaign C', country: 'India', contacts: 9978912068, email: 'user@gmail.com', status: 'Subscribed' }
    // ];

    const fetchGrpData = async () => {
        try {
            // setLoading(true); // Start loading
            const response = await axios.get(`${Backend_Url}/contactgroups/getall`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setGroupData(response.data); // Set data to state
        } catch (error) {
            console.error('Failed to fetch data:', error);

        }
    };
    useEffect(() => {
        fetchGrpData();
    }, []);




    // const group = [
    //     { id: 1, name: 'Campaign A', date: '	23 Mar 2024', member: 190 },
    //     { id: 2, name: 'Campaign B', date: '	23 Mar 2024', member: 190 },
    //     { id: 3, name: 'Campaign C', date: '	23 Mar 2024', member: 190 }
    // ];


    /************************************************************* */

    const handleCheckboxChange = (id) => {
        setSelectedRows(prevSelectedRows => {
            const updatedSelectedRows = new Set(prevSelectedRows);
            if (updatedSelectedRows.has(id.wa_id)) {
                updatedSelectedRows.delete(id.wa_id);
            } else {
                updatedSelectedRows.add(id.wa_id);
            }
            return updatedSelectedRows;
        });

        setSelectedContactDetails((prev) => [...prev, id])
    };

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        setIsSelectAllChecked(isChecked);
        if (isChecked) {
            const allIds = new Set(apiData?.contacts?.map(item => item?.wa_id));
            setSelectedRows(allIds);
        } else {
            setSelectedRows(new Set());
        }
    };

    /******************************************** */

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isModalImportContact, setisModalImportContact] = useState(false);
    const [isModalAddContact, setModalAddContact] = useState(false);
    const [editableItem, setEditableItem] = useState();

    const handleModalImport = () => {
        setisModalImportContact(true)
    }

    const showModalAddContact = () => {
        setModalAddContact(true);
        setActiveTab(0);
    };

    const hideYesBMModel = () => {
        setOpenYesBMModal(false);
    };
    const hideModalAddContact = () => {
        setModalAddContact(false);
    };
    useEffect(() => {
        fetchData();
    }, [isModalAddContact]);
    /******************************************** */
    // useEffect(() => {
    //     alert(isModalImportContact)
    // }, [isModalImportContact]);
    const [isModalAddGroup, setModalAddGroup] = useState(false);

    const showModalAddGroup = () => {
        setModalAddGroup(true);
        setActiveTab(1);
    };
    const hideModalAddGroup = () => {
        setModalAddGroup(false);
    };

    /******************************************** */

    const [dropdownVisibleContact, setDropdownVisibleContact] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleDropdownContact = () => {
        setDropdownVisibleContact(!dropdownVisibleContact);
    };

    const handleClickOutsideDrop = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target)
        ) {
            setDropdownVisibleContact(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideDrop);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDrop);
        };
    }, []);

    /***************************************************************** */

    const [modalVisibleFilters, setModalVisibleFilters] = useState(false);
    const modalReffilters = useRef(null);
    const triggerRef = useRef(null);

    const toggleModalFilters = () => {
        setModalVisibleFilters(!modalVisibleFilters);
    };

    const handleClickOutsideFilters = (event) => {
        if (
            modalReffilters.current &&
            !modalReffilters.current.contains(event.target) &&
            triggerRef.current &&
            !triggerRef.current.contains(event.target)
        ) {
            setModalVisibleFilters(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideFilters);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideFilters);
        };
    }, []);

    /************************************ */

    // const [activeTab, setActiveTab] = useState(null);

    const [activeTab, setActiveTab] = useState(() => {
        // Retrieve the saved state from local storage or default to 0
        const savedTab = localStorage.getItem('activeTab');
        return savedTab !== null ? JSON.parse(savedTab) : 0;
    });

    const handleClick = (tabIndex) => {
        fetchData()
        setActiveTab(tabIndex);
    };

    useEffect(() => {
        // Save the state to local storage whenever it changes
        localStorage.setItem('activeTab', JSON.stringify(activeTab));
    }, [activeTab]);



    // ------------------------------delete all------------------------------
    const handleDeleteAll = async (data) => {

        if (!token) {
            console.error('No token found');
            return;
        }
        debugger
        const result = await Swal.fire({
            title: 'Are you sure delete all contact?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isDismissed) {
            return
        }

        if (selectedRows.size == 0) {
            Swal.fire(
                'No contact selected!',
                'Please select contact to delete.',
                'error'
            );
            return
        }

        try {
            console.log(selectedRows)
            const setArray = Array.from(selectedRows)
            for (let i = 0; i < selectedRows.size; i++) {
                debugger
                console.log(setArray[i])
                const deleteitem = data.find((item) => item.wa_id === setArray[i])
                const response = await axios.delete(`${Backend_Url}/contacts/contacts/${deleteitem.wa_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });

                if (i == selectedRows.size - 1 && response.status == 200) {
                    Swal.fire(
                        'Deleted!',
                        'Your contact has been deleted.',
                        'success'
                    );
                }
            }

            fetchData()
            fetchGrpData()

        } catch (error) {
            console.log(error)
        }
    }


    const handleToggleDropdown = async () => {
        try {
            const response = await axios.get(`${Backend_Url}/contactgroups/getall`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )

            const data = await response.data
            setallGruop(data)
            console.log(data)
            setIsDropdownVisible(!isDropdownVisible);
        } catch (error) {
            console.log(error);
        }
    };
    const convertToGMT530 = (utcTime) => {
        // Parse the given UTC time string
        const utcDate = new Date(utcTime);

        // Convert to GMT+5:30 by adding 5 hours and 30 minutes
        const gmt530Offset = utcDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000);
        const gmt530Date = new Date(gmt530Offset);

        // Format the date and time to a readable format
        const formattedDate = gmt530Date.toISOString().slice(0, 19).replace('T', ' ');

        return formattedDate;
    };


    // -----------------------------handle gruopname------------------------



    const [allgruop, setallgruop] = useState([])

    // const fetchgruop = async () => {
    //     for (let i = 0; i < apiData?.contacts?.length; i++) {
    //         const result = await HandleGruopName(apiData.contacts[i].wa_id)
    //         setallgruop((prev) => [...prev, result])
    //     }
    // }
    // useEffect(() => { fetchgruop() }, [apiData])

    const handleMultipleAddgruop = async (gruop) => {

        debugger
        try {

            const response = await axios.post(`${Backend_Url}/ContactGroups/new/multiple/add-contacts`, { contacts: selecedContactDetails, groupIds: [gruop.wa_id] }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response.status === 200) {
                fetchGrpData()
                setSelectedRows(new Set())
                Swal.fire('Success', 'Contacts added to all groups successfully!', 'success');
            } else {
                Swal.fire('Warning', `Some requests failed`, 'warning');
            }


        } catch (error) {
            // Enhanced error handling
            if (error.response) {

                console.error("Server Error:", error.response.data);
                Swal.fire('Error', `Server Error: ${error.response.data.message || 'Unable to add contacts'}`, 'error');
            } else if (error.request) {

                console.error("Network Error:", error.request);
                Swal.fire('Error', 'Network Error: No response from the server.', 'error');
            } else {
                // Error setting up the request
                console.error("Error:", error.message);
                Swal.fire('Error', `Error: ${error.message}`, 'error');
            }
        }
    }


    // -------------------------------------filter---------------------------------------
    const handleFilter = async (e) => {
        debugger
        setsearchvalue(e.target.value)
        // "http://localhost:5000/contacts/filterContacts"
        // if (/^\d+$/.test(e.target.value)) {
        //     setApiData((prev) => ({
        //         ...prev,
        //         contacts: prev.contacts.filter((item) => item.wa_id?.includes(e.target.value)),
        //     }))
        // } else {
        //     setApiData((prev) => ({
        //         ...prev,
        //         contacts: prev.contacts.filter((item) => item.Profile.toLocaleLowerCase()?.includes(e.target.value.toLocaleLowerCase())),
        //     }))
        // }
        console.log(apiData)
        if (e.target.value == 0) {
            fetchData()
        }
    }

    const highlightText = (text, searchTerm) => {
        if (!searchTerm.trim()) return text;

        const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === searchTerm.toLowerCase() ? (
                <mark key={index}>{part}</mark>
            ) : (
                <React.Fragment key={index}>{part}</React.Fragment>
            )
        );
    };


    // -----------------------------------------date filter--------------------------------------
    const handleDatefilter = async (e) => {
        const filterType = e.target.value;
        debugger
        const today = new Date();

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
        };
        let startDate, endDate;

        switch (filterType) {
            case "Today":
                startDate = endDate = today;
                break;

            case "Yesterday":
                startDate = endDate = new Date(today.setDate(today.getDate() - 1));

                break;

            case "This Month":
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;

            case "Custom":
                // const customStartDate = new Date(document.getElementById('startDateInput').value);
                // const customEndDate = new Date(document.getElementById('endDateInput').value);
                // startDate = customStartDate;
                // endDate = customEndDate;
                break;

            default:
                console.log("Invalid filter type");
                return;
        }

        if (startDate) {
            startDate.setHours(0, 0, 0, 0);
            setStartDate(formatDate(startDate));
        }
        if (endDate) {
            endDate.setHours(23, 59, 59, 999);
            setEndDate(formatDate(endDate));
        }

    }

    const fetchDatefilterdata = async () => {
        debugger
        const filter = await axios.get(`${Backend_Url}/contacts/filterBydate/${StartDate}/${EndDate}`, {
            params: {
                page: currentPage,
                pageSize: 50
            },
            headers: {
                Authorization: `Bearer ${cookie.token}`
            }
        })

        const data = await filter.data
        console.log(data)
        setTotalPages(data.pagination.totalPages);
        setCurrentPage(data.pagination.currentPage);
        setApiData({
            contacts: data.data
        })
    }
    useEffect(() => {
        if (StartDate && EndDate) {
            fetchDatefilterdata()
        }
    }, [StartDate, EndDate])

    const handlegruopfilter = async (e) => {
        try {
            debugger

            if (e.target.value == "All") {
                fetchData();
            }
            const gruopfilterdata = await axios.get(`${Backend_Url}/contactgroups/${e.target.value}/contacts`, {
                headers: {
                    Authorization: `Bearer ${cookie.token}`
                }
            })
            const result = await gruopfilterdata
            setApiData(result.data)
        } catch (error) {
            console.log(error)
        }
    }


    // ---------------------------------handle gruop check-----------------------------
    const [showgruopname, setgruopname] = useState({
        wa_id: [],
        Gruops: [],
        Nogruop: false
    })
    const [Gruoploading, setGruoploading] = useState(false)
    const hanldegruopcheck = async (wa_id) => {
        debugger
        setGruoploading(true)
        try {
            if (showgruopname.Gruops.length == 0 && !showgruopname.Nogruop) {
                const response = await axios.get(`${Backend_Url}/contactgroups/getGruopByContact/${wa_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })

                // Wait for all promises to resolve
                const result = await response.data.data
                console.log(result)
                if (result.gruops.length != 0) {
                    setgruopname((prev) => ({ Gruops: result?.gruops?.map((item) => `${item.Name + ', '}`), wa_id: [...prev.wa_id, wa_id] }))
                } else {
                    setgruopname({
                        wa_id: wa_id,
                        Nogruop: true,
                        Gruops: []
                    })
                }

                setGruoploading(false)
            } else {
                setgruopname({
                    wa_id: [],
                    Gruops: [],
                    Nogruop: false
                })
            }
        } catch (error) {
            console.log(error)
        }

    }


    // ----------------handlesearch---------------------------------
    const handlesearch = async (e) => {
        try {
            // "http://localhost:5000/contacts/filterContacts"
            await axios.post(`${Backend_Url}/contacts/filterContacts`, {
                "filter": [
                    {
                        "fieldName": "wa_id",
                        "operatorName": "like",
                        "compareValue": searchvalue
                    }
                ]
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((res) => {
                console.log(res.data);
                setApiData({
                    contacts: res.data.contacts
                });
            })
        } catch (error) {
            console.log(error);

        }
    }


    const handleEnter = async (e) => {
        try {
            if (e.key == 'Enter') {
                const id = document.getElementById('btn-search')
                id.click();
            }
            console.log(e);

        } catch (error) {
            console.log(error);
        }
    }

    const showYesbmModal = () => {
        setOpenYesBMModal(true);
    }

    // handle yes bm import contact click
    const handleImportFromYesbm = async () => {
        if (yesbmLoginStatus) {
            getContact();
        } else {
            setOpenYesBMModal(true);
        }
    }

    // -----------------------date formater fnc------------------------
    function formatDate(inputDate) {
        debugger
        console.log(inputDate)
        if (inputDate != null) {
            const date = new Date(inputDate);

            const day = String(date.getDate()).padStart(2, '0'); // Ensure day is two digits
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based index, so add 1)
            const year = date.getFullYear();

            return `${day}-${month}-${year}`;
        } else {
            return '----';
        }
    }


    const getContact = async () => {
        setYesbmloader(true)
        debugger
        try {

            const start = 0;
            const end = 1000;
            const format = { grp: "212", txtsearch: "", start: start, end: end, FromDate: "", ToDate: "", filter: "__%" }
            const jsonData = encodeURIComponent(JSON.stringify(format))
            console.log(jsonData);
            // const data = {}
            const getContactUrl = apiUrl.split('/LoginPage')[0]
            console.log('getContact', getContactUrl);

            //new http://137.59.201.149/YES-BM-Techcherry/InceptionService.asmx/CustomerReminderDateListForWhatsapp 
            //DOB   CRM/ReportNextVisit_LensExpiry.aspx/GetData

            // CRM/ReportNextVisit_LensExpiry.aspx/GetData

            // https://www.myopticalapp.in/InceptionService.asmx/viewAccountMaster
            const url = `${getContactUrl}/InceptionService.asmx/viewAccountMaster`
            const response = await axios.post(`${Backend_Url}/Yesbm/save-contact`, { apiUrl: url, arg: jsonData }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                withCredentials: true
            })

            if (response.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Contacts are successfully save!.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            }
            console.log(response)
        } catch (error) {
            console.log('catch', error)
            if (error.response.data.NotfoundStatus) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Contact not found!.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } finally {
            setYesbmloader(false)
        }
    }

    return (
        <div className="Flex-Head-Right-Compaigns">
            {!isFromWhatsappWeb && <div>
                <WTPSideBar openclosenav={openclosenav} mobileslider={mobileslider} setmobileslider={setmobileslider} />
            </div>}
            <div className="Right-Campaigns-Body">
                <div className="Campaigns_header">
                    <div className="Campaigns_header">
                        <h3 className='font-bold text-[1.3rem] mt-2'>Contact Management</h3>
                    </div>
                    <div className="Campaigns_headerRight">
                        <button className="Campaigns_headerButton" onClick={handleButtonClick}>
                            <Icon icon="arcticons:goodwy-contacts" />
                            {showMenuUser && (
                                <div ref={menuRef}>
                                    <ModelUsers />
                                </div>
                            )}
                        </button>
                    </div>
                </div>
                <div className="Campaigns_body_Bg sm:!max-w-[100vw]">
                    <div className="Create-Compaigns-container sm:!grid sm:!grid-cols-2 sm:!gap-2">
                        <div>
                            {yesbmLoginStatus && <button className="export-all-button sm:!text-[.7rem]" onClick={showYesbmModal}>
                                <Icon icon="clarity:export-line" /> Import Contact From YES-BM
                            </button>}
                        </div>
                        <div>
                            <button className="export-all-button sm:!text-[.7rem]" onClick={handleexport}>
                                <Icon icon="clarity:export-line" /> Export Contacts Into Excel
                            </button>
                        </div>
                        <div>
                            <button className="export-all-button sm:!text-[.7rem]" onClick={handleModalImport} >
                                <Icon icon="clarity:export-line" /> Import Contacts From Excel
                            </button>
                        </div>
                        <div className="action-buttons">
                            {activeTab === 0 && (
                                <button className="view-all-button" onClick={showModalAddContact}>
                                    <Icon icon="material-symbols:add" /> Add Contact
                                </button>
                            )}
                            {activeTab === 1 && (
                                <button className="view-all-button" onClick={showModalAddGroup}>
                                    <Icon icon="material-symbols:add" /> Add Group
                                </button>
                            )}
                        </div>
                    </div>
                    {isModalAddContact && (
                        <div className="modal-Category">
                            <div className="modal-content modal-content-contact p-!0 modal-campaigns-width max-h-[600px] overflow-auto relative">
                                <div className="modal-title modal-title-contact">
                                    <h3 className="modal-heading-top font-bold text-[1.3rem] mt-2">Add Contact</h3>
                                    <span className="close" onClick={hideModalAddContact}>&times;</span>
                                </div>
                                <div>
                                    <ModelAddContact editableItem={editableItem} setModalAddContact={setModalAddContact} fetchContactData={fetchData} fetchGrpData={fetchGrpData} />
                                </div>
                            </div>
                        </div>
                    )}
                    {openYesBMModal && (
                        <div className="modal-Category">
                            <div className="modal-content modal-content-contact p-!0 modal-campaigns-width max-h-[600px] overflow-auto relative">
                                <div className="modal-title modal-title-contact">
                                    <h3 className="modal-heading-top font-bold text-[1.3rem] mt-2">Import Contact From YES-BM</h3>
                                    <span className="close" onClick={hideYesBMModel}>&times;</span>
                                </div>
                                <div>
                                    <IntegrationLoginModal editableItem={editableItem} setOpenYesBMModal={setOpenYesBMModal} fetchContactData={fetchData} fetchGrpData={fetchGrpData} isModalFromContacts={true} />
                                </div>
                            </div>
                        </div>
                    )}

                    {isModalImportContact && (
                        <div className="modal-Category">
                            <div className="modal-content modal-campaigns-width">
                                <div className="modal-title">
                                    <h3 className="modal-heading-top font-bold text-[1.3rem] mt-2">Import Contact</h3>
                                    <span className="close" onClick={() => setisModalImportContact(false)}>&times;</span>
                                </div>
                                <div>
                                    <ModalImportContact setisModalImportContact={setisModalImportContact} setintervelforprogree={setintervelforprogree} fetchContactData={fetchData} fetchGrpData={fetchGrpData} />
                                </div>
                            </div>
                        </div>
                    )}

                    {isModalAddGroup && (
                        <div className="modal-Category">
                            <div className="modal-content modal-campaigns-width">
                                <div className="modal-title">
                                    <h3 className="modal-heading-top font-bold text-[1.3rem] mt-2">Group</h3>
                                    <span className="close" onClick={hideModalAddGroup}>&times;</span>
                                </div>
                                <div>
                                    <ModelAddGroups editableItem={editableItem} fetchGrpData={fetchGrpData} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="Contacts-Groups-content">
                        <div
                            className={`Groups-Contacts-width ${activeTab === 0 ? 'active' : ''}`}
                            onClick={() => handleClick(0)}
                        >
                            All Contacts
                        </div>
                        <div
                            className={`Groups-Contacts-width ${activeTab === 1 ? 'active' : ''}`}
                            onClick={() => handleClick(1)}
                        >
                            Groups
                        </div>
                    </div>
                    {activeTab === 0 && (
                        <div>
                            <div className="Campaigns-form-content">
                                {/* <div className='contact-subscribed'>28349 Contacts. 28313 Subscribed Contacts. 36 Unsubscribed.</div> */}
                                <div className="Content-padding-Campaigns Content-position-contact">
                                    <div className="Flex-Input-Campaigns">
                                        <div className='Input-Box-Compaigns flex items-center justify-start'>
                                            <input onKeyDown={handleEnter} className="search-input-contact" onChange={(e) => handleFilter(e)} type="text" name="" placeholder="Search By Number" />
                                            <button id='btn-search' onClick={handlesearch} className={`w-auto h-auto p-1 border-none bg-transparent flex items-center justify-center text-xl`}>
                                                <Icon icon="si:search-fill" style={{ color: "black" }} />
                                            </button>
                                        </div>


                                        {/* <div className='Input-Box-Compaigns'>
                                            <button className="Contact-Type-button" onClick={toggleDropdownContact} ref={buttonRef}>
                                                Contact Type
                                            </button>
                                            {dropdownVisibleContact && (
                                                <div className="Dropdown-menu-Contact" ref={dropdownRef}>
                                                    <div className="Dropdown-item-contact">All</div>
                                                    <div className="Dropdown-item-contact">Subscribed</div>
                                                    <div className="Dropdown-item-contact">Unsubscribed</div>
                                                </div>
                                            )}
                                        </div> */}
                                        <div className='Input-Box-Filter'>
                                            <div className='Icon-flex-add' onClick={toggleModalFilters}>
                                                {/* <select className="Query-select-Box" type="text" name="" placeholder="Filters" >
                                                    <option>Filters</option>
                                                </select> */}
                                                <div className="Query-select-Box">
                                                    Filters
                                                    <Icon icon="solar:filter-bold" />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {modalVisibleFilters && (
                                    <div className="Content-padding-Campaigns" ref={modalReffilters}>
                                        <div className="Flex-Input-Campaigns">
                                            <div className='Input-Box-Compaigns'>
                                                <label className="Campaigns-lbl">Created On</label>
                                                {/* <button className={`date-filter-btn`} onClick={() => setOpendate((prev) => !prev)}>Date Filter</button> */}
                                                {/* {
                                                    Opendate && <div>
                                                        <button>Today</button>
                                                        <button>Yesterday</button>
                                                        <button>This Month</button>
                                                        <div>
                                                            <input />
                                                            to
                                                            <input />
                                                        </div>
                                                    </div>
                                                } */}

                                                <div className='Icon-flex-add'>
                                                    <select onChange={(e) => handleDatefilter(e)} className="Query-select-Box" type="text" name="" placeholder="" >
                                                        <option>--select--</option>
                                                        <option>Today</option>
                                                        <option>Yesterday</option>
                                                        <option>This Month</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className='input-box-compaing'>
                                                <label className="Campaigns-lbl">Custom Date</label>
                                                <div className={`inside-custom-date`}>
                                                    <input className={`Query-select-Box set-height`} onChange={(e) => setStartDate(e.target.value)} type='date' name='start-date' />
                                                    <p>to</p>
                                                    <input className={`Query-select-Box`} type='date' onChange={(e) => setEndDate(e.target.value)} name='start-date' />
                                                </div>
                                            </div>
                                            <div className=' '>
                                                <label className="Campaigns-lbl">Filter By Group</label>
                                                <div className='Icon-flex-add'>
                                                    <select onChange={(e) => handlegruopfilter(e)} className="Query-select-Box" type="text" name="" placeholder="" >
                                                        <option>All</option>
                                                        {groupData.map((gruop, index) => {
                                                            return (
                                                                <>
                                                                    <option value={gruop.wa_id} onClick={() => setselectGruopforFilter(gruop)}>{gruop.Name}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='Input-Box-Compaigns'>
                                                <label className="Campaigns-lbl">Country</label>
                                                <div className='Icon-flex-add'>
                                                    <select className="Query-select-Box" type="text" name="" placeholder="" >
                                                        <option>--select--</option>
                                                        <option>91</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="Campaigns-form-content">
                                <div className={`sm:overflow-x-scroll lg:overflow-x-auto sm:w-[100%]`}>
                                    <div className="pagination-controls flex !justify-end mb-4">
                                        <button
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            <Icon icon="ooui:arrow-next-rtl" width="12" height="12" style={{ color: "black" }} />

                                        </button>
                                        <span>Page {currentPage} of {totalPages}</span>
                                        <button
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        >
                                            <Icon icon="ooui:arrow-next-ltr" width="12" height="12" style={{ color: "black" }} />
                                        </button>
                                    </div>
                                    <div className={`w-auto h-auto overflow-x-scroll`}>
                                        <table className="Table-Body-content">
                                            <thead className="Table-Campaigns-color">
                                                <tr className="Table-Bg-color">
                                                    <th className="th-name-camp">S No</th>
                                                    <th className="th-name-camp">

                                                        <div className={`include-dlt-add`}>
                                                            <input
                                                                type="checkbox"
                                                                className="header-checkbox"
                                                                checked={isSelectAllChecked}
                                                                onChange={handleSelectAllChange}
                                                            />
                                                            <button onClick={() => handleDeleteAll(apiData.contacts)} className={`dlt-btn`}>
                                                                <span className={`delete-button`}>
                                                                    <Icon icon="fluent:delete-12-filled" style={{ color: '#fff' }} />
                                                                </span>
                                                            </button>
                                                            {
                                                                selectedRows.size > 0 && (
                                                                    <>
                                                                        <div className="dropdown-container">
                                                                            <button onClick={handleToggleDropdown} className="add-btn">
                                                                                <span className="add-button">
                                                                                    <Icon icon="typcn:plus" style={{ color: 'rgb(46 73 140)' }} />
                                                                                </span>
                                                                            </button>
                                                                            <div className={`dropdown ${isDropdownVisible ? 'show' : ''}`}>
                                                                                <ul>
                                                                                    {Allgruop?.map((gruop, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <li style={{ color: "black" }} onClick={() => handleMultipleAddgruop(gruop)}>{gruop.Name}</li>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </ul>
                                                                                <div onClick={() => setIsDropdownVisible((prev) => !prev)} className={`dropdown-menu-action-close`}></div>
                                                                            </div>

                                                                        </div>
                                                                    </>

                                                                )
                                                            }
                                                        </div>
                                                    </th>
                                                    <th className="th-name-camp">Full Name</th>
                                                    <th className="th-name-camp">Country</th>
                                                    <th className="th-name-camp">Mobile Number</th>
                                                    <th className="th-name-camp">Email</th>
                                                    <th className="th-name-camp">DOB</th>
                                                    <th className="th-name-camp">DOM</th>
                                                    <th className="th-name-camp">Group</th>
                                                    <th className="th-name-camp">Created Date</th>
                                                    <th className="th-name-camp">Status</th>
                                                    <th className="th-name-camp"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {apiData && apiData.contacts ? (
                                                    apiData.contacts.map((item, index) => (
                                                        <tr key={item.wa_id} className="Table-Bg-color">
                                                            <td>
                                                                {(index + 1) + (50 * (currentPage - 1))}
                                                            </td>
                                                            <td onClick={() => handleCheckboxChange(item)} className="td-name-campaigns !py-0 m-0">
                                                                <input
                                                                    className='Checkbox-table-row'
                                                                    type="checkbox"
                                                                    checked={selectedRows.has(item.wa_id)}

                                                                />
                                                            </td>
                                                            <td className="td-name-campaigns !py-0 m-0">{item?.Profile?.length > 1 && item.Profile != null ? highlightText(item.Profile, searchvalue) : '-----------'}</td>
                                                            <td className="td-name-campaigns !py-0 m-0">{item.country || 'India'} </td>
                                                            <td className="td-name-campaigns !py-0 m-0"> {highlightText(item.wa_id, searchvalue)} </td>
                                                            <td className="td-name-campaigns !py-0 m-0">{item.Email !== null ? item.Email : '---------'}</td>
                                                            <td className='td-name-campaigns !py-0 m-0'>{formatDate(item.DOB)}</td>
                                                            <td className='td-name-campaigns !py-0 m-0'>{formatDate(item.DOM)}</td>
                                                            <td style={{ cursor: 'pointer' }} onClick={() => hanldegruopcheck(item.wa_id)} className={`td-name-campaigns !py-0 m-0  `}>{
                                                                showgruopname.wa_id.includes(item.wa_id) ? showgruopname.Gruops.length != 0 ? <p className={`flex m-0 text-green-600`}>{showgruopname.Gruops}</p> : <p className={`w-auto h-auto flex m-0 items-center  justify-center text-red-500`}>
                                                                    No Group </p> : <p>
                                                                    ShowGroup
                                                                </p>
                                                            }</td>
                                                            <td className={`td-name-campaigns !py-0 m-0 `}>{item?.createdOn.split("T")[0]}</td>
                                                            <td className="td-name-campaigns !py-0 m-0">
                                                                {renderStatusIcon(item.status)} {item.status}
                                                                ------
                                                            </td>
                                                            <td className="td-name-campaigns !py-0 m-0">
                                                                <div className="action-icon-container" ref={menuRefAction} >
                                                                    <Icon
                                                                        icon="bi:three-dots-vertical"
                                                                        onClick={() => handleIconClick(item.wa_id)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                    {showMenu === item.wa_id && (
                                                                        <>
                                                                            <div className="dropdown-menu-action">
                                                                                <div>
                                                                                    <button onClick={() => handleEdit(item, 1)}>Edit</button>
                                                                                </div>
                                                                                <div>
                                                                                    <button>Unsubscribe</button>
                                                                                </div>
                                                                                <div>
                                                                                    <button onClick={() => handleDelete(item.wa_id)}>Delete</button>
                                                                                </div>
                                                                                <div onClick={() => setShowMenu(null)} className={`dropdown-menu-action-close`}></div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7">Loading...</td>
                                                    </tr>
                                                )}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                {/* <div className="pagination-controls">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        <Icon icon="ooui:arrow-next-rtl" width="12" height="12" style={{ color: "black" }} />

                                    </button>
                                    <span>Page {currentPage} of {totalPages}</span>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        <Icon icon="ooui:arrow-next-ltr" width="12" height="12" style={{ color: "black" }} />
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    )}

                    {activeTab === 1 && (
                        // <div>
                        //     <ModelGroups />
                        // </div>
                        <div className="Campaigns-form-content">
                            <div className={`sm:overflow-x-scroll sm:w-[100%]`}>
                                <table className="Table-Body-content">
                                    <thead className="Table-Campaigns-color">
                                        <tr className="Table-Bg-color">
                                            <th className="th-name-camp">Group Name</th>
                                            <th className="th-name-camp">Created Date</th>
                                            <th className="th-name-camp">Member</th>
                                            <th className="th-name-camp">Details</th>
                                            <th className="th-name-camp"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groupData.map(item => (
                                            <tr key={item.wa_id} className="Table-Bg-color">
                                                <td className="td-name-campaigns">{item.Name}</td>
                                                <td className="td-name-campaigns">{item.CreatedOn ? convertToGMT530(item.CreatedOn) : item.CreatedOn}</td>
                                                <td className="td-name-campaigns"> {item.memberCount}
                                                </td>
                                                <td className="td-name-campaigns">
                                                    <p onClick={() => OpenMembersModal(item)} className={`view-member`}>
                                                        <Icon icon="fluent:people-48-filled" style={{ color: "#528052" }} />
                                                        Members
                                                    </p>

                                                </td>
                                                <td className="td-name-campaigns">
                                                    <div className="action-icon-container" ref={menuRefAction}>
                                                        <Icon
                                                            icon="bi:three-dots-vertical"
                                                            onClick={() => handleIconClick(item.wa_id)}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        {showMenu === item.wa_id && (
                                                            <>
                                                                <div className="dropdown-menu-action">

                                                                    <button onClick={() => handleEdit(item, 2)}>Edit</button>


                                                                    <button onClick={() => handleGroupDelete(item.wa_id)}>Delete</button>

                                                                    <div onClick={() => setShowMenu(null)} className={`dropdown-menu-action-close`}></div>
                                                                </div>

                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    )}

                </div>
                {
                    isOpen && <MemberModal totalmembers={totalmem} isOpen={isOpen} onClose={setisOpen} wa_id={gruopWa_id} />
                }

            </div>
        </div>
    )
}

export default WTPContacts
