import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Gallery from "./Component/Gallery";
import Slider from "./Component/Slider";
import Newsandnotice from "./Component/news&notice/newsandnotice";
import Flashnews from "./Component/flashnews/Flashnews";
import GeneralSetting from "./Component/GeneralSetting/GeneralSetting";
import ProductCategoryMaster from "./Component/ProductCategoryMaster/ProductCategoryMaster";
import UserLogin from "./Component/UserLogin/UserLogin";
import SignUp from "./Component/UserLogin/SignUp";
import ForgotPassword from "./Component/UserLogin/ForgotPassword";
import ResetPassword from "./Component/UserLogin/ResetPassword";
import NavBar from "./Component/NavBar/NavBar";
import Whatsapp from "./Component/Whatsapp/Whatsapp";
import WhatsAppPanel from "./Component/Whatsapp/WhatsAppPanel";
import MyContext from "./context/Mycontext";
import Backend_Url from "../src/config/config";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import WhatsappWeb from "./Component/WhatsappWeb/WhatsappWeb";
import WTPSideBar from "./Component/WhatsappCampaigns/WTPSideBar";
import WTPCampaigns from "./Component/WhatsappCampaigns/WTPCampaigns";
import CreateCampaigns from "./Component/WhatsappCampaigns/CreateCampaigns";
import CreateCompany from "./Component/Company/Company";
import WTPContacts from "./Component/WhatsappContacts/WTPContacts";
import WTPBusinessAccount from "./Component/WABAStatus/WTPBusinessAccount";
import Template from "./Component/WhatsappCampaigns/Template";
import CreateCompaignModal from "./Component/WhatsappCampaigns/CreateCompaignModal";
import Dashboard from "./Component/Dashboard/Dashboard";
import CreateCustomTemplate from "./Component/WhatsappCampaigns/CreateCustomTemplate";
import MetaTemplates from "./Component/WhatsappCampaigns/MetaTemplates";
import WhatsappAutomationAndChatBot from "./Component/WhatsappWeb/WhatsappAutomationAndChatBot";
import AutomationDetail from "./Component/WhatsappWeb/AutomationDetail";
import Flow from "./Component/WhatsappWeb/Flow";
import Integration from "./Component/Integration/Integration";

import FlowWithProvider from "./Component/flow/MainFlow";
import Swal from "sweetalert2";
import Loader from "./Component/Loader/Loader";

function App() {
  const [cookies] = useCookies(["token"]);
  const isAuthenticated = cookies.token;
  const [ws, setWs] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [messageData, setMessageData] = useState();
  const [lstmessageData, setLstMessageData] = useState();
  const [messagedeliverd, setmessagedeliverd] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [mobileslider, setmobileslider] = useState(false);
  const [modalopen, setmodalopen] = useState(false);
  const [message, setmessage] = useState([]);
  const [apiset, setApiSet] = useState();
  const [isFlowModalOpen, setIsFlowModalOpen] = useState(false);
  const [instagramAccessToken, setInstagramAccessToken] = useState("");
  const [apiDropdown, setApiDropdown] = useState("");
  const [defaultId, setDefaultId] = useState("");
  const [apiUrl, setApiUrl] = useState('')
  const [yesbmLoginStatus, setYesbmLoginStatus] = useState(false);

  // const [companyDetails, setCompanyDetails] = useState("");

  // const fetchCompData = async () => {
  //   try {
  //     const response = await axios.get(`${Backend_Url}/companydetails/getall`);
  //     if (response.data.length > 0) {
  //       console.log(response);
  //       setCompanyDetails(response.data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchCompData();
  // }, []);

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     console.error("No token found");
  //     return;
  //   }

  //   const createWebSocketConnection = () => {
  //     const socket = new WebSocket(`${Backend_Url}`);
  //     setWs(socket);

  //     socket.onopen = () => {
  //       console.log("WebSocket connection opened");
  //       socket.send(JSON.stringify({ type: "auth", isAuthenticated }));
  //     };

  //     socket.onmessage = (event) => {
  //       const data = JSON.parse(event.data);
  //       if (data.error) {
  //         console.error(data.error);
  //       } else {
  //         if (data.type === "latestMessages") {
  //           setLstMessageData(data.messages);
  //         } else if (data.type === "contactMessages") {
  //           // setMessageData(data);

  //           if (data.messages.length > 0) {
  //             setMessageData((prevData) => {
  //               const existingMessages = prevData?.messages || [];
  //               const existingMessageIds = new Set(
  //                 existingMessages.map((msg) => msg.id)
  //               );
  //               const newMessages = data.messages.filter(
  //                 (msg) => !existingMessageIds.has(msg.id)
  //               );

  //               if (newMessages.length > 0) {
  //                 toast.info(`You have ${newMessages.length} new messages`);
  //               }

  //               return {
  //                 ...prevData,
  //                 messages: [...existingMessages, ...newMessages].sort(
  //                   (a, b) => a.timestamp - b.timestamp
  //                 ),
  //               };
  //             });
  //           } else {
  //             setHasMore(false);
  //           }
  //         }
  //       }
  //     };

  //     socket.onerror = (error) => {
  //       console.error("WebSocket error:", error);
  //     };

  //     socket.onclose = () => {
  //       console.log("WebSocket connection closed");
  //       // Attempt to reconnect after a delay
  //       setTimeout(createWebSocketConnection, 1000);
  //     };
  //   };

  //   // setInterval(() => {
  //   //   createWebSocketConnection();
  //   // }, 1000);

  //   return () => {
  //     if (ws) {
  //       ws.close();
  //     }
  //   };
  // }, []);

  const wsRef = useRef(null);

  const notifiedContactsRef = useRef(new Set());
  // const notificationSound = new Audio("/path/to/notification.mp3");
  const handleNewMessages = (messages) => {
    const notifiedContacts = notifiedContactsRef.current;

    messages.forEach((message) => {
      const { contactWaId, unreadCount, timestamp } = message;

      if (
        unreadCount > 0 &&
        (!notifiedContacts[contactWaId] ||
          notifiedContacts[contactWaId] < timestamp)
      ) {
        // notificationSound.play().catch((error) => {
        //   console.error("Failed to play notification sound:", error);
        // });
        toast.info(`You have ${unreadCount} new messages from ${contactWaId}`);

        notifiedContacts[contactWaId] = timestamp;
      }
    });

    notifiedContactsRef.current = notifiedContacts;
  };

  const createWebSocketConnection = useCallback(() => {
    if (!isAuthenticated) {
      console.error("No token found");
      return;
    }

    const socket = new WebSocket(Backend_Url);

    socket.onopen = () => {
      console.log("WebSocket connection opened");
      socket.send(JSON.stringify({ type: "auth", token: isAuthenticated }));
      fetchlastmessage();
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.error) {
        console.error(data.error);
      } else {
        if (data.type === "latestMessages") {
          setLstMessageData(data.messages);
          handleNewMessages(data.messages);
        } else if (data.type === "contactMessages") {
          if (data.messages.length > 0) {
            setMessageData((prevData) => {
              const existingMessages = prevData?.messages || [];
              const existingMessageIds = new Set(
                existingMessages.map((msg) => msg.id)
              );
              const newMessages = data.messages.filter(
                (msg) => !existingMessageIds.has(msg.id)
              );
              // if (newMessages.length > 0) {
              //   toast.info(`You have ${newMessages.length} new messages`);
              // }
              return {
                ...prevData,
                messages: [...existingMessages, ...newMessages].sort(
                  (a, b) => a.timestamp - b.timestamp
                ),
              };
            });
          } else {
            setHasMore(false);
          }
        }
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
      setTimeout(createWebSocketConnection, 1000);
    };
    console.log("Setting WebSocket state:", socket);
    setWs(socket);
    wsRef.current = socket;
  }, [isAuthenticated]);

  const fetchContactMessages = (wa_id, page) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(
        JSON.stringify({ type: "getContactMessages", wa_id, page })
      );
    }
  };

  const fetchlastmessage = () => {
    debugger;
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      console.log("Sending fetchlastmessage request");
      wsRef.current.send(JSON.stringify({ type: "getLatestMessages" }));
    } else {
      console.error("WebSocket connection is not established");
    }
  };

  useEffect(() => {
    createWebSocketConnection();

    return () => {
      if (wsRef.current) {
        console.log("Closing WebSocket connection");
        wsRef.current.close();
      }
    };
  }, [createWebSocketConnection]);

  // useEffect(() => {
  //   console.log("WebSocket state:", wsRef.current);
  // }, [wsRef.current]);

  useEffect(() => {
    // fetchlastmessage();
    // const intervalId = setInterval(() => {}, 1500);
    // return () => clearInterval(intervalId);
  }, []);

  const [completed, setcompleted] = useState(0);
  const [showupload, setshowupload] = useState(false);
  let twosecinterval = null;
  let traksetinterval = false;

  const setintervelforprogree = () => {
    setcompleted(0);
    localStorage.setItem("intervalcheck", true);
    twosecinterval = setInterval(() => {
      setshowupload(true);
      checkProgress();
    }, 2000);
  };

  const checkProgress = async () => {
    debugger;
    await axios
      .get(`${Backend_Url}/contacts/events`, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      })
      .then((data) => {
        console.log(data.data);

        setcompleted(parseInt(data.data.progress.process));
        if (parseInt(data.data.progress.process) === 100) {
          console.log(data);
          localStorage.setItem("intervalcheck", false);
          Swal.fire({
            title: "Contact uploaded successfully.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            stopInterval();
          });
          stopInterval();
        }
      })
      .catch((error) => {
        console.error("Error fetching progress:", error);
        localStorage.setItem("intervalcheck", false);
        stopInterval();
        // Swal.fire({
        //   title: 'Error Uploading Contact',
        //   icon: 'warning',
        // })
      });
  };

  useEffect(() => {
    console.log(completed);
  }, [completed]);

  const stopInterval = () => {
    if (twosecinterval !== null) {
      debugger;
      clearInterval(twosecinterval);

      localStorage.setItem("intervalcheck", false);
    }
  };

  const check = localStorage.getItem("intervalcheck");
  useEffect(() => {
    debugger;
    console.log(check);
    console.log(typeof check);
    if (check == "true" && check != null && check != "false") {
      setintervelforprogree();
    } else {
      stopInterval();
    }
  }, []);

  useEffect(() => {
    console.log("object");

    const getDefault = async () => {
      debugger;
      try {
        let res = await axios.get(`${Backend_Url}/whatsapp/getDefault`, {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
          },
        });
        console.log("default ", res);
        if (res?.status === 200) {
          setDefaultId(res.data.result.id);
          setApiDropdown(res.data.result.id);
          if (res.data.result2) {
            setInstagramAccessToken(null);
          } else {
            setInstagramAccessToken(res.data.result.InstaAccessToken);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (cookies.token) {
      getDefault();
    }
  }, []);
  const openclosenav = () => {
    setmobileslider(!mobileslider);
  };

  useEffect(() => {
    const fetchApiSetting = async () => {
      try {
        const response = await axios.get(
          `${Backend_Url}/whatsapp/v1/whatsapp-api`,
          {
            headers: {
              Authorization: `Bearer ${cookies.token}`,
            },
          }
        );
        if (response) {
          console.log(response);
          const defaultApi = response.data.find((item) => item.IsDefault);
          setApiSet(defaultApi);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (cookies.token !== undefined) {
      fetchApiSetting();
    }
  }, []);

  const deleteAcessTokenAfterMonth = async () => {
    try {
      const response = await axios.put(
        `${Backend_Url}/whatsapp/deleteAccessTokenAfterMonth/${apiDropdown}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
          },
        }
      );

      console.log("deleteTokenAfterMonth", response);
    } catch (error) {}
  };

  return (
    <>
      {isAuthenticated && <ToastContainer />}
      <MyContext.Provider
        value={{
          fetchContactMessages,
          hasMore,
          lstmessageData,
          messageData,
          instagramAccessToken,
          apiDropdown,
          defaultId,
          completed,
          setDefaultId,
          setApiDropdown,
          setInstagramAccessToken,
          setMessageData,
          setLstMessageData,
          setHasMore,
          fetchlastmessage,
          setCompanyName,
          companyName,
          messagedeliverd,
          setmessagedeliverd,
          setmobileslider,
          mobileslider,
          modalopen,
          setmodalopen,
          message,
          setmessage,
          setIsFlowModalOpen,
          isFlowModalOpen,
          openclosenav,
          setintervelforprogree,
          apiset,
          setApiSet,
          yesbmLoginStatus,
          setYesbmLoginStatus,
          apiUrl,
          setApiUrl


        }}
      >
        <div style={{ maxHeight: "100vh" }}>
          {isAuthenticated && (
            <NavBar
              completed={completed}
              openclosenav={openclosenav}
              setmobileslider={setmobileslider}
              mobileslider={mobileslider}
              showupload={showupload}
            />
          )}
          <Routes>
            {/* Default route when accessing root */}
            <Route
              path="*"
              element={
                isAuthenticated ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/UserLogin" />
                )
              }
            />

            {/* Login-related routes */}
            {!isAuthenticated && (
              <>
                <Route path="/UserLogin" element={<UserLogin />} />
                <Route path="/Createcompany" element={<CreateCompany />} />
                <Route path="/SignUp" element={<SignUp />} />
                <Route path="/ForgotPassword" element={<ForgotPassword />} />
                <Route path="/ResetPassword" element={<ResetPassword />} />
                {/* <Route path="*" element={<UserLogin />} /> */}
              </>
            )}

            {/* Protected routes */}
            {isAuthenticated && (
              <>
                <Route
                  path="/dashboard"
                  element={
                    <Dashboard
                      setmobileslider={setmobileslider}
                      openclosenav={openclosenav}
                      mobileslider={mobileslider}
                    />
                  }
                />
                <Route path="/WTPSideBar" element={<WTPSideBar />} />
                <Route
                  path="/WTPCampaigns"
                  element={
                    <WTPCampaigns
                      setmobileslider={setmobileslider}
                      openclosenav={openclosenav}
                      mobileslider={mobileslider}
                    />
                  }
                />
                <Route path="/CreateCampaigns" element={<CreateCampaigns />} />
                <Route
                  path="/metatemplates"
                  element={
                    <MetaTemplates
                      setmobileslider={setmobileslider}
                      openclosenav={openclosenav}
                      mobileslider={mobileslider}
                    />
                  }
                />
                <Route
                  path="/CreateCustomTemplate"
                  element={<CreateCustomTemplate />}
                />
                <Route
                  path="/WTPContacts"
                  element={
                    <WTPContacts
                      completed={completed}
                      setmobileslider={setmobileslider}
                      openclosenav={openclosenav}
                      mobileslider={mobileslider}
                      setintervelforprogree={setintervelforprogree}
                    />
                  }
                />
                <Route
                  path="/Integration"
                  element={
                    <Integration
                      setmobileslider={setmobileslider}
                      openclosenav={openclosenav}
                      mobileslider={mobileslider}
                    />
                  }
                />
                <Route
                  path="/WTPBusinessAccount"
                  element={
                    <WTPBusinessAccount
                      setmobileslider={setmobileslider}
                      openclosenav={openclosenav}
                      mobileslider={mobileslider}
                    />
                  }
                />
                <Route path="/tempcreate" element={<CreateCompaignModal />} />
                <Route path="/slider" element={<Slider />} />
                <Route path="/newsnotice" element={<Newsandnotice />} />
                <Route path="/flashnews" element={<Flashnews />} />
                <Route path="/GeneralSetting" element={<GeneralSetting />} />
                <Route
                  path="/ProductCategoryMaster"
                  element={<ProductCategoryMaster />}
                />
                <Route path="/Gallery" element={<Gallery />} />
                <Route path="/Whatsapp" element={<Whatsapp />} />
                <Route
                  path="/Whatsappapi"
                  element={
                    <WhatsAppPanel
                      setmobileslider={setmobileslider}
                      openclosenav={openclosenav}
                      mobileslider={mobileslider}
                    />
                  }
                />
                <Route path="/templates" element={<Template />} />
                <Route
                  path="/WhatsappWeb"
                  element={
                    <WhatsappWeb
                      setmobileslider={setmobileslider}
                      openclosenav={openclosenav}
                      mobileslider={mobileslider}
                    />
                  }
                />
                <Route
                  path="/WhatsappAutomationAndChatBot"
                  element={<WhatsappAutomationAndChatBot />}
                />
                <Route
                  path="/AutomationDetail"
                  element={<AutomationDetail />}
                />
                <Route path="/flow" element={<Loader />} />
                <Route path="*" element={<WTPSideBar />} />
              </>
            )}
          </Routes>
          {modalopen && <CreateCompaignModal closeWindow={setmodalopen} />}
        </div>
      </MyContext.Provider>
    </>
  );
}

export default App;
