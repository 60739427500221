"use client";

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Panel,
  useReactFlow,
  MiniMap,
  Controls,
  Background,
} from "reactflow";
import "reactflow/dist/base.css";
import { FaTrash } from 'react-icons/fa';
import Sidebar from "./components/sidebar";
import TextNode from "./components/TextNode";
import QuestionNodes from "./components/QuestionNodes";
import { useDispatch, useSelector } from "react-redux";
import { nodeSliceAction } from "../../Store/store";
import ScreenNameNode from "./components/ScreenNameNode";
import CustomEdge from "./components/CustomEdge";

// Key for local storage
const flowKey = "flow-key";

// Initial node setup


// Function for generating unique IDs for nodes
const getId = () => `${Date.now()}`;

const App = () => {
  // Define custom node types
  const nodeTypes = useMemo(
    () => ({
      textnode: TextNode,
      questionNode: QuestionNodes,
      screenNameNode: ScreenNameNode
    }),
    []
  );

  const { tempNode } = useSelector(store => store.nodeSlice);

  // States and hooks setup
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(tempNode);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selectedElements, setSelectedElements] = useState([]);
  const [nodeName, setNodeName] = useState("");
  const dispatch = useDispatch();

  // converting Json data whenever some changes on nodes
  useEffect(() => {
    function compareArraysByKeys(arr1, arr2, keys) {
      // debugger
      if (arr1.length !== arr2.length) return false;
      return arr1.every((obj1, index) => {
        const obj2 = arr2[index];
        return keys.every(key => obj1[key] === obj2[key]);
      });
    }
    const keysToCompare = ['data', 'id', 'element', 'elementType'];
    if (!compareArraysByKeys(nodes, tempNode, keysToCompare)) {
      dispatch(nodeSliceAction.convertToJSON(nodes));
      dispatch(nodeSliceAction.setTempNode(nodes));
    }
    // dispatch(nodeSliceAction.convertToJSON(nodes));
  }, [nodes])

  // Update nodes data when nodeName or selectedElements changes
  // useEffect(() => {
  //   // if (selectedElements.length > 0) {
  //   //   setNodes((nds) =>
  //   //     nds?.map((node) => {
  //   //       if (node.id === selectedElements[0]?.id) {
  //   //         node.data = {
  //   //           ...node.data,
  //   //           label: nodeName,
  //   //         };
  //   //       }
  //   //       return node;
  //   //     })
  //   //   );
  //   // } else {
  //   //   setNodeName(""); // Clear nodeName when no node is selected
  //   // }
  // }, [nodeName, selectedElements, setNodes]);

  // Handle node click
  const onNodeClick = useCallback((event, node) => {
    setSelectedElements([node]);
    dispatch(nodeSliceAction.selectNode(node))
    setNodeName(node.data.label);
    setNodes((nodes) =>
      nodes.map((n) => ({
        ...n,
        selected: n.id === node.id,
      }))
    );
  }, []);


  // const onEdgeClick = useCallback((event, edge) => {
  //   setSelectedEdge(edge);
  // }, []);


  const onEdgeClick = useCallback((event, edge) => {
    setSelectedEdge((prevSelectedEdge) =>
      prevSelectedEdge?.id === edge.id ? null : edge
    );
  }, []);


  const onDeleteEdge = useCallback(() => {
    debugger
    if (selectedEdge) {
      const { source, target } = selectedEdge;

      // Remove the edge from the edges state
      setEdges((eds) => eds.filter((edge) => edge.id !== selectedEdge.id));

      // Update the nodes to reflect the deletion
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === source) {
            // Remove the target node from the source node's children
            const updatedChildren = node.children?.filter((child) => child.id !== target);
            return { ...node, children: undefined };
          }
          if (node.id === target) {
            // Remove the source node from the target node's parents
            const updatedParents = node.parents?.filter((pt) => pt !== source);
            return { ...node, parents: updatedParents };
          }
          return node;
        })
      );

      // Reset the selected edge after deletion
      setSelectedEdge(null);
    }
  }, [selectedEdge, setEdges, setNodes]);


  // Setup viewport
  const { setViewport } = useReactFlow();

  // Check for empty target handles
  const checkEmptyTargetHandles = () => {
    let emptyTargetHandles = 0;
    edges.forEach((edge) => {
      if (!edge.targetHandle) {
        emptyTargetHandles++;
      }
    });
    return emptyTargetHandles;
  };

  // Check if any node is unconnected
  const isNodeUnconnected = useCallback(() => {
    let unconnectedNodes = nodes.filter(
      (node) =>
        !edges.find(
          (edge) => edge.source === node.id || edge.target === node.id
        )
    );

    return unconnectedNodes.length > 0;
  }, [nodes, edges]);

  //
  const onSave = useCallback(() => {
    if (reactFlowInstance) {
      const emptyTargetHandles = checkEmptyTargetHandles();

      if (nodes.length > 1 && (emptyTargetHandles > 1 || isNodeUnconnected())) {
        alert(
          "Error: More than one node has an empty target handle or there are unconnected nodes."
        );
      } else {
        const flow = reactFlowInstance.toObject();
        localStorage.setItem(flowKey, JSON.stringify(flow));
        alert("Save successful!");
      }
    }
  }, [reactFlowInstance, nodes, isNodeUnconnected]);

  // Restore flow from local storage
  const onRestore = useCallback(() => {
    debugger
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(flowKey));

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };

    restoreFlow();
  }, [setNodes, setViewport]);

  // Handle edge connection
  const onConnect = useCallback(
    (params) => {
      debugger
      const Sourceedges = nodes.filter((item) => item.id === params.source)
      const targetEdges = nodes.filter((item) => item.id === params.target)
      const isequal = Sourceedges[0].type == "screenNameNode" && targetEdges[0].type === "textnode"
      console.log("isequal", !isequal);
      console.log("Sourceedges", Sourceedges[0]?.children?.length);
      console.log("params", params);
      if ((Sourceedges[0]?.children?.length === undefined || Sourceedges[0]?.children[0].type === 'textnode') || isequal) {


        setEdges((eds) => {
          console.log('eds', eds);
          const edge = { ...params, type: 'custom-edge' };
          return addEdge(edge, eds)
        });
        const target = nodes.filter(elm => elm.id === params.target);

        setNodes(prev => {

          return prev.map(elm => {
            if (elm.id === params.source) {
              const prevChildren = elm?.children || [];
              console.log("prevChildren", prevChildren);
              if (prevChildren?.length === 0 || isequal) {
                const upd = { ...elm, children: [...prevChildren, target[0]] }
                return upd;
              } else {
                return elm;
              }

            } if (elm.id === params.target) {
              const prevParents = elm?.parents || [];
              const upd = { ...elm, parents: [...prevParents, params.source] };
              return upd
            } else {
              return elm
            }
          })
        })
      }
    },
    [setEdges, nodes]
  );

  useEffect(() => {
    console.log("nodes", nodes);
  }, [nodes]);

  // Enable drop effect on drag over
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // Handle drop event to add a new node
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      // console.log("type", type);
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: '' },
      };

      setNodes((nds) => nds?.concat(newNode));
    },
    [reactFlowInstance]
  );

  const rfStyle = {
    backgroundColor: "#ffffff",
  };

  const edgeTypes = {
    'custom-edge': (props) => (
      <CustomEdge
        {...props}
        isSelected={selectedEdge?.id === props.id}
        onDelete={onDeleteEdge}
      />
    ),
  };

  return (
    <div className="flex flex-row min-h-screen lg:flex-row">
      <div className="flex-grow h-screen" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeClick={onEdgeClick}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
          style={rfStyle}
          onNodeClick={onNodeClick}
          onPaneClick={() => {
            setSelectedElements([]);
            dispatch(nodeSliceAction.selectNode(null))
            setNodes((nodes) =>
              nodes?.map((n) => ({
                ...n,
                selected: false,
              }))
            );
          }}
          fitView
        >
          <Background variant="dots" gap={12} size={1} />
          <Controls />
          <MiniMap zoomable pannable />
          <Panel>
            <button
              className=" m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={onSave}
            >
              save flow
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={onRestore}
            >
              restore flow
            </button>

          </Panel>
        </ReactFlow>
      </div>

      <Sidebar
        nodeName={nodeName}
        setNodes={setNodes}
        setNodeName={setNodeName}
        selectedNode={selectedElements[0]}
        setSelectedElements={setSelectedElements}
        nodes={nodes}
      />
    </div>
  );
};

// Wrap App with ReactFlowProvider
function FlowWithProvider() {
  return (
    <ReactFlowProvider>
      <App />
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
