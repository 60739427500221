import React, { useContext, useEffect, useState } from 'react';
import WTPSideBar from './WTPSideBar';
import { FaImage, FaVideo, FaFileAlt, FaPlus, FaUpload } from 'react-icons/fa'; // Importing icons from react-icons
import { Icon } from '@iconify/react/dist/iconify.js';
import Avatar from '../../Assets/Images/Avatar.png'
import image from '../../Assets/Images/image.png'
import video from '../../Assets/Images/video.png'
import document from '../../Assets/Images/document.png'
import axios from 'axios';
import Backend_Url from '../../config/config';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import FlowWithProvider from '../flow/MainFlow';
import { useSelector } from 'react-redux';
import { Input } from "../ui/input.tsx"
import { Label } from "../ui/label.tsx"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../ui/tabs.tsx"
import MyContext from '../../context/Mycontext';

////////Image And Video example needs to be added via media endpoint//////////

const CreateCustomTemplate = () => {
    const [cookies] = useCookies(['token']);
    const [templateName, setTemplateName] = useState('');
    const [templateLanguage, setTemplateLanguage] = useState('en_US');
    const [templateCategory, setTemplateCategory] = useState('');
    const [headerType, setHeaderType] = useState('none');
    const [headerFormat, setHeaderFormat] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [variables, setVariables] = useState([]);
    const [message, setMessage] = useState(`Hi John,

        Thanks for shopping with Your Company and we're just as excited as you are. We've got your order ID {{1}}. You can expect delivery soon and you'll receive an update once your order is packed and ready to be shipped.
        
        Thanks`);
    const [footer, setFooter] = useState('Reply STOP to unsubscribes');

    useEffect(() => {
        console.log("headerFormat", headerFormat);
        console.log("headerType", headerType);
    }, [headerFormat, headerType]);
    const { JSONData } = useSelector(store => store.nodeSlice)

    const [buttonType, setButtonType] = useState('none');
    const [quickReplies, setQuickReplies] = useState(['']);
    const [actionType, setActionType] = useState('call');
    const [buttonText, setButtonText] = useState('');
    const [urlType, setUrlType] = useState('static');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('1');
    const [loading, setLoading] = useState(true);
    const [file, setfile] = useState();
    const [flowId, setflowId] = useState('');
    const [flowJson, setflowJson] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [flowData, setFlowData] = useState([]);
    const { setIsFlowModalOpen, isFlowModalOpen } = useContext(MyContext)

    useEffect(() => {
        console.log("flowData", flowData);
    }, [flowData]);

    const handleFlowOpenModal = (e) => {
        e.preventDefault()
        setIsFlowModalOpen(true);
    };

    const handleFlowCloseModal = () => {
        setIsFlowModalOpen(false);
    };

    // const flowData = [
    //     {
    //         "name": "MyFlow",
    //         "status": "DRAFT",
    //         "categories": ["CUSTOMER_SUPPORT"],
    //         "validation_errors": [],
    //         "id": "450864817539895"
    //     },
    //     {
    //         "name": "Message templates_UTILITY_d7fd645e-4",
    //         "status": "DRAFT",
    //         "categories": ["OTHER"],
    //         "validation_errors": [],
    //         "id": "1529071084415375"
    //     },

    //     {
    //         "name": "MyFlow 2",
    //         "status": "DRAFT",
    //         "categories": ["CUSTOMER_SUPPORT"],
    //         "validation_errors": [],
    //         "id": "450864817539896"
    //     },
    //     {
    //         "name": "Message templates_UTILITY_d7fd645e-5",
    //         "status": "DRAFT",
    //         "categories": ["OTHER"],
    //         "validation_errors": [],
    //         "id": "1529071084415376"
    //     },
    //     {
    //         "name": "MyFlow 3",
    //         "status": "DRAFT",
    //         "categories": ["CUSTOMER_SUPPORT"],
    //         "validation_errors": [],
    //         "id": "450864817539897"
    //     },
    //     {
    //         "name": "Message templates_UTILITY_d7fd645e-6",
    //         "status": "DRAFT",
    //         "categories": ["OTHER"],
    //         "validation_errors": [],
    //         "id": "1529071084415377"
    //     },
    //     {
    //         "name": "MyFlow 4",
    //         "status": "DRAFT",
    //         "categories": ["CUSTOMER_SUPPORT"],
    //         "validation_errors": [],
    //         "id": "450864817539898"
    //     },
    //     {
    //         "name": "Message templates_UTILITY_d7fd645e-7",
    //         "status": "DRAFT",
    //         "categories": ["OTHER"],
    //         "validation_errors": [],
    //         "id": "1529071084415378"
    //     },
    // ];

    useEffect(() => {
        if (actionType === "flow") {
            setButtonText("View flow");
        } else {
            setButtonText("")
        }
    }, [actionType]);

    const handleOpenModal = (e) => {
        e.preventDefault()
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleHeaderTypeChange = (e) => {
        setHeaderType(e.target.value);
        setHeaderText(''); // Reset text input when changing header type
        setHeaderFormat(''); // Reset format when changing header type
    };

    const handleFormatSelect = (format) => {
        setHeaderFormat(format);
    };

    const handleAddVariable = () => {
        const newVariableIndex = variables.length + 1;
        setMessage((prevMessage) => prevMessage + `{{${newVariableIndex}}}`);
        setVariables([...variables, '']);
    };


    useEffect(() => {
        console.log("file", file);
    }, [file]);

    console.log("variables", variables);

    const handleVariableChange = (index, value) => {
        const newVariables = [...variables];
        newVariables[index] = value;
        setVariables(newVariables);
    };

    const handleRemoveVariable = (index) => {

        const newVariables = variables.filter((_, i) => i !== index);


        const variablePlaceholder = `{{${index + 1}}}`;
        setMessage((prevMessage) => {

            let updatedMessage = prevMessage.replace(variablePlaceholder, '');


            newVariables.forEach((_, i) => {
                const oldPlaceholder = `{{${i + 2}}}`;
                const newPlaceholder = `{{${i + 1}}}`;
                updatedMessage = updatedMessage.replace(oldPlaceholder, newPlaceholder);
            });

            return updatedMessage;
        });


        setVariables(newVariables);
    };


    const handleQuickReplyChange = (index, value) => {
        const newReplies = [...quickReplies];
        newReplies[index] = value;
        setQuickReplies(newReplies);
    };

    const addQuickReply = () => {
        setQuickReplies([...quickReplies, '']);
    };

    const removeQuickReply = (index) => {
        setQuickReplies(quickReplies.filter((_, i) => i !== index));
    };

    const validateImageType = (file) => {

        // Validate file types
        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const validVideoTypes = ['video/mp4'];
        const validDocumentTypes = ['application/pdf'];

        const fileType = file.type;

        // Check if the file is a valid image
        if (validImageTypes.includes(fileType)) {
            return true;  // Image is valid
        } else if (validVideoTypes.includes(fileType)) {
            return true;  // Video is valid
        } else if (validDocumentTypes.includes(fileType)) {
            return true;  // Document is valid
        } else {
            return false;

        }
    }


    const handleSave = async () => {

        const components = [];

        debugger
        if (headerType !== 'none') {
            if (!file) {
                components.push({
                    type: "HEADER",
                    format: headerType === 'media' ? headerFormat : headerType.toUpperCase(),
                    ...(headerType === 'media' ? {} : { text: headerText })
                });
            } else {

                if (!validateImageType(file)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Invalid Media Type',
                        text: 'Please select valid media type [image-(jpg,jpeg,png) video-(mp4) document-(pdf)].',
                        confirmButtonText: 'OK'
                    });

                    return
                }
                components.push({
                    type: "HEADER",
                    format: headerType === 'media' ? headerFormat : headerType.toUpperCase(),
                    ...(headerType === 'media' ? {} : { text: headerText }),
                    example: {
                        header_handle: []
                    }
                });
            }
        }


        if (message) {
            if (variables && variables.length > 0) {
                components.push({
                    type: 'BODY',
                    text: message,
                    example: {
                        body_text: [variables]
                    }
                });
            } else {
                components.push({
                    type: 'BODY',
                    text: message
                });
            }
        }

        if (footer) {
            components.push({
                type: 'FOOTER',
                text: footer
            });
        }


        //button payload and flow payload 
        // https://developers.facebook.com/docs/whatsapp/flows/guides/sendingaflow
        // https://developers.facebook.com/docs/graph-api/reference/whats-app-business-account/message_templates/#Creating
        debugger
        let parsedData
        if (JSONData) {
            parsedData = JSON.parse(JSONData);
        }

        if (buttonType === 'calltoaction') {
            if (flowId) {
                components.push({
                    type: 'BUTTONS',
                    buttons: [
                        {
                            type: 'FLOW',
                            text: buttonText || 'Open flow!',
                            navigate_screen: 'SCREEN_A', //get the id of the first screen
                            flow_action: 'navigate',
                            flow_id: flowId || null,

                        }
                    ].filter(Boolean)
                });
            } else if (JSONData) {
                components.push({
                    type: 'BUTTONS',
                    buttons: [
                        {
                            type: 'FLOW',
                            text: buttonText || 'Open flow!',
                            navigate_screen: parsedData.screens[0].id,
                            flow_action: 'navigate',
                            flow_json: JSONData || null
                        }
                    ].filter(Boolean)
                });
            }

        } else if (buttonType !== 'none' && (phoneNumber || websiteUrl)) {
            // Existing button types: PHONE_NUMBER or URL
            components.push({
                type: 'BUTTONS',
                buttons: [
                    phoneNumber ? {
                        type: 'PHONE_NUMBER',
                        text: buttonText || 'Call',
                        phone_number: phoneNumber
                    } : null,
                    websiteUrl ? {
                        type: 'URL',
                        text: 'Contact Support',
                        url: websiteUrl
                    } : null
                ].filter(Boolean)
            });
        }


        // Construct the final payload
        const payload = {
            name: templateName.toLowerCase(),
            language: templateLanguage,
            category: templateCategory,
            components: components,
            // allow_category_change: true,
        };

        console.log(JSON.stringify(payload, null, 2));
        const loadingSwal = Swal.fire({
            title: 'Creating Template...',
            text: 'Please wait while we create your template.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });


        try {

            // const response = await axios.post(`${Backend_Url}/whatsapp/createtemplates`, payload, {
            //     headers: {
            //         Authorization: `Bearer ${cookies.token}`
            //     }
            // });

            let response;

            if (file) {
                debugger
                const formData = new FormData();
                formData.append('payload', JSON.stringify(payload));
                formData.append('file', file);

                response = await axios.post(`${Backend_Url}/whatsapp/createtemplates`, formData, {
                    headers: {
                        'Authorization': `Bearer ${cookies.token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } else {

                response = await axios.post(`${Backend_Url}/whatsapp/createtemplates`, payload, {
                    headers: {
                        'Authorization': `Bearer ${cookies.token}`,
                        'Content-Type': 'application/json'
                    }
                });
            }


            if (response.status === 201) {

                Swal.fire({
                    title: 'Template Created!',
                    text: `Template ID: ${response.data.id}, Status: ${response.data.status}`,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {

            console.error("Error creating template:", error);
            if (error.response.data.error.code === 100) {
                Swal.fire({
                    title: error.response.data.error.error_user_title,
                    text: error.response.data.error.error_user_msg,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to create template. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        }


    };

    const Spinner = () => (
        <div className="flex items-center justify-center h-full w-full">
            <div className="w-8 h-8 border-4 border-blue-400 border-dotted rounded-full animate-spin"></div>
        </div>
    );


    const handleCancel = () => {
        // Implement cancel logic (e.g., clear form, redirect, etc.)
        console.log('Action cancelled');
        // Optionally reset all state
        setTemplateName('');
        setTemplateLanguage('en_US');
        setTemplateCategory('');
        setHeaderType('none');
        setHeaderFormat('');
        setHeaderText('');
        setMessage('');
        setFooter('');
        setButtonType('none');
        setQuickReplies(['']);
        setActionType('call');
        setButtonText('');
        setUrlType('static');
        setWebsiteUrl('');
        setPhoneNumber('');
        setCountryCode('1');
    };
    const date = new Date(Date.now());
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });


    const fetchflowdata = async () => {
        try {
            debugger
            setLoading(true);
            const response = await axios.get(`${Backend_Url}/whatsapp/getallflows`, {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`,
                }
            })
            console.log("response", response);
            if (response.status === 200) {

                setTimeout(() => {
                    setFlowData(response.data.data.data)
                    setLoading(false);
                }, 500);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (isModalOpen === true) {
            fetchflowdata();
        }
    }, [isModalOpen]);

    const handleflowClick = (e, data) => {
        e.preventDefault();
        console.log("data", data);
        setflowId(data.id);

        Swal.fire({
            title: 'Flow Selected!',
            text: `${data.name} has been selected.`,
            icon: 'success',
            confirmButtonText: 'OK'
        });

        setIsModalOpen(false);
    };

    // const handlepublish = async (e, data) => {
    //     try {
    //         const response = await axios.post(`${Backend_Url}/flowpublish/${data.id}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${cookies.token}`,
    //             }
    //         })
    //         console.log("response", response);
    //         Swal.fire(response);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    // const handleflowdelete = async (e, data) => {
    //     e.preventDefault()
    //     try {
    //         const response = await axios.delete(`${Backend_Url}/flowdelete/${data.id}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${cookies.token}`,
    //             }
    //         })
    //         Swal.fire(response);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }


    const handlepublish = async (e, data) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${Backend_Url}/flowpublish/${data.id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`,
                }
            });
            console.log("response", response);
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Published Successfully!',
                    text: response.data.message || 'The flow has been published.',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error(error);


            Swal.fire({
                icon: 'error',
                title: 'Publish Failed',
                text: error.response?.data?.message || 'An error occurred while publishing.',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleflowdeprecate = async (e, data) => {
        e.preventDefault();
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        });

        if (!result.isConfirmed) {
            return
        }
        try {
            const response = await axios.post(`${Backend_Url}/deprecateflow/${data.id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`,
                }
            });
            console.log("response", response);
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Request Successfull',
                    text: response.data.message || 'The flow has been Deprecated.',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error(error);


            Swal.fire({
                icon: 'error',
                title: 'Deprication Failed',
                text: error.response?.data?.message || 'An error occurred while Depricating.',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleflowdelete = async (e, data) => {
        e.preventDefault();
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });

        if (!result.isConfirmed) {
            return
        }
        try {
            const response = await axios.delete(`${Backend_Url}/flowdelete/${data.id}`, {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`,
                }
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted Successfully!',
                    text: response.data.message || 'The flow has been deleted.',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error(error);


            Swal.fire({
                icon: 'error',
                title: 'Delete Failed',
                text: error.response?.data?.message || 'An error occurred while deleting.',
                confirmButtonText: 'OK'
            });
        }
    };
    const { mobileslider, setmobileslider } = useContext(MyContext);
    return (
        <>
            {isFlowModalOpen && (
                <div
                    className="fixed inset-0  bg-black bg-opacity-50 flex justify-center items-center "
                    onClick={handleFlowCloseModal}
                >
                    <div
                        className="bg-white p-8 rounded-lg w-full "
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={handleFlowCloseModal}
                            className="absolute top-4 right-12 text-xl"
                        >
                            &times;
                        </button>
                        <FlowWithProvider />
                    </div>
                </div>
            )}
            <div className='Flex-Head-Right-Compaigns'>
                <div>
                    <WTPSideBar mobileslider={mobileslider} setmobileslider={setmobileslider} />
                </div>
                <div className="whatsapp-template-form-custom-template">
                    <h2 className="form-title-custom-template">Create New WhatsApp Templates</h2>
                    <div className={`form-preview-container-custom-template ${!isFlowModalOpen && "relative"} sm:block flex`}>

                        <Tabs defaultValue="create" className="w-full hidden sm:block">
                            <TabsList className="grid w-full grid-cols-2 bg-[#f6f6f6]">
                                <TabsTrigger
                                    value="create"
                                    className="px-4 py-2 text-black transition-colors bg-[#f6f6f6] data-[state=active]:bg-blue-500 data-[state=active]:text-white"
                                >
                                    Create
                                </TabsTrigger>
                                <TabsTrigger
                                    value="preview"
                                    className="px-4 py-2 text-black transition-colors bg-[#f6f6f6] data-[state=active]:bg-blue-500 data-[state=active]:text-white"
                                >
                                    Preview
                                </TabsTrigger>
                            </TabsList>
                            <TabsContent value="create" className="">
                                <form className="form-container-custom-template sm:!w-full">
                                    <CreateTemplate
                                        templateName={templateName}
                                        setTemplateName={setTemplateName}
                                        templateLanguage={templateLanguage}
                                        setTemplateLanguage={setTemplateLanguage}
                                        templateCategory={templateCategory}
                                        setTemplateCategory={setTemplateCategory}
                                        headerType={headerType}
                                        setHeaderType={setHeaderType}
                                        headerFormat={headerFormat}
                                        setHeaderFormat={setHeaderFormat}
                                        headerText={headerText}
                                        setHeaderText={setHeaderText}
                                        message={message}
                                        setMessage={setMessage}
                                        variables={variables}
                                        setVariables={setVariables}
                                        footer={footer}
                                        setFooter={setFooter}
                                        buttonType={buttonType}
                                        setButtonType={setButtonType}
                                        quickReplies={quickReplies}
                                        setQuickReplies={setQuickReplies}
                                        actionType={actionType}
                                        setActionType={setActionType}
                                        buttonText={buttonText}
                                        setButtonText={setButtonText}
                                        urlType={urlType}
                                        setUrlType={setUrlType}
                                        websiteUrl={websiteUrl}
                                        setWebsiteUrl={setWebsiteUrl}
                                        countryCode={countryCode}
                                        setCountryCode={setCountryCode}
                                        phoneNumber={phoneNumber}
                                        setPhoneNumber={setPhoneNumber}
                                        handleAddVariable={handleAddVariable}
                                        handleVariableChange={handleVariableChange}
                                        handleRemoveVariable={handleRemoveVariable}
                                        handleFormatSelect={handleFormatSelect}
                                        handleQuickReplyChange={handleQuickReplyChange}
                                        addQuickReply={addQuickReply}
                                        removeQuickReply={removeQuickReply}
                                        setfile={setfile}
                                        handleHeaderTypeChange={handleHeaderTypeChange}
                                        handleFlowOpenModal={handleFlowOpenModal}
                                        handleflowdeprecate={handleflowdeprecate}
                                        handleCloseModal={handleCloseModal}
                                        handleOpenModal={handleOpenModal}
                                        isModalOpen={isModalOpen}
                                        loading={loading}
                                        flowData={flowData}
                                        handleflowClick={handleflowClick}
                                        handlepublish={handlepublish}
                                        handleflowdelete={handleflowdelete}
                                        Spinner={Spinner}
                                    />
                                </form>
                                {isFlowModalOpen && (
                                    <div
                                        className="fixed inset-0  bg-black bg-opacity-50 flex justify-center items-center"
                                        onClick={handleFlowCloseModal}
                                    >
                                        <div
                                            className="bg-white p-8 rounded-lg w-full "
                                            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicked inside
                                        >
                                            <button
                                                onClick={handleFlowCloseModal}
                                                className="absolute top-4 right-12 text-xl"
                                            >
                                                &times;
                                            </button>
                                            <FlowWithProvider />
                                        </div>
                                    </div>
                                )}
                            </TabsContent>
                            <TabsContent value="preview" className="">
                                <div className={`preview-custom-template !w-full !m-0 !p-0`}>
                                    <Preview
                                        formattedDate={formattedDate}
                                        headerFormat={headerFormat}
                                        headerText={headerText}
                                        headerType={headerType}
                                        message={message}
                                        footer={footer}
                                        isDesktopMode={false}
                                        buttonText={buttonText}
                                        buttonType={buttonType}
                                        actionType={actionType}
                                        quickReplies={quickReplies}
                                        file={file}

                                    />
                                </div>
                            </TabsContent>
                        </Tabs>


                        <form className="form-container-custom-template sm:!hidden">
                            <CreateTemplate
                                templateName={templateName}
                                setTemplateName={setTemplateName}
                                templateLanguage={templateLanguage}
                                setTemplateLanguage={setTemplateLanguage}
                                templateCategory={templateCategory}
                                setTemplateCategory={setTemplateCategory}
                                headerType={headerType}
                                setHeaderType={setHeaderType}
                                headerFormat={headerFormat}
                                setHeaderFormat={setHeaderFormat}
                                headerText={headerText}
                                setHeaderText={setHeaderText}
                                message={message}
                                setMessage={setMessage}
                                variables={variables}
                                setVariables={setVariables}
                                footer={footer}
                                setFooter={setFooter}
                                buttonType={buttonType}
                                setButtonType={setButtonType}
                                quickReplies={quickReplies}
                                setQuickReplies={setQuickReplies}
                                actionType={actionType}
                                setActionType={setActionType}
                                buttonText={buttonText}
                                setButtonText={setButtonText}
                                urlType={urlType}
                                setUrlType={setUrlType}
                                websiteUrl={websiteUrl}
                                setWebsiteUrl={setWebsiteUrl}
                                countryCode={countryCode}
                                setCountryCode={setCountryCode}
                                phoneNumber={phoneNumber}
                                setPhoneNumber={setPhoneNumber}
                                handleAddVariable={handleAddVariable}
                                handleVariableChange={handleVariableChange}
                                handleRemoveVariable={handleRemoveVariable}
                                handleFormatSelect={handleFormatSelect}
                                handleQuickReplyChange={handleQuickReplyChange}
                                addQuickReply={addQuickReply}
                                removeQuickReply={removeQuickReply}
                                setfile={setfile}
                                handleHeaderTypeChange={handleHeaderTypeChange}
                                handleFlowOpenModal={handleFlowOpenModal}
                                handleflowdeprecate={handleflowdeprecate}
                                handleCloseModal={handleCloseModal}
                                handleOpenModal={handleOpenModal}
                                isModalOpen={isModalOpen}
                                loading={loading}
                                flowData={flowData}
                                handleflowClick={handleflowClick}
                                handlepublish={handlepublish}
                                handleflowdelete={handleflowdelete}
                                Spinner={Spinner}
                            />
                        </form>


                        <div className={`preview-custom-template sm:hidden `}>

                            <Preview
                                isDesktopMode={true}
                                formattedDate={formattedDate}
                                headerFormat={headerFormat}
                                headerText={headerText}
                                headerType={headerType}
                                message={message}
                                footer={footer}
                                buttonText={buttonText}
                                buttonType={buttonType}
                                actionType={actionType}
                                quickReplies={quickReplies}
                                file={file}

                            />
                        </div>

                        <div className={`form-footer-custom-template ${!isFlowModalOpen && "fixed"} sm:relative`}>
                            <button className="save-button-custom-template" onClick={handleSave}>Save</button>
                            <button className="cancel-button-custom-template" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </div>



            </div>
        </>
    );
};


const Preview = ({
    formattedDate,
    headerText,
    headerFormat,
    headerType,
    message,
    footer,
    isDesktopMode,
    buttonText,
    buttonType,
    actionType,
    quickReplies,
    file
}) => {

    const [filePreview, setFilePreview] = useState('');

    useEffect(() => {
        if (file) {
            console.log('File:', file);

            const previewUrl = URL.createObjectURL(file);
            setFilePreview(previewUrl);
        }
    }, [file]);

    // Function to render preview based on file type
    const renderFilePreview = () => {
        if (!filePreview) return null;

        // Image preview
        if (file.type.startsWith('image')) {
            return <div
                className="preview-image-custom-template !h-[100px]"
                style={{
                    backgroundImage: `url(${filePreview})`,
                    backgroundSize: '90%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            ></div>
        }

        // Video preview
        if (file.type.startsWith('video')) {
            return (
                <video controls className="preview-video-custom-template" style={{ width: '100%' }}>
                    <source src={filePreview} type={file.type} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        // PDF preview (optional)
        if (file.type === 'application/pdf') {
            return (
                <object data={filePreview} type="application/pdf" width="100%" height="500">
                    <p>Your browser does not support PDF preview. Please download the PDF to view it.</p>
                </object>
            );
        }
    }

    return (
        <>
            <h3 className="preview-title-custom-template">Preview</h3>
            {/* <video src={filePreview} controls></video> */}
            <div className='mobile-box-custom-template'>
                <div className='mobile-header-custom-template'>
                    <span className="mobile-notification-count-custom-template">12</span>
                    <div className="pl-3-custom-template">
                        <img src={Avatar} className="mobile-avatar-custom-template" />
                    </div>
                    <div className="pl-3-custom-template">
                        <div className="text-dark-custom-template">
                            Your Company <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M8.08163 0L6.61224 1.37374L4.32653 0.888889L3.42857 2.90909L1.46939 3.39394L1.71429 5.57576L0 7.11111L1.22449 8.80808L0.571429 10.7475L2.44898 11.9596L2.69388 14.0606H5.06122L6.53061 16L8.08163 14.7071L10.0408 15.8384L11.1837 14.0606H13.3878L13.7143 11.9596L15.5918 10.7475L14.8571 8.80808L16 7.11111L14.449 5.57576L14.6122 3.39394L12.8163 2.90909L11.7551 0.888889L9.63265 1.37374L8.08163 0Z" fill="#25D366"></path>
                                <path d="M5.30656 7.83681L4.49023 8.81641L7.26574 11.347L11.9188 5.71437L10.8576 4.81641L7.02085 9.38783L5.30656 7.83681Z" fill="white"></path>
                            </svg>
                        </div>
                        <div style={{ fontSize: "12px" }}>Online</div>
                    </div>
                </div>
                <div className='mobile-body-custom-template'>
                    <div style={{ textAlign: "center" }}>
                        <div className="mobile-time-custom-template">{formattedDate}</div>
                    </div>
                    <div className="chat-reply-custom-template">
                        <div className="card-custom-template">
                            <div className="card-body-custom-template">
                                <div className="card-pin-custom-template">
                                    <svg width="14" height="14" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 3C4.012 4.79118 5.71852 6.48861 8 10.5V0C4.4246 0.0578375 2.6981 0.767101 0 3Z" fill="#FFF"></path>
                                    </svg>
                                </div>
                                <p className="preview-header-text-custom-template" style={{ display: headerType === 'text' ? 'block' : 'none' }}>
                                    <strong>{headerText}</strong>
                                </p>
                                {!file && headerType === "media" && (headerFormat && (<img
                                    src={
                                        headerFormat === 'IMAGE' ? image : headerFormat === 'VIDEO' ? video : headerFormat === 'DOCUMENT' ? document : null
                                    }
                                    className="preview-image-custom-template"
                                    alt="Preview"
                                />))}
                                {renderFilePreview()}
                                <p style={{ fontSize: "14px" }} className="preview-text-custom-template">
                                    {message}
                                </p>
                                <div className="preview-footer-text">
                                    <div className="text-muted-custom-template">{footer}</div>
                                </div>

                                {(buttonType === "calltoaction" && buttonText) && (
                                    <div className="flex " style={{ borderTop: '1px solid gray', width: '100%' }}>
                                        <div className="w-full">
                                            <div className="row-custom-template flex items-center justify-center py-2 gap-1">
                                                {actionType === 'call' && < Icon icon="mynaui:telephone" width="16" height="16" style={{ color: "#60a5fa" }} />}
                                                {actionType === 'visit' && < Icon icon="line-md:external-link" width="16" height="16" style={{ color: "#60a5fa" }} />}
                                                <span className="text-blue-400 text-sm ">{buttonText}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {(buttonType === "quickreply" && quickReplies) && (
                                    <div className="flex " style={{ borderTop: '1px solid gray', width: '100%' }}>
                                        <div className="w-full">
                                            <div className="row-custom-template flex items-center justify-center py-2 gap-1">
                                                <span className="text-blue-400 text-sm ">{quickReplies}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}



const CreateTemplate = ({
    templateName,
    setTemplateName,
    templateLanguage,
    setTemplateLanguage,
    templateCategory,
    setTemplateCategory,
    headerType,
    setHeaderType,
    headerFormat,
    setHeaderFormat,
    headerText,
    setHeaderText,
    message,
    setMessage,
    variables,
    setVariables,
    footer,
    setFooter,
    buttonType,
    setButtonType,
    quickReplies,
    setQuickReplies,
    actionType,
    setActionType,
    buttonText,
    setButtonText,
    urlType,
    setUrlType,
    websiteUrl,
    setWebsiteUrl,
    countryCode,
    setCountryCode,
    phoneNumber,
    setPhoneNumber,
    handleChange,
    handleAddVariable,
    handleVariableChange,
    handleRemoveVariable,
    handleFormatSelect,
    handleQuickReplyChange,
    addQuickReply,
    removeQuickReply,
    setfile,
    handleHeaderTypeChange,
    handleFlowOpenModal,
    handleflowdeprecate,
    handleCloseModal,
    handleOpenModal,
    isModalOpen,
    loading,
    flowData,
    handleflowClick,
    handlepublish,
    handleflowdelete,
    Spinner,
}) => {
    return (
        <>
            <div className="form-group-custom-template">
                <label htmlFor="templateName" className="form-label-custom-template">Template Name*</label>
                <input
                    type="text"
                    id="templateName"
                    className="form-input-custom-template"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    placeholder="Ex. order_confirmation_1"
                    required
                />
            </div>

            <div className="form-group-custom-template">
                <label htmlFor="templateLanguage" className="form-label-custom-template">Template Language*</label>
                <select
                    id="templateLanguage"
                    className="form-select-custom-template"
                    value={templateLanguage}
                    onChange={(e) => setTemplateLanguage(e.target.value)}
                >
                    <option value="en_US" selected>en_US</option>
                    <option value="en_GB">en_GB</option>
                    <option value="en">en</option>
                </select>
            </div>

            <div className="form-group-custom-template">
                <label htmlFor="templateCategory" className="form-label-custom-template">Template Category*</label>
                <select
                    id="templateCategory"
                    className="form-select-custom-template"
                    value={templateCategory}
                    onChange={(e) => setTemplateCategory(e.target.value)}
                >
                    <option value="AUTHENTICATION">Authentication</option>
                    <option value="MARKETING">Marketing</option>
                    <option value="UTILITY">Utility</option>
                </select>
            </div>

            <div className="form-group-custom-template">
                <label className="form-label-custom-template">Header Type*</label>
                <select
                    id="headerType"
                    className="form-select-custom-template"
                    value={headerType}
                    onChange={handleHeaderTypeChange}
                >
                    <option value="none">None</option>
                    <option value="text">Text</option>
                    <option value="media">Media</option>
                </select>
            </div>

            {headerType === 'media' && (
                <div className="form-group-custom-template">
                    <label className="form-label-custom-template">Header Format*</label>
                    <div className="media-selection-custom-template">
                        <div className='media-option-container-custom-template'>
                            <div
                                className={`media-option-custom-template ${headerFormat === 'IMAGE' ? 'selected' : ''}`}
                                onClick={() => handleFormatSelect('IMAGE')}
                                title="Image"
                            >
                                <FaImage size={50} />
                                <p>Image</p>
                            </div>
                            {headerFormat === 'IMAGE' && (
                                <>
                                    <input
                                        type="file"
                                        onChange={(e) => setfile(e.target.files[0])}
                                        style={{ display: 'none' }}
                                        id="image-upload"
                                        accept=".jpg,.jpeg,.png"
                                    />
                                    <label
                                        htmlFor="image-upload"
                                        style={{ cursor: 'pointer', marginTop: '10px', display: "flex", justifyContent: "center" }}
                                    >
                                        <FaUpload size={30} title="Upload Image" />
                                    </label>
                                </>
                            )}
                        </div>
                        <div className='media-option-container-custom-template'>
                            <div
                                className={`media-option-custom-template ${headerFormat === 'VIDEO' ? 'selected' : ''}`}
                                onClick={() => handleFormatSelect('VIDEO')}
                                title="Video"
                            >
                                <FaVideo size={50} />
                                <p>Video</p>
                            </div>
                            {headerFormat === 'VIDEO' && (
                                <>
                                    <input
                                        type="file"
                                        onChange={(e) => setfile(e.target.files[0])}
                                        style={{ display: 'none' }}
                                        id="video-upload"
                                        accept=".mp4"
                                    />
                                    <label
                                        htmlFor="video-upload"
                                        style={{ cursor: 'pointer', marginTop: '10px', display: "flex", justifyContent: "center" }}
                                    >
                                        <FaUpload size={30} title="Upload Video" />
                                    </label>
                                </>
                            )}
                        </div>
                        <div className='media-option-container-custom-template'>
                            <div
                                className={`media-option-custom-template ${headerFormat === 'DOCUMENT' ? 'selected' : ''}`}
                                onClick={() => handleFormatSelect('DOCUMENT')}
                                title="Document"
                            >
                                <FaFileAlt size={50} />
                                <p>Document</p>
                            </div>
                            {headerFormat === 'DOCUMENT' && (
                                <>
                                    <input
                                        type="file"
                                        onChange={(e) => setfile(e.target.files[0])}
                                        style={{ display: 'none' }}
                                        id="document-upload"
                                        accept=".pdf"
                                    />
                                    <label
                                        htmlFor="document-upload"
                                        style={{ cursor: 'pointer', marginTop: '10px', display: "flex", justifyContent: "center" }}
                                    >
                                        <FaUpload size={30} title="Upload Document" />
                                    </label>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {headerType === 'text' && (
                <div className="form-group-custom-template">
                    <label htmlFor="headerText" className="form-label-custom-template">Header Text*</label>
                    <input
                        type="text"
                        id="headerText"
                        className="form-input-custom-template"
                        value={headerText}
                        onChange={(e) => setHeaderText(e.target.value)}
                        placeholder="Type your header text here..."
                        required
                    />
                </div>
            )}

            <div className="form-group-custom-template">
                <label htmlFor="message" className="form-label-custom-template">Message*</label>
                <textarea
                    id="message"
                    style={{ scrollbarWidth: "none" }}
                    className="form-textarea-custom-template"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                    required
                />
                <button type="button" onClick={handleAddVariable} className="add-variable-button-custom-template">
                    Add Variable
                </button>


                {variables.map((variable, index) => (
                    <div key={index} className="variable-example-custom-template">
                        <input
                            type="text"
                            value={variable}
                            onChange={(e) => handleVariableChange(index, e.target.value)}
                            placeholder={`Example for {{${index + 1}}}`}
                        />
                        <button
                            type="button"
                            onClick={() => handleRemoveVariable(index)}
                            className="remove-variable-button-custom-template"
                        >
                            Remove
                        </button>
                    </div>
                ))}
            </div>

            <div className="form-group-custom-template">
                <label htmlFor="footer" className="form-label-custom-template">Footer (Optional)</label>
                <input
                    type="text"
                    id="footer"
                    className="form-input-custom-template"
                    value={footer}
                    onChange={(e) => setFooter(e.target.value)}
                />
            </div>

            {/* New Buttons Dropdown */}
            <div className="form-group-custom-template">
                <label htmlFor="buttonType" className="form-label-custom-template">Buttons (Optional)</label>
                <select
                    id="buttonType"
                    className="form-select-custom-template"
                    value={buttonType}
                    onChange={(e) => setButtonType(e.target.value)}
                >
                    <option value="none">None</option>
                    <option value="quickreply">Quick Reply</option>
                    <option value="calltoaction">Call to Action</option>
                </select>
            </div>

            {/* Quick Replies Section */}
            {buttonType === 'quickreply' && (
                <div className="quick-replies-section">
                    <label className="form-label-custom-template">Quick Replies</label>
                    {quickReplies.map((reply, index) => (
                        <div key={index} className="quick-reply-input-custom-template">
                            <input
                                className='form-input-custom-template'
                                type="text"
                                value={reply}
                                onChange={(e) => handleQuickReplyChange(index, e.target.value)}
                                placeholder={`Quick Reply ${index + 1}`}
                            />
                            <button className="remove-quick-reply-input-custom-template" type="button" onClick={() => removeQuickReply(index)}>
                                <Icon icon="fluent:delete-12-filled" style={{ color: "#ffffff" }} />
                            </button>
                            <button type="button" onClick={addQuickReply} className="add-reply-button-custom-template">
                                <FaPlus />
                            </button>
                        </div>
                    ))}

                </div>
            )}

            {/* Call to Action Section */}
            {buttonType === 'calltoaction' && (
                <div className="call-to-action-section">
                    <div className="form-group-custom-template flex-row-custom-template sm:!grid sm:grid-cols-2 !gap-5">
                        <div className='Flex-Input-custom-templates '>
                            <label htmlFor="actionType" className="form-label-custom-template">Type of Action*</label>
                            <select
                                id="actionType"
                                className="form-select-custom-template"
                                value={actionType}
                                onChange={(e) => setActionType(e.target.value)}
                            >
                                <option value="call">Call Phone Number</option>
                                <option value="visit">Visit Website</option>
                                <option value="flow">Flow</option>
                            </select>
                        </div>
                        <div className='Flex-Input-custom-templates'>
                            <label htmlFor="buttonText" className="form-label-custom-template">Button Text*</label>
                            <input
                                type="text"
                                id="buttonText"
                                // className="form-input-custom-template"
                                value={buttonText}
                                onChange={(e) => setButtonText(e.target.value)}
                                placeholder="Enter button text"
                                required
                            />
                        </div>
                        {actionType === 'visit' && (
                            <>
                                <div className='Flex-Input-custom-templates'>
                                    <label htmlFor="urlType" className="form-label-custom-template">URL Type*</label>
                                    <select
                                        id="urlType"
                                        className="form-select-custom-template"
                                        value={urlType}
                                        onChange={(e) => setUrlType(e.target.value)}
                                    >
                                        <option value="static">Static</option>
                                        <option value="dynamic">Dynamic</option>
                                    </select>
                                </div>

                                <div className='Flex-Input-custom-templates'>
                                    <label htmlFor="websiteUrl" className="form-label-custom-template">Website URL*</label>
                                    <input
                                        type="url"
                                        id="websiteUrl"
                                        // className="form-input-custom-template"
                                        value={websiteUrl}
                                        onChange={(e) => setWebsiteUrl(e.target.value)}
                                        placeholder="Enter website URL"
                                        required
                                    />
                                </div>
                            </>
                        )}
                        {actionType === 'call' && (
                            <>
                                <div className='Flex-Input-custom-templates'>
                                    <label htmlFor="countryCode" className="form-label-custom-template">Country Code*</label>
                                    <select
                                        id="countryCode"
                                        className="form-select-custom-template"
                                        value={countryCode}
                                        onChange={(e) => setCountryCode(e.target.value)}
                                    >
                                        <option value="1">+1</option>
                                        <option value="44">+44</option>
                                        <option value="91">+91</option>

                                    </select>
                                </div>
                                <div className='Flex-Input-custom-templates'>
                                    <label htmlFor="phoneNumber" className="form-label-custom-template">Phone Number*</label>
                                    <input
                                        type="tel"
                                        id="phoneNumber"
                                        className="form-input-custom-template"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        placeholder="Enter phone number"
                                        required
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {actionType === 'flow' && (
                            <div className="p-6 border border-gray-300 rounded-lg shadow-md bg-white mb-4 ">

                                <h2 className="text-lg font-semibold mb-1">Flow Setup</h2>
                                <p className="text-gray-600 mb-1 text-sm">Choose an existing Flow or create a new one.</p>
                                <p className="text-gray-600 mb-4 text-[10px] font-semibold">Note:Flows Can't Be Edited Or Deleted once it is Published</p>


                                <div className="flex items-center space-x-4">
                                    <button
                                        className=" flex items-center border-blue-500  px-4 py-2 text-white bg-blue-500 rounded-lg shadow hover:bg-blue-600 transition duration-300"
                                        onClick={handleFlowOpenModal}
                                    >
                                        <Icon icon="mdi-light:plus" style={{ color: "white" }} className="mr-2" />
                                        Create New
                                    </button>
                                    <button onClick={(e) => handleOpenModal(e)} className=" flex items-center px-4 py-2 text-blue-500 border border-blue-500 rounded-lg hover:bg-blue-500 hover:text-white transition duration-300">
                                        <Icon icon="mdi:file-import" className="mr-2" />
                                        Use Existing
                                    </button>
                                </div>



                            </div>
                        )}

                        {/* Modal */}
                        {isModalOpen && (
                            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                                <div className="bg-white rounded-lg shadow-lg w-6/12 md:w-1/2 lg:w-1/3 p-6">
                                    <h2 className="text-lg font-semibold mb-4">Select an Existing Flow</h2>
                                    <p className="text-gray-600 mb-4">Choose from the list below:</p>
                                    <p className='text-gray-600 text-xs'>Note:Flows can be deleted, but only if they are in the Draft state</p>
                                    {/* Flow Cards */}
                                    <div className="h-96 overflow-y-auto space-y-4" style={{ scrollbarWidth: "none" }}>
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            flowData.map(flow => (
                                                <div key={flow.id} className="bg-white border border-gray-300 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 p-5 relative group">
                                                    <h3 className="text-lg font-bold mb-2">{flow.name}</h3>
                                                    <p className="text-sm text-gray-500">Status: <span className="font-semibold">{flow.status}</span></p>
                                                    <p className="text-sm text-gray-500">Categories: <span className="font-semibold">{flow.categories?.join(', ')}</span></p>
                                                    {/* {flow.status !== 'DRAFT' && (
                                                        <div>

                                                            <button onClick={(e) => handleflowClick(e, flow)} className="absolute top-2 right-2 border-blue-500 bg-blue-500 text-white text-xs font-semibold px-2 py-1 rounded-full transition duration-300 hover:bg-blue-600 group-hover:bg-blue-600 cursor-pointer">
                                                                Use
                                                            </button>

                                                            <button
                                                                onClick={(e) => handleflowdeprecate(e, flow)}
                                                                className="absolute top-[70px] right-2 border border-red-500 bg-red-500 text-white text-xs font-semibold px-2 py-2 rounded-full transition duration-300 hover:bg-red-500 hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-lg cursor-pointer flex items-center space-x-1"
                                                            >
                                                                <span>Deprecate</span>
                                                            </button>
                                                        </div>
                                                    )}


                                                    {flow.status === 'DRAFT' && (
                                                        <div>
                                                            <span className="absolute top-2 right-2 text-gray-500 text-xs font-semibold">
                                                                Not Usable(Unpublished)
                                                            </span>
                                                            <button
                                                                onClick={(e) => handlepublish(e, flow)}
                                                                className="absolute top-6 right-2 border-green-600 bg-green-600 text-white text-xs font-semibold px-3 py-1.5 rounded-full transition duration-300 hover:bg-green-500 hover:scale-105 shadow-lg cursor-pointer"
                                                            // className="absolute top-6 right-2 border-blue-500 bg-blue-500 text-white text-xs font-semibold px-2 py-1 rounded-full transition duration-300 hover:bg-blue-600 group-hover:bg-blue-600 cursor-pointer"
                                                            >
                                                                Publish
                                                            </button>

                                                            <button
                                                                onClick={(e) => handleflowdelete(e, flow)}
                                                                className="absolute top-[70px] right-2 border border-red-400 bg-red-600 text-white text-xs font-semibold px-2 py-2 rounded-full transition duration-300 hover:bg-red-500 hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-lg cursor-pointer flex items-center space-x-1"
                                                            >
                                                                <Icon icon="mdi:delete" width="16" height="16" style={{ color: "white" }} />

                                                            </button>
                                                        </div>
                                                    )} */}
                                                    {flow.status !== 'DEPRECATED' && (
                                                        <>
                                                            {flow.status !== 'DRAFT' && (
                                                                <div>
                                                                    <button
                                                                        onClick={(e) => handleflowClick(e, flow)}
                                                                        className="absolute top-2 right-2 border-blue-500 bg-blue-500 text-white text-xs font-semibold px-2 py-1 rounded-full transition duration-300 hover:bg-blue-600 group-hover:bg-blue-600 cursor-pointer"
                                                                    >
                                                                        Use
                                                                    </button>

                                                                    <button
                                                                        onClick={(e) => handleflowdeprecate(e, flow)}
                                                                        className="absolute top-[70px] right-2 border border-red-500 bg-red-500 text-white text-xs font-semibold px-2 py-2 rounded-full transition duration-300 hover:bg-red-500 hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-lg cursor-pointer flex items-center space-x-1"
                                                                    >
                                                                        <span>Deprecate</span>
                                                                    </button>
                                                                </div>
                                                            )}

                                                            {flow.status === 'DRAFT' && (
                                                                <div>
                                                                    <span className="absolute top-2 right-2 text-gray-500 text-xs font-semibold">
                                                                        Not Usable (Unpublished)
                                                                    </span>
                                                                    <button
                                                                        onClick={(e) => handlepublish(e, flow)}
                                                                        className="absolute top-6 right-2 border-green-600 bg-green-600 text-white text-xs font-semibold px-3 py-1.5 rounded-full transition duration-300 hover:bg-green-500 hover:scale-105 shadow-lg cursor-pointer"
                                                                    >
                                                                        Publish
                                                                    </button>

                                                                    <button
                                                                        onClick={(e) => handleflowdelete(e, flow)}
                                                                        className="absolute top-[70px] right-2 border border-red-400 bg-red-600 text-white text-xs font-semibold px-2 py-2 rounded-full transition duration-300 hover:bg-red-500 hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-lg cursor-pointer flex items-center space-x-1"
                                                                    >
                                                                        <Icon icon="mdi:delete" width="16" height="16" style={{ color: "white" }} />
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}


                                                </div>
                                            ))
                                        )}
                                    </div>

                                    {/* Close Button */}
                                    <div className="flex justify-end mt-4">
                                        <button
                                            onClick={handleCloseModal}
                                            className="px-4 py-2 text-white border-red-500 bg-red-500 rounded-lg hover:bg-red-600 transition duration-300"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    )
}

export default CreateCustomTemplate;
