import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { FaUser, FaWhatsapp, FaSignOutAlt } from 'react-icons/fa';

const ModelUsers = () => {
    // const [showMenu, setShowMenu] = useState(false); 

    const handleMenuItemClick = (action) => {
      console.log(action); // Implement your action handling here
    //   setShowMenu(false);
    };
  return (
    <div className="dropdown-menu-Model">
        <h3 className="heading-welcome-ur font-bold text-[1.3rem] mt-2">Welcome user !</h3>
          <button className='!text-[.9rem] flex items-center gap-2' onClick={() => handleMenuItemClick('profile')}>
            <FaUser style={{ marginRight: '8px' }} /> Profile
          </button>
          <button className='!text-[.9rem] flex items-center gap-2' onClick={() => handleMenuItemClick('whatsapp-template')}>
            <FaWhatsapp style={{ marginRight: '8px' }} /> WhatsApp Template
          </button>
          <button className='!text-[.9rem] flex items-center gap-2' onClick={() => handleMenuItemClick('whatsapp-profile')}>
            <FaUser style={{ marginRight: '8px' }} /> WhatsApp Profile
          </button>
          <button className='!text-[.9rem] flex items-center gap-2' onClick={() => handleMenuItemClick('logout')}>
            <FaSignOutAlt style={{ marginRight: '8px' }} /> Logout
          </button>
        </div>
  )
}

export default ModelUsers
