import React, { useEffect, useRef, useState } from 'react';
import { Icon } from "@iconify/react/dist/iconify.js";
import "../../css/wtpstyle.css";
import WTPSideBar from '../WhatsappCampaigns/WTPSideBar';
import IntegrationLoginModal from '../IntegrationLoginModal';
const Integration = ({ mobileslider, setmobileslider, openclosenav }) => {

    const data = [
        { id: 1, description: 'WhatsApp Messages Payment (direct to Facebook)', link: 'https://www.facebook.com' },
        { id: 2, description: 'WhatsApp Business Manager', link: 'https://www.facebook.com/business' },
        { id: 3, description: 'Create WhatsApp Template', link: 'https://www.facebook.com/whatsapp' },
        { id: 4, description: 'Green Tick Tutorial', link: 'https://www.facebook.com/whatsapp' },
        { id: 5, description: 'WhatsApp Direct Support', link: 'https://www.facebook.com/whatsapp' },
    ];

    const [openYesBMModal, setOpenYesBMModal] = useState(false);

    const showYesBMModel = () => {
        setOpenYesBMModal(true);
    }

    const hideYesBMModel = () => {
        setOpenYesBMModal(false);
    }
    return (
        <div className="Flex-Head-Right-Compaigns">
            <div>
                <WTPSideBar mobileslider={mobileslider} setmobileslider={setmobileslider} />
            </div>
            <div className="Right-Campaigns-Body">
                <div className="Campaigns_header">
                    <div className="Campaigns_header">
                        <h3 className='font-bold text-[1.3rem] mt-2'>Integration </h3>
                    </div>
                    <div className="Campaigns_headerRight">
                        <button className="Campaigns_headerButton">
                            <Icon icon="arcticons:goodwy-contacts" />
                        </button>
                    </div>
                </div>
                <div className="Campaigns_body_Bg">

                    <div className='w-full flex px-8 gap-4'>
                        <div>
                            <button className='cursor-pointer px-4 py-2 rounded-[4px] border-none bg-red-500 text-white font-semibold font-sans text-[1rem]' onClick={showYesBMModel}>Integrate with YES-BM</button>
                        </div>
                        <div>
                            <button className='cursor-pointer px-4 py-2 rounded-[4px] border-none bg-red-500 text-white font-semibold font-sans text-[1rem]'>Integrate with YES-BM ( New )</button>
                        </div>
                    </div>


                    {openYesBMModal && (
                        <div className="modal-Category ">
                            <div className="modal-content modal-content-contact p-!0 modal-campaigns-width sm:!w-[90%] max-h-[600px] overflow-auto relative px-4">
                                <div className="modal-title modal-title-contact">
                                    <h3 className="modal-heading-top">Import Contact From 
                                        YES-BM</h3>
                                    <span className="close" onClick={hideYesBMModel}>&times;</span>
                                </div>
                                <div>
                                    <IntegrationLoginModal setOpenYesBMModal={setOpenYesBMModal}  />
                                </div>
                            </div>
                        </div>
                    )}



                    {/* <div className="Campaigns-form-content">
                        <div>
                            <table className="Table-Body-content">
                                <thead className="Table-Campaigns-color">
                                    <tr className="Table-Bg-color">
                                        <th className="th-name-camp">Description</th>
                                        <th className="th-name-camp">Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(item => (
                                        <tr key={item.id} className="Table-Bg-color">
                                            <td className="td-name-campaigns">{item.description}</td>
                                            <td className="td-name-campaigns">
                                                <a href={item.link} target="_blank" rel="noopener noreferrer" className='clickhere-color'>
                                                    Click Here
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Integration
