export const Template = JSON.stringify({
  version: "5.0",
  screens: [],
});

export const ScreenTemplate = JSON.stringify({
  id: "MY_FIRST_SCREEN",
  title: "First Screen",
  terminal: true,
  success: true,
  layout: {
    type: "SingleColumnLayout",
    children: [
      {
        type: "Footer",
        label: "Continue",
        "on-click-action": {
          name: "data_exchange",
          payload: {},
        },
      },
    ],
  },
});

// const Screen = ["SCREEN_ONE"]

// {
//   id: "FORM_EXAMPLE", //first screen id
//   title: "Demo Screen", //name of the screen
//   terminal: true,
//   success: true,
//   data: {},
//   layout: {
//     type: "SingleColumnLayout",
//     children: [
//       {
//         type: "Footer",
//         label: "Submit data",
//         "on-click-action": {
//           name: "complete",
//           payload: {},
//         },
//       },
//     ],
//   },
// },
